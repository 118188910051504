import {
    FETCH_COURSE_DETAIL,
    FETCH_COURSE_LIST
} from "../../constants/action-type";
import { env } from "../../constants/config";

export function fetchCourseList(token) {

    return function(dispatch) {

        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            }
        };
        fetch(env.host + "/apiAsia/course/list", requestOptions)
            .then(response => response.json())
            .then((data) => {
                dispatch({
                    type: FETCH_COURSE_LIST,
                    payload: data
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_COURSE_LIST,
                    payload: error
                });
            });
    }

}

export function fetchCourseDetail(token, courseId) {

    return function(dispatch) {
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            }
        };
        fetch(env.host + "/apiAsia/course/" + courseId, requestOptions)
            .then(response => response.json())
            .then((data) => {
                dispatch({
                    type: FETCH_COURSE_DETAIL,
                    payload: data
                });
            })
            .catch((error) => {
            });
    }

}