import { env } from "../constants/config";

function buildHeader(token) {
    return {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + token,
        "Cache-Control": "no-cache"
    };
}

function getRequestOption(token) {
    const requestOptions = {
        method: "GET",
        headers: buildHeader(token)
    };
    return requestOptions;
}

function postRequestOption(token) {
    const requestOptions = {
        method: "POST",
        headers: buildHeader(token)
    }
    return requestOptions;
}

function handleResponse(response, onSuccess, onFail) {
    if (response.status === 403) {
        onFail({
            statusCode: "403",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
        return;
    }

    if (response.ok) {
        response.json()
        .then((body) => {
            if (body.status_code !== "00") {
                onFail({
                    statusCode: body.status_code,
                    statusMessage: body.status_message
                });
                return;
            }
            onSuccess(body);
        })
        .catch((error) => {
            onFail({
                statusCode: "99",
                statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
            })
            return;
        })
    }
} 

export function getCustomVocab(token, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/vocab/custom", getRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail);
    })
    .catch((error) => {
        onFail({
            statusCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function updateCustomVocab(token, update, onSuccess, onFail) {
    const request = {
        ...postRequestOption(token),
        body: JSON.stringify({
            result: update.result
        })
    }
    fetch(env.host + "/apiAsia/vocab/custom/" + update.id + "/update", request)
    .then((response) => {
        handleResponse(response, onSuccess, onFail);
    })
    .catch((error) => {
        onFail({
            statusCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function addCustomVocab(token, update, onSuccess, onFail) {
    const request = {
        ...postRequestOption(token),
        body: JSON.stringify({
            vocab: update.vocab,
            meaning: update.meaning,
            note: update.note
        })
    }
    fetch(env.host + "/apiAsia/vocab/custom", request)
    .then((response) => {
        handleResponse(response, onSuccess, onFail);
    })
    .catch((error) => {
        onFail({
            statusCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function checkCanAddCustomVocab(token, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/vocab/custom/available", getRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail);
    })
    .catch((error) => {
        onFail({
            statusCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function deleteVocab(token, id, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/vocab/custom/" + id + "/delete", postRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail);
    })
    .catch((error) => {
        onFail({
            statusCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function getAllVocab(token, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/vocab/my", getRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail);
    })
    .catch((error) => {
        onFail({
            statusCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function getTranslateVocab(token, wording, lang, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/vocab/translate?text=" + wording + "&lang=" + lang, postRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail);
    })
    .catch((error) => {
        onFail({
            statusCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function getNotifyInfo(token, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/user/notify/info", getRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail)
    })
    .catch((error) => {
        onFail({
            statisCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function disableNotify(token, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/user/notify/disable", postRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail)
    })
    .catch((error) => {
        onFail({
            statisCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function generateLineNotifyURL(token, onSuccess, onFail) {
    fetch(env.host + "/apiAsia/user/notify", getRequestOption(token))
    .then((response) => {
        handleResponse(response, onSuccess, onFail)
    })
    .catch((error) => {
        onFail({
            statisCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function enableNotify(token, code, time, onSuccess, onFail) {
    const request = {
        ...postRequestOption(token),
        body: JSON.stringify({
            code: code,
            time: time
        })
    }
    fetch(env.host + "/apiAsia/user/notify/enable", request)
    .then((response) => {
        handleResponse(response, onSuccess, onFail)
    })
    .catch((error) => {
        onFail({
            statisCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}

export function notifyIncorrectVocabs(token, customVocabs, courseVocabs, onSuccess, onFail) {
    const request = {
        ...postRequestOption(token),
        body: JSON.stringify({
            custom_vocab: customVocabs,
            course_vocab: courseVocabs
        })
    }
    fetch(env.host + "/apiAsia/vocab/notify/incorrect", request)
    .then((response) => {
        handleResponse(response, onSuccess, onFail)
    })
    .catch((error) => {
        onFail({
            statisCode: "99",
            statusMessage: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        });
    });
}