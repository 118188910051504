import React from "react";
import { connect } from 'react-redux';
import SubmitButtons from './submitButton';
import TextField from '@material-ui/core/TextField';
import logo from '../../../assets/images/logo-2@2x.png';
import { invalidateToken } from '../../../state/action/users-action';
import {LOG_IN, NOTIFY} from "../../../constants/routes";
import {
    closeDialog,
    loading,
    register,
    setupRegisterDetail,
    validateEmail,
    validateUsername
} from "./controller/register-controller";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";

const fontsHead = {
    fontFamily: "Prompt, sans-serif",
    color: "#2d3142",
    fontWeight: 500,
    fontSize: 32,
    margin: 0,
    lineHeight: 1.19,
    marginTop: 20
};
const fontsBody = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 400,
    color: "#006b6d",
    fontSize: 14
};

class LoginEmailScreen extends React.Component {

    constructor(props) {
        super(props);
        const registerDetail = this.props.location.state;
        if (registerDetail == null) {
            this.props.history.push(LOG_IN);
        }
        this.props.initRegisterDetail(registerDetail);
        this.onSubmit = this.onSubmit.bind(this);
        this.onPositiveClick = this.onPositiveClick.bind(this);
        this.onFailedClick = this.onFailedClick.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();
        this.props.onLoading();
        this.props.firebase.auth().currentUser.getIdToken(false)
            .then((idToken) => {
                this.props.onRegister(idToken);
            });
    };

    onChangeUsername = event => {
        const username = event.target.value;
        this.props.onValidateUsername(username);
    };

    onChangeEmail = event => {
        const email = event.target.value;
        this.props.onValidateEmail(email);
    };

    onPositiveClick() {
        this.props.onCloseDialog();
        this.props.invalidateToken({
            username: this.props.registerDetail.username,
            email: this.props.registerDetail.email
        });
        setTimeout(() => {
            this.props.history.push(NOTIFY);
        }, 1000);
    }

    onFailedClick() {
        this.props.onCloseDialog();
    }

    render() {
        return (
            <div>
                <div className="container" style={{textAlign: "center",margin:"auto",marginTop:80,width:300}}>
                    <div><img src={logo} style={{width:44,height:36}} alt="logo" /></div>
                    <div className="welcom" style={fontsHead}>
                        <div >โปรดระบุอีเมล์</div>
                        <div >ที่ใช้ในปัจจุบัน</div>
                    </div>
                    <div className="space" style={{marginBottom: 50}}/>
                    <div className="input-box" style={{textAlign:"left"}}>
                        <div className="input-username">
                            <div style={fontsBody}>ชื่อ (Display name)</div>
                            <TextField
                                id="standard-full-width"
                                margin="dense"
                                fullWidth
                                onChange={this.onChangeUsername}
                                value={this.props.registerDetail?.username}
                                error={this.props.usernameInvalid}
                                helperText={this.props.errorUsernameMessage}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className="space" style={{marginBottom: 10}}/>
                        <div className="input-email" >
                            <div style={fontsBody}>อีเมล์</div>
                            <TextField
                                id="standard-full-width"
                                margin="dense"
                                fullWidth
                                onChange={this.onChangeEmail}
                                value={this.props.registerDetail?.email}
                                error={this.props.emailInvalid}
                                helperText={this.props.errorEmailMessage}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </div>
                    <div className="space" style={{marginBottom: 30}}/>
                    <SubmitButtons onClick={this.onSubmit} disabled={this.props.emailInvalid || this.props.usernameInvalid}/>
                </div>
                <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
                <ConfirmDialog
                    open={this.props.dialogMessage?.show}
                    message={this.props.dialogMessage?.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onPositiveClick}/>
                <ConfirmDialog
                    open={this.props.dialogErrorMessage?.show}
                    message={this.props.dialogErrorMessage?.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onFailedClick}/>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    db: state.firebase.db,
    firebase: state.firebase.app,
    users: state.user,
    registerDetail: state.registerReducer.register_detail,
    emailInvalid: state.registerReducer.email_invalid,
    errorEmailMessage: state.registerReducer.error_email_message,
    usernameInvalid: state.registerReducer.username_invalid,
    errorUsernameMessage: state.registerReducer.error_username_message,
    loading: state.registerReducer.loading,
    dialogMessage: state.registerReducer.dialog_message,
    dialogErrorMessage: state.registerReducer.dialog_message_fail
});

const mapDispatchToProps = (dispatch) => ({
    initRegisterDetail: (detail) => dispatch(setupRegisterDetail(detail)),
    onValidateEmail: (email) => dispatch(validateEmail(email)),
    onValidateUsername: (username) => dispatch(validateUsername(username)),
    onLoading: () => dispatch(loading()),
    onRegister: (token) => dispatch(register(token)),
    onCloseDialog: () => dispatch(closeDialog()),
    invalidateToken: (user) => dispatch(invalidateToken(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginEmailScreen);