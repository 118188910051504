import React from "react";
import LinearProgressBars from './ProgressBar/LinearProgress';
import DialogSubmitScreen from '../common/Dialogs/dialogSubmit';
import * as ROUTES from "../../constants/routes";

const container = {
    display: "flex",
    alignItems: "center",
    height: "60px"
};

const itemTitle1 = {
    flexGrow: 1,
    fontWeight: 500,
    fontFamily: 'Prompt, sans-serif',
    fontSize: "23px",
    marginRight: "48px",
    textAlign: "center",
    margin: "auto",
};

const itemTitle2 = {
    flexGrow: 1,
    fontWeight: 500,
    fontFamily: 'Prompt, sans-serif',
    fontSize: "23px",
    marginRight: "48px",
    textAlign: "center",
    marginLeft: "6%",
};

class ProgressBarChallenge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen : false
        };
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleGoToLanding = this.handleGoToLanding.bind(this);
    }

    handleCloseDialog(){
        this.setState(prevState => ({
            dialogOpen: !prevState.dialogOpen
        }));
    }

    handleGoToLanding(){
        this.props.handlerGoToLanding();
        this.setState(prevState => ({
            dialogOpen: !prevState.dialogOpen
        }));
    }

    render() {
        return (
            <div style={container}>
                <div>
                    <button style={{
                        display: this.props.displayClose,
                        justifyContent: "center",
                        backgroundColor: `rgba(0, 0, 0, 0)`,
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                        color: this.props.closeButtonColor
                    }} 
                    onClick={this.handleCloseDialog}>
                        <i className="material-icons-round md-48">close</i>
                    </button>
                </div>
                <div style={this.props.displayClose==="none" ? itemTitle1 : itemTitle2}>
                    <LinearProgressBars 
                        getPercentage={{
                            learn: this.props.percentage.learn,
                            total: this.props.percentage.total}} 
                        progressBarColor={this.props.progressBarColor}
                    />
                </div>

                <DialogSubmitScreen
                    dialogOpen = {this.state.dialogOpen}
                    handleCloseDialog = {this.handleCloseDialog}
                    headerMessage = {this.props.dialog.headerMessage}
                    bodyMessage = {this.props.dialog.bodyMessage}
                    handleGoToLanding = {this.handleGoToLanding}
                    cancelLabelButton = {this.props.dialog.cancelLabelButton}
                    submitLabelButton = {this.props.dialog.submitLabelButton}
                />
            </div>
        );
    }

}


export default ProgressBarChallenge;