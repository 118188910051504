import React from 'react';
import './progress.css';

const ProgressBar = (props) => {
    return (
        <div className="progress-bar" style={{border:`solid 2px ${props.progressBarUI}`}}>
            <Filler percentage={props.percentage} progressBarUI={props.progressBarUI} />
        </div>
    )
}

const Filler = (props) => {
    return <div className="filler" style={{ width: `${props.percentage}%`, 
    background: `${props.progressBarUI}` }} />
}

function calculatePercentage(learn,total){
    if (learn === 0){
        return 0
    }
    let result = (learn/total)*100
    if(result > 100 || result === 100) {
        result = 98
    }
    return result
}

class LinearProgressBar extends React.Component {

    render(){
        return (
            <div>
                <ProgressBar percentage={calculatePercentage(
                                this.props.getPercentage.learn,
                                this.props.getPercentage.total
                            )}
                            progressBarUI={this.props.progressBarColor}
                />
            </div>
        )
    }

}


export default LinearProgressBar;