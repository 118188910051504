import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles(({fontColorBt,colorBt}) => ({
	root: {
		color: fontColorBt,
        backgroundColor: colorBt,
        width: 289,
        height: 68,
        borderRadius: 20,
        boxShadow: `0 10px 20px 0 rgba(0, 91, 142, 0.3)`,
		textTransform: 'none',
		fontSize:16,
		fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
		'&:hover': {
			backgroundColor: colorBt,
			borderColor: colorBt,
			boxShadow: `0 10px 20px 0 rgba(0, 91, 142, 0.3)`,
        },
        marginTop : 30,
	},
}))(Button);

function ChoiceButtons({id,onClick,textButton,stateButton,value}) {
	return (
		<div>
			{
				stateButton === "true" ?
				<ColorButton id={id} size="large" onClick={onClick} data-value={value}
							style={{color:"#ffffff",backgroundColor:"#1fbe7a"}}>
					{textButton}
				</ColorButton> :
					stateButton === "false" ?
					<ColorButton id={id} size="large" onClick={onClick} data-value={value}
								style={{color:"#ffffff",backgroundColor:"#ce3b5d"}}>
						{textButton}
					</ColorButton> :
					<ColorButton id={id} size="large" onClick={onClick} data-value={value}
								style={{color:"#202027",backgroundColor:"#f3f4f5"}}>
						{textButton}
					</ColorButton>
			}
		</div>
	);
}

export default ChoiceButtons;
