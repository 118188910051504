import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react';

export const mainContainer = {
    display: "flex",
    flexDirection: "column"
}

export const cardContainer = {
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 20,
    boxShadow: "0 10px 20px 0 rgba(0, 64, 128, 0.15)",
    backgroundColor: "#FFFFFF",
    marginTop: 16,
    marginBottom: 16
}

export const headerText = {
    paddingTop: 16,
    paddingLeft: 24,
    fontFamily: "Prompt, sans-serif",
    fontSize: 16,
    color: "#000000"
}

export const subText = {
    paddingLeft: 24,
    fontFamily: "Prompt, sans-serif",
    fontSize: 11,
    color: "#717479"
}

export const AlohaTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        borderRadius: "12px",
        height: "2px",
        '& > span': {
            width: '85%',
            backgroundColor: '#006b6d'
        },
    }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const AlohaTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#000000',
        fontFamily: 'Prompt, sans-serif',
        fontSize: 16,
        weight: 400
    },
}))((props) => <Tab disableRipple {...props} />);

export const cardTitle = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 16,
    fontWeight: "500",
    color: "#000000",
}

export const cardSubTitle = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 11,
    fontWeight: 300,
    color: "#4a4a4a"
}

export const itemCustomVocabContainer = {
    display: "flex",
    flexDirection: "row",  
}

export const itemCustomVocabTextContainer = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 8
}

export const itemTextMain = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 15,
    fontWeight: "500",
    color: "#000",
    paddingLeft: 0,
    paddingTop: 4
}

export const itemTextSub = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 11,
    fontWeight: "300",
    color: "#717479",
    paddingLeft: 0,
    paddingBottom: 4
}

export const actionSectionContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 4,
    paddingRight: 4
}

export const rememberText = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 12,
    fontWeight: "300",
    color: "#1fbe7a",
}

export const forgetText = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 12,
    fontWeight: "300",
    color: "#e1436a",
}

export const bottomContainer = {
    width: "100%",
    boxSizing: "border-box",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    
}

export const bottomContainerPadding = {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 36,
    paddingTop: 16,
}

export const VerticalContainer = {
    display: "flex",
    flexDirection: "column",
    padding: 16
}

export const verticalCenterContainer = {
    ...VerticalContainer,
    alignItems: "center",
    fontFamily: "Prompt, sans-serif",
    padding: 16
}

export const formTextTitle = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 14,
    fontWeight: "400",
    color: "#006b6d",
}

export const formTextData = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 16,
    fontWeight: "400",
    color: "#000000"
}

export const horizontalContainer = {
    display: "flex",
    flexDirection: "row",
    padding: 8,
    alignItems: "center",
    justifyContent: "center"
}

export const customVocabHeader = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 40,
    fontWeight: "700",
    color: "#000000",
    paddingTop: 8,
    paddingBottom: 8
}

export const customVocabMeaning = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 21,
    fontWeight: "500",
    color: "#4a4a4a",
    paddingTop: 4,
    paddingBottom: 20
}

export const customVocabInfo = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 11,
    fontWeight: "300",
    color: "#4a4a4a",
}

export const customVocabRepetition = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 11,
    fontWeight: "300",
    color: "#e9446a",
}

export const filterTitle = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 18,
    fontWeight: "400",
    color: "#717479",
}

export const filterItem = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 18,
    fontWeight: "400",
    color: "#000000",
}

export const line = {
    width: "100%",
    height: "1px",
    backgroundColor: "#eeeeef"
}

export const inputAloha = {
    border: "none",
    fontFamily: "Prompt, sans-serif",
    fontSize: 18,
    fontWeight: "400",
    color: "#000000",
}