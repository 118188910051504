import React, { useState } from "react";
import Drawer from '@material-ui/core/Drawer';
import { VerticalContainer, bottomContainerPadding } from "../styles";
import FilterList from "./list-filter";
import {ActionBottomButton} from "./button-bottom-action";
import Spacer from "../../../common/space";

export default function CourseVocabFilter(props) {
    
    const [ orderFilter, setOrderFilter ] = useState(0);
    const [ typeFilter, setTypeFilter ] = useState(0);
    const [ courseFilter, setCourseFilter ] = useState("00");

    return (
        <Drawer
            anchor="bottom"
            open={props.open}
            onClose={props.onClose}>
            <div style={{display: "block"}}>
                <Spacer spaceHeight={24} spaceWidth={0}/>
                <FilterList title={"เรียงลำดับ"} filter={props.order} onSelect={setOrderFilter}/>
                <FilterList title={"ประเภทคำศัพท์"} filter={props.types} onSelect={setTypeFilter}/>
                <FilterList title={"หมวด"} filter={props.course} onSelect={setCourseFilter}/>
                <Spacer spaceHeight={80} spaceWidth={0}/>
                <ActionBottomButton onClick={() => props.onSubmitFilter(orderFilter, typeFilter, courseFilter)} name={"ยืนยัน"}/>
            </div>
        </Drawer>
    );

}