import {REGISTER_ACTION} from "./register-reducer";
import { env } from "../../../../constants/config";

export function loading() {
    return {
        type: REGISTER_ACTION.LOADING
    }
}

export function setupRegisterDetail(detail) {
    return {
        type: REGISTER_ACTION.SETUP_REGISTER_DETAIL,
        payload: {
            register_detail: detail
        }
    }
}

export function validateEmail(email) {
    const validator = require("email-validator");
    return {
        type: REGISTER_ACTION.VALIDATE_EMAIL,
        payload: {
            email_valid: validator.validate(email),
            email: email
        }
    }
}

export function validateUsername(username) {
    return {
        type: REGISTER_ACTION.VALIDATE_USERNAME,
        payload: {
            username: username
        }
    }
}

export function register(token) {
    return (dispatch, getState) => {
        const { username, email, register_token } = getState().registerReducer.register_detail;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": 'application/json; charset=UTF-8',
                "Authorization": 'Bearer ' + token,
                "Cache-Control": 'no-cache'
            },
            body: JSON.stringify({
                username: username,
                email: email,
                register_token: register_token
            })
        };
        fetch(env.host + "/apiAsia/user/register", requestOptions)
            .then((response) => response.json())
            .then((body) => {

                if (body.status_code === "00") {
                    dispatch({
                        type: REGISTER_ACTION.REGISTER_SUCCESS
                    });
                } else if (body.status_code === "002") {
                    dispatch({
                        type: REGISTER_ACTION.REGISTER_FAILED_DUPLICATE_EMAIL,
                        payload: {
                            message: body.status_message
                        }
                    });
                } else {
                    dispatch({
                        type: REGISTER_ACTION.REGISTER_FAILED,
                        payload: {
                            message: body.status_message
                        }
                    });
                }

            })
            .catch((error) => {
                dispatch({
                    type: REGISTER_ACTION.REGISTER_FAILED,
                    payload: {
                        message: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองอีกครั้งภายหลัง"
                    }
                });
            });
    }
}

export function closeDialog() {
    return {
        type: REGISTER_ACTION.CLOSE_DIALOG
    }
}