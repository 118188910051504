import {
    FETCH_COURSE_DETAIL,
    FETCH_COURSE_LIST,
} from "../../constants/action-type";

const coursesState = {
    ownCourses: [],
    allCourses: [],
    selectedCourse: null
};

function formatTwoDigit(num) {
    return ("0" + num).slice(-2)
}

export function coursesReducer(state = coursesState, { type, payload }) {
    switch (type) {
        case FETCH_COURSE_LIST: {
            if (payload.status_code === "00") {
                return {
                    ...state,
                    ownCourses: payload.user_course,
                    allCourses: payload.all_course
                }
            }
            return {
                ...state
            }
        }
        case FETCH_COURSE_DETAIL: {
            let expiredDateFormat = "";
            if (payload.status === "active") {
                const expiredDate = new Date(payload.expired_at._seconds * 1000);
                expiredDateFormat = formatTwoDigit(expiredDate.getDate()) + "/" + formatTwoDigit(expiredDate.getMonth()) + "/" + (expiredDate.getFullYear()+543+"").substring(2);
            }
            return {
                ...state,
                selectedCourse: {
                    ...payload,
                    expiredAt: expiredDateFormat
                }
            }
        }
        default: {
            return state;
        }
    }
}
