import React from "react";
import Toolbar from "../../../common/toolbar";
import {LoadingScreen} from "../../../common/loading";
import {connect} from "react-redux";
import {fetchCourseDetail} from "../../../../state/action/course-action";
import Label from "./item/label";
import icStudent from "../../../../assets/images/ic_student.png";
import icAvailable from "../../../../assets/images/ic_available.png";
import icVocabCount from "../../../../assets/images/ic_vocab_count.png";
import icRepeat from "../../../../assets/images/ic_repeat.png";
import Parser from 'html-react-parser';
import { Container } from "@material-ui/core";
import { env } from "../../../../constants/config";
import {
    closeDialog,
    finishRegisterCourse,
    getCourseDetail,
    loading,
    registerCourse
} from "../controller/course-controller";
import {refreshToken} from "../../../../state/action/users-action";
import DialogLoadingScreen from "../../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../../common/Dialogs/confirm-dialog";
import { COURSES } from "../../../../constants/routes";

const container = {
    display: "flex",
    flexDirection: "column",
    height: "100%"
};

const boxContainer = {
    width: "100%",
    flexGrow: 1,
    boxShadow: "0 2px 20px 0 rgba(0, 64, 128, 0.1)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    position: "relative"
};

const coverContainer = {
    paddingTop: "48px",
    paddingBottom: "20px",
    width: 250,
    height: 105,
    alignSelf: "center",
    textAlign: "center"
};

const coverStyle = {
    width: "250px"
};

const detailContainer = {
    display: "grid",
    gridTemplateColumns: "60% 40%",
    gridTemplateRows: "auto auto",
    paddingLeft: 16,
    paddingTop: 16,
    paddingRight: 16
};

const detailItemContainer = {
    marginBottom: 10
};

const dividerStyle = {
    backgroundColor: "#c7c7c7",
    marginLeft: 16,
    marginRight: 16,
    border: "solid 1px rgba(0, 0, 0, 0.07)"
};

const courseDetailContainer = {
    flexGrow: 1,
    color: "#000000",
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingTop: 8
};

const textDescriptionStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 300,
    fontSize: 13,
};

const textNoticeStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 300,
    fontSize: 11,
    paddingTop: 16
};

const buttonContainer = {
    width: "100%",
    boxSizing: "border-box",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
};

const paddingContainer = {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 32,
    backgroundColor: "#ffffff"
};

const emptyContainer = {
    height: 76
};

class CourseDetailScreen extends React.Component {

    constructor(props) {
        super(props);
        let { course_id } = props.match.params;
        this.state = {
            courseId: course_id
        };
        this.fetchCourseDetail = this.fetchCourseDetail.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.goBack = this.goBack.bind(this);
        this.gotoPurchase = this.gotoPurchase.bind(this);
        this.registerCourse = this.registerCourse.bind(this);
        this.onFinishedRegister = this.onFinishedRegister.bind(this);
    }

    componentDidMount() {
        this.fetchCourseDetail();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.course != null && nextProps.course.status === "waiting") {
            this.props.history.push("/course/" + nextProps.course.course_id + "/successful");
        }

        const { token } = nextProps.user;
        if (token !== this.props.user.token) {
            this.fetchCourseDetail();
        }
    }

    fetchCourseDetail() {
        let { course_id } = this.props.match.params;
        this.props.onLoading();
        this.props.onFetchCourseDetail(course_id);
    }

    onCloseDialog() {
        if (this.props.invalidateToken) {
            this.props.onRefreshToken();
        }
        this.props.onCloseDialog();
    }

    onFinishedRegister() {
        this.props.onFinished();
        this.props.history.goBack();
    }

    goBack() {
        this.props.history.push(COURSES);
    }

    gotoPurchase() {
        this.props.history.push("/course/" + this.props.course.course_id + "/purchase", this.props.course);
    }

    registerCourse() {
        let { course_id } = this.props.match.params;
        this.props.onLoading();
        this.props.onRegister(course_id);
    }

    render() {
        if (this.props.course === null || this.props.course.course_id !== this.state.courseId || this.props.course.status === "waiting") {
            return (
                <div>
                    <Toolbar title="" history={this.props.history}/>
                    <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
                    <ConfirmDialog
                        open={this.props.dialogError?.show}
                        message={this.props.dialogError?.message}
                        positiveText={"ตกลง"}
                        onPositive={this.onCloseDialog}/>
                </div>
            );
        } else {

            let button = null;
            let description = this.props.course.course_description;

            if (this.props.course.status === "active") {
                button = <button className="register-button" onClick={this.goBack}>เรียนต่อ (หมดอายุ {this.props.course.expiredAt})</button>
            } else if (this.props.course.price === "0") {
                button = <button className="register-button" onClick={this.registerCourse}>เริ่มเรียน</button>
            } else {
                let price = this.props.course.price;
                if (this.props.course.special_price !== "0") {
                    price = this.props.course.special_price
                }
                button = <button className="register-button purchase-state" onClick={this.gotoPurchase}>ซื้อคอร์สนี้ ({price} บาท)</button>
            }

            return (
                <div style={container}>
                    <div>
                        <Toolbar title={this.props.course.course_title} history={this.props.history} onBack={this.goBack}/>
                    </div>
                    <div style={boxContainer}>
                        <div style={coverContainer}>
                            <img src={this.props.course.cover} alt="cover" style={coverStyle}/>
                        </div>
                        <div style={detailContainer}>
                            <div style={detailItemContainer}>
                                <Label icon={icStudent} title={"เพื่อนร่วมเรียน " + this.props.course.total_student + " คน"}/>
                            </div>
                            <div style={detailItemContainer}>
                                <Label icon={icAvailable} title={"เรียนได้ " + this.props.course.available_day + " วัน"}/>
                            </div>
                            <div style={detailItemContainer}>
                                <Label icon={icVocabCount} title={"คำศัพท์ทั้งหมด " + this.props.course.total_vocab + " คำ"}/>
                            </div>
                            <div style={detailItemContainer}>
                                <Label icon={icRepeat} title={"ทบทวนได้ไม่จำกัด"}/>
                            </div>
                        </div>
                        <hr style={dividerStyle}/>
                        <div style={courseDetailContainer}>
                            <div style={textDescriptionStyle}>
                                {Parser(description)}
                            </div>
                            <div style={textNoticeStyle}>
                                หมายเหตุ : คำศัพท์ในแต่ละคอร์สอาจมีคำศัพท์ที่ซ้ำกันได้ สามารถตรวจสอบได้ที่ “ตัวกรอง” ในสมุดคำศัพท์
                            </div>
                            <div style={emptyContainer}/>
                        </div>
                        <div style={buttonContainer}>
                            <Container maxWidth="sm">
                                <div style={paddingContainer}>
                                    {button}
                                </div>
                            </Container>
                        </div>
                    </div>
                    <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
                    <ConfirmDialog
                        open={this.props.dialogError?.show}
                        message={this.props.dialogError?.message}
                        positiveText={"ตกลง"}
                        onPositive={this.onCloseDialog}/>
                    <ConfirmDialog
                        open={this.props.dialogSuccess?.show}
                        message={this.props.dialogSuccess?.message}
                        positiveText={"ตกลง"}
                        onPositive={this.onFinishedRegister}/>
                </div>
            );
        }
    }

}

function mapStateToProps({ courses, user, session, courseReducer }) {
    return {
        course: courseReducer.courseDetail,
        user: user,
        session: session,
        loading: courseReducer.loading,
        dialogError: courseReducer.error,
        dialogSuccess: courseReducer.dialog,
        invalidateToken: courseReducer.shouldInvalidateToken,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchDetail: (token, courseId) => dispatch(fetchCourseDetail(token, courseId)),
        onFetchCourseDetail:(course_id) => dispatch(getCourseDetail(course_id)),
        onLoading: () => dispatch(loading()),
        onCloseDialog: () => dispatch(closeDialog()),
        onRefreshToken: () => dispatch(refreshToken()),
        onRegister: (course_id) => dispatch(registerCourse(course_id)),
        onFinished: () => dispatch(finishRegisterCourse())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailScreen);