import React from "react";
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Image from 'react-image-webp';
import TextField from '@material-ui/core/TextField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import InformButtons from './buttons/informButton';
import DeleteButtons from './buttons/deleteButton';
import CopyButton from './buttons/copyButton';
import DialogCloseScreen from '../../../common/Dialogs/dialogClose';
import DialogLoadingScreen from '../../../common/Dialogs/dialogLoading';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Zoom from '@material-ui/core/Zoom';
import * as ROUTES from '../../../../constants/routes';
import * as css from './pageCss';
import { IconButton } from "material-ui";
import { withRouter } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as DIA from '../../../../constants/dialogs';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { env } from "../../../../constants/config";

function TransitionUp(props) {
    return <Slide {...props} direction="up" style={{justifyContent: "center",fontFamily: "Prompt, sans-serif",}}/>;
}

function makeid(length, type) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    let ftype = "";
    if (type === null) {
        ftype = ""
    } else {
        ftype = type.split('/')[1];
        if (ftype === "jpeg"){
            ftype = "jpg"
        }
    }
    
    return "".concat(result,".",ftype);
}

function numFormat(str) {
    return ('0' + str).slice(-2)
}

class CoursesDetailPurchase extends React.Component {

    constructor(props){
        super(props);

        const tempDate = new Date();
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 14);

        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 14);

        const maxdates = maxDate.getFullYear() + '-' + numFormat((maxDate.getMonth()+1)) + '-' + numFormat(maxDate.getDate());
        const mindates = minDate.getFullYear() + '-' + numFormat((minDate.getMonth()+1)) + '-' + numFormat(minDate.getDate());

        const date = tempDate.getFullYear() + '-' + numFormat((tempDate.getMonth()+1)) + '-' + numFormat(tempDate.getDate());
        const time = numFormat(tempDate.getHours())+':'+numFormat(tempDate.getMinutes());
        const date_time = date + " " + time + ":" + numFormat(tempDate.getSeconds());
        
        this.state = {
            file : null,
            file_name : null,
            imagePreviewUrl : "",
            isToggleOn : false,
            maxDate : maxdates,
            minDate : mindates,
            selectedDate : date ,
            selectedTime : time ,
            dateTime : date_time,
            cost : null,
            receiptURL : null,
            status_code : null,
            purchaseFlag : null,
            dialogOpen : false,
            dialogMessage : "",
            copyDialog : false,
            transition : undefined,
            dialogLoadingOpen : false,
            course: this.props.location.state
        };
        this.handleOnClickImg = this.handleOnClickImg.bind(this);
        this.handleChangeImg = this.handleChangeImg.bind(this);
        this.handleClickRemoveImg = this.handleClickRemoveImg.bind(this);
        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleFireBaseUpload = this.handleFireBaseUpload.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleCopyClick = this.handleCopyClick.bind(this);
        this.handleCopyClose = this.handleCopyClose.bind(this);
    }
    
    handleChangeImg(event) {
        let reader = new FileReader();
        let file = event.target.files[0];
        
        if (typeof(file) != "undefined") {
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    file_name : makeid(15, file.type),
                    imagePreviewUrl: reader.result,
                    isToggleOn: true
                });
            };
    
            const allowed = ["jpeg", "png", "gif"];
            let found = false;
    
            allowed.forEach(function(extension) {
                if (file && file.type.match('image/'+extension) && ((file.size)/1024<2048)) {
                    found = true;
                }
            });
    
            if(found) {
                reader.readAsDataURL(file);
            }
        }
    }

    handleOnClickImg (event) {
        event.target.value = null
    }

    handleClickRemoveImg(event) {
        this.setState({
            file: null,
            file_name : null,
            imagePreviewUrl: "",
            isToggleOn: false
        });
    }
    
    handleOnCancel = () => {
        this.props.history.push(ROUTES.LANDING);
    };

    handleDateChange = (event) => {
        const date_time = event.target.value + " " + this.state.selectedTime + ":00";
        this.setState({
            selectedDate : event.target.value ,
            dateTime : date_time,
        })
    };

    handleTimeChange = (event) => {
        const date_time = this.state.selectedDate + " " + event.target.value + ":00";
        this.setState({
            selectedTime : event.target.value ,
            dateTime : date_time,
        })
    };

    handleCurrencyChange = (event) => {
        this.setState({
            cost : event.target.value,
        })
    };

    handleDialogClose = (event) => {
        if (this.state.purchaseFlag === true){
            this.setState({
                dialogOpen : false ,
            });
            this.props.history.push("/course/" + this.state.course.course_id + "/successful", this.state.course);
        } else {
            this.setState({
                dialogOpen : false ,
            })
        }
    };

    handleFireBaseUpload = (event) => {
        event.preventDefault();

        this.setState({
            dialogLoadingOpen : true ,
        });

        if(this.state.file === null) {
            this.setState({
                purchaseFlag : false,
                dialogOpen : true,
                dialogMessage : "โปรดอัพโหลดสลิปหลักฐานการโอนก่อนการแจ้งโอน",
                dialogLoadingOpen : false ,
            });
        } else {
            const dateRef = this.state.selectedDate.split('-').join('');
            const storageRef = this.props.storage.ref("/aac8c2e7647ec5c4bcd222aa7b27c1/"+dateRef+"/"+this.state.file_name);
            const uploadTask = storageRef.put(this.state.file);

            uploadTask.on('state_changed', (snapShot) => {
                //takes a snap shot of the process as it is happening
                }, (err) => {
                    //catches the errors
                }, () => {
                // gets the functions from storage refences the image storage in firebase by the children
                // gets the download url then sets the image from firebase as the value for the imgUrl key:
                
                storageRef.getDownloadURL()
                .then(fireBaseUrl => {
                    const getURL = fireBaseUrl.split("&")[0];
                    this.setState({
                        receiptURL : getURL,
                    });
                    // promises.push(getURL);
                    const purchaseDate = new Date(this.state.dateTime.replace(/\s/, 'T'));
                    let reqCost = this.state.course.special_price;
                    if (this.state.cost !== null) {
                        reqCost = this.state.cost
                    }
                    const requestOptions = {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': 'Bearer ' + this.props.token,
                        },
                        body: JSON.stringify({
                            course_id: this.state.course.course_id,
                            purchase_datetime: purchaseDate.getTime() / 1000,
                            receipt_url: getURL,
                            cost: reqCost
                        })
                    };

                    fetch(env.host + '/apiAsia/course/purchase', requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            if (data.status_code !== "00"){
                                this.setState({
                                    purchaseFlag : false,
                                    dialogOpen : true,
                                    dialogMessage : data.status_message,
                                    dialogLoadingOpen : false ,
                                });
                                storageRef.delete().then(function() {
                                    // File deleted successfully
                                    this.setState({
                                        receiptURL : null,
                                    })
                                  }).catch(function(error) {
                                    // Uh-oh, an error occurred!
                                  });
                            } else {
                                this.setState({
                                    purchaseFlag : true,
                                    dialogOpen : true,
                                    dialogMessage : DIA.WAITING_APPROVE,
                                    dialogLoadingOpen : false ,
                                })
                            }
                        });

                }).catch(function(error) {
                    // Handle any errors
                });
            })

        }

    };

    handleCopyClick = (Transition) => () => {
        this.setState({
            transition : Transition,
            copyDialog : true
        })
    };

    handleCopyClose  = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            copyDialog : false
        })
    };

    render() {
        return (
            <div style={css.container}>
                <div style={css.containerSummary}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            {this.state.course.course_title}
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'center'}}>
                            {this.state.course.price} บาท
                        </Grid>
                        <Grid item xs={8}>
                            ส่วนลด
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'center'}}>
                            {parseInt(this.state.course.price.split(",").join(""))-parseInt(this.state.course.special_price)} บาท
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                        <Grid item xs={8} style={{fontWeight: 500, fontSize:16}}>
                            ยอดรวม
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'center',fontWeight: 500, fontSize:16, color:"#d0021b"}}>
                            {this.state.course.special_price} บาท
                        </Grid>
                    </Grid>
                </div>
                <div style={css.detail}>
                    <div style={css.topics}>ช่องทางชำระเงิน</div>
                    <div style={{width:"100%"}}>
                        <Image alt="kasikorn" 
                                style={{
                                    width: 40,
                                    height: 40,
                                    overflow: "hidden",
                                    float: "right",
                                }}
                                src={require('../../../../assets/images/kasikorn-icon.png')}
                                webp={require('../../../../assets/images/kasikorn-icon.png')}/>
                        <Typography variant="body2" style={css.fonts}>
                            ชื่อบัญชี ศรันย์ โอวรารินท์
                        </Typography>
                        <Typography variant="body2" style={css.fonts}>
                            ธ.กสิกกรไทย 630-2-36681-9
                        </Typography>
                    </div>
                    
                    <CopyToClipboard text="630-2-36681-9">
                        <CopyButton onClick={this.handleCopyClick(TransitionUp)} />
                    </CopyToClipboard>
                    <Snackbar open={this.state.copyDialog} 
                        autoHideDuration={2000} 
                        onClose={this.handleCopyClose}
                        TransitionComponent={this.state.transition}
                        message="คัดลอกสำเร็จ"
                        key={this.state.transition ? this.state.transition.name : ''}
                    />
                            
                    <div style={css.topics}>จำนวนเงิน (ไม่รวมค่าธรรมเนียม)</div>
                    <CurrencyTextField
                        id="standard-full-width"
                        fullWidth
                        outputFormat="string"
                        currencySymbol=""
                        minimumValue="0"
                        defaultValue={parseInt(this.state.course.special_price)}
                        value={this.state.cost}
                        onChange={this.handleCurrencyChange}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        textAlign="Left"
                    />
                    <div style={css.topics}>สลิปหลักฐานการโอน (ขนาดไม่เกิน 2 MB)</div>
                    <div style={{width:"100%"}}>
                        <div style={this.state.isToggleOn ? css.fileName : css.fileNameHidden}>
                            {this.state.file_name}
                        </div>
                        <div style={this.state.isToggleOn ? css.deleteButton : css.deleteButtonHidden}>
                            <IconButton style={this.state.isToggleOn ? css.common : css.hidden}>
                                <DeleteButtons onClick={this.handleClickRemoveImg}/>
                            </IconButton>
                        </div>
                        <div style={this.state.isToggleOn ? css.hidden : css.common}>
                            <input
                                id="file"
                                type="file"
                                onChange={this.handleChangeImg}
                                onClick={this.handleOnClickImg}
                                style={{
                                    width: 0,
                                    height: 0,
                                    opacity: 0,
                                    overflow: 'hidden',
                                    position: 'absolute',
                                }}
                            />
                            <ListItem button htmlFor="file" component="label" style={{padding:0, marginTop:5}}>
                                <div style={css.uploadButton}>อัพโหลดหลักฐานการโอน</div>
                                <input type="file" style={{ display: "none" }} />
                            </ListItem>
                            <Divider />
                        </div>
                    </div>
                    <div style={{paddingTop: 20}} />
                    <Zoom in={this.state.isToggleOn} style={this.state.isToggleOn ? css.imgs1 : css.hidden}>
                        <Paper elevation={0}>
                            <img alt="upload" style={this.state.isToggleOn ? css.imgs1 : css.hidden}
                                src={this.state.imagePreviewUrl}/>
                        </Paper>
                    </Zoom>
                    
                    <div style={css.ButtonBottomPosition}>
                        <InformButtons onClick={this.handleFireBaseUpload}/>
                    </div>
                    
                    <div style={{paddingBottom:100}} />

                    <DialogCloseScreen
                        dialogOpen={this.state.dialogOpen}
                        handleDialogClose={this.handleDialogClose}
                        purchaseFlag={this.state.purchaseFlag}
                        dialogMessage={this.state.dialogMessage}
                    />

                    <DialogLoadingScreen dialogLoadingOpen={this.state.dialogLoadingOpen}/>
                </div>
                
            </div>
        );
    }

}

function mapStateToProps(state) {
	return {
		firebase : state.firebase.app,
        db : state.firebase.db,
        storage : state.firebase.storage,
		session : state.session,
        ui : state.ui,
        token : state.user.token
	};
}


export default connect(mapStateToProps)(withRouter(CoursesDetailPurchase));