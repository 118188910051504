import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import LoginScreen from "./screen/login";
import TermScreen from "./screen/term";
import PrivacyScreen from "./screen/privacy";
import RegisterScreen from "./screen/register";
import HomeScreen from "./screen/home";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {Container} from "@material-ui/core";
import * as ROUTES from '../constants/routes';
import VocabBookScreen from './screen/vocab-book';
import {connect} from "react-redux";
import withAuth from "./session/withAuth";
import CoursesListScreen from "./screen/courses";
import CoursesPurchaseScreen from "./screen/courses/purchase";
import CourseDetailScreen from "./screen/courses/detail";
import CoursesPurchaseSuccessScreen from "./screen/courses/purchase/success";
import MyBookScreen from "./screen/mybook";
import LogoutScreen from "./screen/logout";
import NewVocabScreen from "./screen/vocab-new";
import ChallengeVocab from "./screen/vocab-challenge";
import ChallengeSummaryVocab from "./screen/vocab-challenge/summary";
import ScrollToTop from "./common/ScrollToTop";
import RepeatVocab from "./screen/vocab-repeat";
import QuestionScreen  from "./screen/home/question";
import NotifyScreen from "./screen/notify";
import { refreshToken } from "../state/action/users-action";
import RepeatResultScreen from "./screen/vocab-repeat/result/result-repeat";

const heightContainer = {
    height: "100%"
};

class App extends React.Component {

    render() {
        return (
            <div className="application">
                <Container maxWidth="sm" style={heightContainer}>
                    <Switch>
                        <MuiThemeProvider>
                            <div style={{height:"100%"}}>
                                <ScrollToTop />
                                <Route exact path={ROUTES.LANDING} component={withAuth(HomeScreen)}/>
                                <Route exact path={ROUTES.QUESTION} component={withAuth(QuestionScreen)}/>
                                <Route exact path={ROUTES.LOG_IN} component={LoginScreen}/>
                                <Route exact path={ROUTES.REGISTER} component={RegisterScreen}/>
                                <Route exact path={ROUTES.TERM} component={TermScreen}/>
                                <Route exact path={ROUTES.PRIVACY} component={PrivacyScreen}/>
                                <Route exact path={ROUTES.VOCAB_BOOK} component={withAuth(MyBookScreen)}/>
                                <Route exact path={ROUTES.COURSES} component={withAuth(CoursesListScreen)}/>
                                <Route exact path={ROUTES.COURSE_DETAIL} component={withAuth(CourseDetailScreen)}/>
                                <Route exact path={ROUTES.COURSES_PURCHASE} component={withAuth(CoursesPurchaseScreen)}/>
                                <Route exact path={ROUTES.COURSES_SUCCESS_PURCHASE} component={withAuth(CoursesPurchaseSuccessScreen)}/>
                                <Route exact path={ROUTES.NEW_VOCAB} component={withAuth(NewVocabScreen)}/>
                                <Route exact path={ROUTES.REPEAT_VOCAB} component={withAuth(RepeatVocab)}/>
                                <Route exact path={ROUTES.CHALLENGE} component={withAuth(ChallengeVocab)}/>
                                <Route exact path={ROUTES.CHALLENGESUM} component={withAuth(ChallengeSummaryVocab)}/>
                                <Route exact path={ROUTES.NOTIFY} component={withAuth(NotifyScreen)}/>
                                <Route exact path={ROUTES.REPEAT_RESULT} component={withAuth(RepeatResultScreen)}/>
                                <Route exact path={ROUTES.LOGOUT} component={LogoutScreen}/>
                            </div>
                        </MuiThemeProvider>
                    </Switch>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        firebase: state.firebase.app,
        db: state.firebase.db,
        user: state.user,
        session: state.session
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onRefreshToken: () => dispatch(refreshToken())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));