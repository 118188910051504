import React from "react";
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import ItemVocabBook from "./itemBook";
import { withStyles } from '@material-ui/styles';
import "./detailbook.css";

const container = {
    marginTop: 20,
    borderRadius: 20,
    boxShadow: `0 10px 20px 0 rgba(0, 64, 128, 0.15)`,
    backgroundColor: `#ffffff`,
    width: "100%",
    height: "100%",
}

const appBarStyle = {
    borderRadius: 20,
    boxShadow: `0 0 0 0 rgba(0, 64, 128, 0.15)`,
    backgroundColor: `#ffffff`,
    color: "black",
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
}

const tabStyle = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 16,
    color: "#000000",
    paddingBottom: 0,
}

const fontNotFound = {
    fontSize: `12px`,
    color: `#717479`,
}

const styleTabPanel = {
    paddingTop: 10, 
    paddingRight: 5,
    minHeight: `100vh`,
}

const styles = theme => ({
    customTabIndicator: {
        backgroundColor: "#006b6d"
    },
});

function NotFound() {
    return <div>
        <div>ไม่พบผลลัพธ์</div>
        <div style={fontNotFound}>ลองเลือกสถานะอื่น หรือตัวกรองอื่น</div>
    </div>;
}
class DetailVocabBook extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: "1",
            index: 0,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeIndex = this.handleChangeIndex.bind(this)
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue,
        })
    }

    handleChangeIndex = (index) => {
        this.setState({
            index: index,
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={container}>
                <TabContext value={this.state.value}>
                    <AppBar position="static" style={appBarStyle}>
                        <TabList
                            classes={{ indicator: classes.customTabIndicator }}
                            onChange={this.handleChange} aria-label="content tabs">
                            <Tab label="ทวนอยู่" value="1" style={tabStyle} selectionFollowsFocus />
                            <Tab label="จำแม่น" value="2" style={tabStyle} />
                            <Tab label="ทั้งหมด" value="3" style={tabStyle} />
                        </TabList>
                    </AppBar>
                    <TabPanel
                        value="1"
                        style={styleTabPanel}>
                        {
                            this.props.repeatBook.length !== 0 ?
                                this.props.repeatBook.map(data => {
                                    return <ItemVocabBook vocab={data} />
                                }) : <NotFound />
                        }
                    </TabPanel>
                    <TabPanel
                        value="2"
                        style={styleTabPanel}>
                        {
                            this.props.doneBook.length !== 0 ?
                                this.props.doneBook.map(data => {
                                    return <ItemVocabBook vocab={data} />
                                }) : <NotFound />
                        }
                    </TabPanel>
                    <TabPanel
                        value="3"
                        style={styleTabPanel}>
                        {
                            this.props.vocabBook.length !== 0 ?
                                this.props.vocabBook.map(data => {
                                    return <ItemVocabBook vocab={data} />
                                }) : <NotFound />
                        }
                    </TabPanel>
                </TabContext>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        // fetchCourseList: (token) => dispatch(fetchCourseList(token)),
    };
}

function mapStateToProps({ }) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DetailVocabBook));