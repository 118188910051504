export const LOGOUT_ACTION = {
    LOGOUT_SUCCESS: "logout_success_action",
    LOGOUT_FAILED: "logout_failed_action"
}

const initialState = {
    logoutSuccess: null
}

export default function logoutReducer(state = initialState, { type, payload }) {
    switch(type) {
        case LOGOUT_ACTION.LOGOUT_SUCCESS: {
            return {
                ...state,
                logoutSuccess: true
            }
        }
        case LOGOUT_ACTION.LOGOUT_FAILED: {
            return {
                ...state,
                logoutSuccess: false
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}