import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Icon from "@material-ui/core/Icon";
import FacebookIcon from '../../../assets/images/facebook.svg';
import { connect } from 'react-redux';
import {loginWithFacebook} from "./controller/login-controller";

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
}));

const ColorButton = withStyles((theme) => ({
	root: {
		color: "white",
		backgroundColor: "#005b8e",
		borderRadius: 23,
		textTransform: 'none',
		width: 325,
		height: 40,
		paddingLeft: 60,
		paddingRight: 60,
		fontSize:14,
		fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
		'&:hover': {
			backgroundColor: '#005b8e',
			borderColor: '#0062cc',
			boxShadow: 'none',
		},
	},
}))(Button);

function FacebookButtons(props) {
	const classes = useStyles();
	const svgIcon = (
		<Icon>
		<img alt="edit" 
				src={FacebookIcon} 
				style={{width:14,height:14,marginBottom:"25%"}}
		/>
		</Icon>
	);

	return (
		<div>
			<ColorButton
				variant="outlined"
				size="large"
				className={classes.Button}
				startIcon={svgIcon}
				onClick={() => props.onLogin()}>
				เข้าสู่ระบบด้วย Facebook
			</ColorButton>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		firebase: state.firebase.app
	}
}

function mapDispatchToProps(dispatch) {
	return {
		onLogin: () => dispatch(loginWithFacebook())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookButtons);
