import React from "react";
import { connect } from "react-redux";
import * as ROUTES from "../../../constants/routes";
import { challengeButtonStyle } from "./style";
import {setErrorOverview} from "./controller/home-controller";

class ChallengeButton extends React.Component {

    constructor(props) {
        super(props);

        this.goToChallengeScreen = this.goToChallengeScreen.bind(this)
    }

    goToChallengeScreen() {
        let check_err = 1
        if (this.props.master_vocab < 100) {
            this.props.setErrorOverview("ต้องมีคำศัพท์ที่จำแม่นอย่างน้อย 100 คำ")
            check_err = 0
        }

        if (this.props.challenge_energy === 0) {
            this.props.setErrorOverview("จัดอันดับได้สูงสุด 4 ครั้งต่อวัน")
            check_err = 0
        }
        
        if (check_err === 1) {
            this.props.history.push(ROUTES.CHALLENGE);
        }
    }

    render() {
        return (
            <button onClick={this.goToChallengeScreen} style={challengeButtonStyle}>
                จัดอันดับ
            </button>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setErrorOverview: (message) => dispatch(setErrorOverview(message)),
    }
}

function mapStateToProps({ homeReducer }) {
    return {
        challenge_energy: homeReducer.challenge.challenge_energy,
        master_vocab: homeReducer.master_vocab,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeButton);
