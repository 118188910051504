import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
}));

const ColorButton = withStyles((theme) => ({
	root: {
		color: "white",
		backgroundColor: "#006b6d",
		borderRadius: 23,
		textTransform: 'none',
		width: 145,
		height: 40,
		paddingLeft: 60,
		paddingRight: 60,
		fontSize: 16,
		fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
		'&:hover': {
			backgroundColor: '#006b6d',
			boxShadow: 'none',
		},
		'&:disabled': {
			backgroundColor: '#cbcbcb'
		}
	},
}))(Button);

export default function SubmitButtons({onClick, disabled}) {
	const classes = useStyles();
	return (
		<div>
			<ColorButton
				variant="outlined"
				size="large"
				className={classes.Button}
				// startIcon={svgIcon}
				onClick={onClick}
				disabled={disabled}>
				ยืนยัน
			</ColorButton>
		</div>
	);
}
