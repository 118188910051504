import React, { useState, useEffect } from "react";
import Spacer from "../../../common/space";
import { filterItem, filterTitle, VerticalContainer } from "../styles";
import FilterItem from "./item-filter";

export default function FilterList(props) {

    const [ filter, setFilter ] = useState([]);

    useEffect(() => {
        if (props.filter !== undefined) {
            setFilter([...props.filter]);
        }
    }, []);

    const onSelectedFilter = (id) => {
        props.onSelect(id);
        setFilter((filter) => 
            filter.map((f) => {
                if (f.id === id) {
                    return {
                        ...f,
                        selected: true
                    }
                } else {
                    return {
                        ...f,
                        selected: false
                    }
                }
            })
        );
    }

    return (
        <div style={{...VerticalContainer, paddingTop: 0, paddingBottom: 0}}>
            <div style={filterTitle}>
                {props.title}
            </div>
            <div style={filterItem}>
                { 
                    filter.map((f) => {
                        return <FilterItem filter={f} onSelected={onSelectedFilter}/>
                    })
                }
            </div>
            <Spacer spaceHeight={24} spaceWidth={0}/>
        </div>
    );

}