import React from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToolBar from "../../common/toolbar";
import {QUESTION} from "../../../constants/topics";
import * as ROUTES from "../../../constants/routes";

const fontHeadStyle = {
    color: "#000000",
    fontFamily: "Prompt, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

};

const fontDetialStyle = {
    color: "#000000",
    fontFamily: "Prompt, sans-serif",
    fontSize: 11,
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

};

const Accordion = withStyles({
    root: {
        padding: '10px',
        paddingBottom: 0,
        paddingTop: 0,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderRadius: '25px',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: '#ffffff',
      marginBottom: -1,
      minHeight: 40,
      '&$expanded': {
        minHeight: 40,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

const container = {
    marginTop: 20,
    borderRadius: 20,
    boxShadow: `0 2px 20px 0 rgba(0, 64, 128, 0.1)`,
    backgroundColor: `#ffffff`,
    width: "100%",
    height: "100%",
}

class QuestionScreen extends React.Component {

    constructor(props) {
        super(props);
        this.handleBackToLanding = this.handleBackToLanding.bind(this)
    }

    handleBackToLanding() {
        this.props.history.push(ROUTES.LANDING)
    }

    render() {
        
        return (
            <div style={{width: "100%",height: "100%"}}>
                <ToolBar title={QUESTION} onBack={this.handleBackToLanding} history={this.props.history}/>
                <div style={container}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={fontHeadStyle}>จำแม่น ทวนอยู่ รอเรียนรู้ คืออะไร</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingBottom:0}}>
                        <Typography style={fontDetialStyle}>
                            ทั้ง 3 เป็นสถานะของคำศัพท์<br/>
                            1. รอเรียนรู้ : คำศัพท์ที่สามารถเรียนได้แต่ยังไม่ได้ถูกเรียนรู้<br/>
                            2. ทวนอยู่ : คำศัพท์ที่เพิ่งเรียนรู้ และทวนทวนไม่ถึง 3 ครั้ง<br/>
                            3. จำแม่น : คำศัพท์ที่ถูกทบทวนและตอบถูกต่อเนื่อง 3 ครั้ง คำศัพท์ที่จำแม่นจะไม่ต้องทบทวนอีก จนกว่าจะตอบผิดในการจัดอันดับและกด “เลือกทบทวนและสรุปผล”<br/>
                            <hr style={{borderColor:'rgba(0, 64, 128, 0.1)', marginBottom:0, marginTop:10}}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={fontHeadStyle}>เราจะทบทวนศัพท์ครั้งได้เมื่อไหร่</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingBottom:0}}>
                        <Typography style={fontDetialStyle}>
                            การทบทวนศัพท์จะทบทวนได้ตามหลัก Spaced Repetitio<br/>
                            ถ้า “เลือกทบทวน” (สถานะเริ่มต้น) สามารถทบทวนได้ในวันถัดไป<br/>
                            ถ้าทบทวนครั้งที่ 1 แล้ว สามารถทบทวนได้อีกใน 2 วันถัดไป<br/>
                            ถ้าทบทวนครั้งที่ 2 แล้ว สามารถทบทวนได้อีกใน 4 วันถัดไป<br/>
                            ถ้าทบทวนครั้งที่ 3 แล้ว ศัพท์คำนี้จะกลายเป็น จำแม่น!<br/>
                            ถ้าตอบผิดในการทบทวนศัพท์คำนี้จะกลายเป็น สถานะเริ่มต้น<br/>
                            แต่ถ้าเลือก “จำแม่น” ศัพท์คำนั้นจะไม่ต้องทบทวนจนกว่าจะตอบผิดในการจัดอันดับ<br/>
                            <hr style={{borderColor:'rgba(0, 64, 128, 0.1)', marginBottom:0, marginTop:10}}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={fontHeadStyle}>ทำไมทบทวนศัพท์แล้ว "ทวนอยู่" ไม่อัพเดท</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingBottom:0}}>
                        <Typography style={fontDetialStyle}>
                            ในการทบทวนคำศัพท์ตอบถูกต่อเนื่องกัน 3 ครั้ง<br/>
                            คำศัพท์ถึงถูกปรับเป็น จำแม่น<br/>
                            ระหว่างทบทวนถ้าตอบผิดต้องกลับมาเริ่มทบทวนใหม่<br/>
                            <hr style={{borderColor:'rgba(0, 64, 128, 0.1)', marginBottom:0, marginTop:10}}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={fontHeadStyle}>การจัดอันดับคืออะไร</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingBottom:0}}>
                        <Typography style={fontDetialStyle}>
                            การจัดอันดับเป็นการแข่งขัดเพื่อทบทวนคำศัพท์<br/>
                            โดยจะเป็นนำคำศัพท์ที่อยู่ในสถานะ จำแม่น ทั้งหมดมาทดสอบ<br/>
                            โดยจะมีอัลกอริทึมพิเศษที่ช่วยให้น้องๆสามารถทวนศัพท์เฉพาะศัพท์ที่ยังจำไม่แม่นได้<br/>
                            โดยใน 1 วันสามารถเข้าร่วมการจัดอันดับได้ทั้งหมด 4 ครั้ง<br/>
                            และจะ Reset อันดับทุกๆต้นเดือน<br/>
                            <hr style={{borderColor:'rgba(0, 64, 128, 0.1)', marginBottom:0, marginTop:10}}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={fontHeadStyle}>เปลี่ยนชื่อและอีเมล์ได้หรือไม่</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingBottom:0}}>
                        <Typography style={fontDetialStyle}>
                            ปัจจุบันยังไม่สามารถเปลี่ยนชื่อหรืออีเมล์ได้หากจำเป็นต้องการ<br/>
                            เปลี่ยนสามารถติดต่อพี่ๆแอดมินได้<br/>
                            <hr style={{borderColor:'rgba(0, 64, 128, 0.1)', marginBottom:0, marginTop:10}}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={fontHeadStyle}>Reset สถานะคำศัพท์ทั้งหมดได้หรือไม่</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingBottom:0}}>
                        <Typography style={fontDetialStyle}>
                            ปัจจุบันยังไม่สามารถ Reset สถานะคำศัพท์ได้<br/>
                            <hr style={{borderColor:'rgba(0, 64, 128, 0.1)', marginBottom:0, marginTop:10}}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={fontHeadStyle}>ติดต่อเราได้อย่างไร</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{paddingBottom:0}}>
                        <Typography style={fontDetialStyle}>
                            LINE : @Alohavocab<br/>
                            IG : Alohavocab<br/>
                            FB : Aloha จำศัพท์แม่น<br/>
                            <hr style={{borderColor:'rgba(0, 64, 128, 0.1)', marginBottom:0, marginTop:10}}/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        // onLoading: () => dispatch(homeLoading()),
    }
}

function mapStateToProps({ homeReducer }) {
    return {
        // loading: homeReducer.loading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionScreen);