import getStoredStateMigrateV4 from "redux-persist/lib/integration/getStoredStateMigrateV4";
import * as CON from "./const-repeat";

const initialState = {
    firstTime: true,
    selectedVocab : [],
    correctVocabs: [],
    incorrectVocabs: [],
    requestUpdate: [],
    resultVocab : [],
    isRepeat: false,
    toggleVoice : false,
    loopRepeat : 0,
    currentIndexVocab : 0,
    nextIndexVocab: 1,
    updateStackFirst: true,
    audio:undefined,
    stackVocabFirst : {
        vocab_id : "",
        vocab : "",
        image : "",
        meaning : "",
        sentense : "",
        choice2 : "",
        choice3 : "",
        noun : false,
        verb : false,
        adjective : false,
        adverb : false,
        preposition : false,
        pronoun : false,
        conjunction : false,
        isHiddenUI : true,
        choices : [],
        isAnswer: false
    },
    stackVocabSecond : {
        vocab_id : "",
        vocab : "",
        image : "",
        meaning : "",
        sentense : "",
        choice2 : "",
        choice3 : "",
        noun : false,
        verb : false,
        adjective : false,
        adverb : false,
        preposition : false,
        pronoun : false,
        conjunction : false,
        isHiddenUI : true,
        choices : [],
        isAnswer: false
    },
    isHiddenNextButton : true,
    loading: false,
    dialogError: {
        show: false,
        message: ""
    },
    updateRepeatVocabSuccess: false,
    incorrectCustomVocabs: [],
    correctCustomVocabs: [],
    loadRepeatVocabSuccess: false
};

export default function repeatVocabReducer(state = initialState, { type, payload }) {
    switch (type) {
        case CON.SET_AUDIO : {
            return {
                ...state,
                audio : payload.audio,
            }
        }
        case CON.COUNT_LOOP_REPEAT : {

            let tmpLoopRepeat = state.loopRepeat
            if(state.currentIndexVocab === 0) {
                tmpLoopRepeat = tmpLoopRepeat + 1
            }

            return {
                ...state,
                loopRepeat : tmpLoopRepeat,
                toggleVoice : !state.toggleVoice,
            }
        }
        case CON.IS_HIDDEN_NEXT_BUTTON : {
            return {
                ...state,
                isHiddenNextButton : payload.flag,
            }
        }
        case CON.LOADING_REPEAT_VOCAB: {
            return {
                ...state,
                loading: true
            }
        }
        case CON.SETUP_SELECTED_VOCAB : {
            return {
                ...state,
                stackVocabFirst : payload.stackVocabFirst,
                stackVocabSecond : payload.stackVocabSecond,
                selectedVocab : payload.selectedVocab,
                requestUpdate: payload.requestUpdate,
                resultVocab : payload.resultVocab,
                loading: false
            }
        }
        case CON.NEXT_VOCAB : {
            return {
                ...state,
                currentIndexVocab : payload.currentIndexVocab,
                nextIndexVocab: payload.nextIndexVocab,
                stackVocabFirst : payload.stackVocabFirst,
                stackVocabSecond : payload.stackVocabSecond,
                isHiddenNextButton: payload.hideNextButton,
                updateStackFirst: payload.updateStackFirst
            }
        }
        case CON.SUBMIT_ANSWER : {
            return {
                ...state,
                stackVocabFirst : payload.stackVocabFirst,
                stackVocabSecond : payload.stackVocabSecond,
                isHiddenNextButton: payload.isHiddenNextButton,
                correctVocabs: payload.correctVocabs,
                selectedVocab: payload.selectedVocab,
                requestUpdate: payload.requestUpdate
            }
        }
        case CON.CLOSE_REPEAT_DIALOG: {
            return {
                ...state,
                loading: false,
                dialogError: {
                    show: false,
                    message: ""
                }
            }
        }
        case CON.UPDATE_REPEAT_VOCAB_SUCCESS: {
            return {
                ...state,
                updateRepeatVocabSuccess: true,
            }
        }
        case CON.UPDATE_REPEAT_VOCAB_ERROR: {
            return {
                ...state,
                loading: false,
                dialogError: {
                    ...payload.dialogError
                }
            }
        }
        case CON.CLEAR_VOCAB_STATE: {
            return {
                ...state,
                firstTime: true,
                selectedVocab : [],
                correctVocabs: [],
                requestUpdate: [],
                resultVocab : [],
                currentIndexVocab : 0,
                nextIndexVocab: 1,
                updateStackFirst: true,
                isRepeat: false,
                toggleVoice : false,
                loopRepeat : 0,
                stackVocabFirst : {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                    isHiddenUI : true,
                    choices : [],
                    isAnswer: false
                },
                stackVocabSecond : {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                    isHiddenUI : true,
                    choices : [],
                    isAnswer: false
                },
                isHiddenNextButton : true,
                loading: false,
                dialogError: {
                    show: false,
                    message: ""
                },
                updateRepeatVocabSuccess: false,
                correctCustomVocabs: [],
                incorrectCustomVocabs: [],
                loadRepeatVocabSuccess: false
            }
        }
////////////////////////////////////////////////////////////////////////////////////
        case CON.FETCH_VOCAB_REPEAT_SUCCESS : {
            if (payload.selectedVocab.length === 0) {
                return {
                    ...state,
                    loading : false,
                    isRepeat: true,
                    selectedVocab : payload.selectedVocab,
                    loadRepeatVocabSuccess: true,
                    updateRepeatVocabSuccess: true
                }
            }
            return {
                ...state,
                loading : false,
                isRepeat: true,
                selectedVocab : payload.selectedVocab,
                loadRepeatVocabSuccess: true
            }
        }
        case CON.FETCH_VOCAB_REPEAT_FAIL : {
            return {
                ...state,
                loading : false,
                dialogError : {
                    show : true,
                    message : payload.error
                },
            }
        }
        case CON.SET_FIRST_TIME: {
            return {
                ...state,
                loading: false,
                firstTime: payload.firstTime
            }
        }
        case CON.SET_REPEAT: {
            return {
                ...state,
                isRepeat: payload.repeat
            }
        }
        case CON.SET_CUSTOM_VOCAB_ANSWER: {
            if (payload.remember === true) {
                return {
                    ...state,
                    correctCustomVocabs: [...state.correctCustomVocabs, payload.vocab],
                }
            }
            return {
                ...state,
                incorrectCustomVocabs: [...state.incorrectCustomVocabs, payload.vocab],
            }
        }
        default : {
            return state;
        }
    }

}