import React from "react";
import { Box } from "@material-ui/core";

const ListRank = (props) => {
     const rankRender = props.ranking.map((rank, index) => {
        return (
            <Box display="flex" pr={1} mb={1} key={index}>
                <Box flexGrow={1} pr={1.5}>
                    <t style={{fontFamily: "Prompt, sans-serif", fontWeight: "400", color: "#4a4a4a"}}>{rank.user}</t>
                </Box>
                <Box>
                <t style={{fontFamily: "Prompt, sans-serif", fontWeight: "400", color: "#4a4a4a"}}>{rank.score}</t>
                </Box>
            </Box>
        );
    });
    return (
        <Box>
            {rankRender}
        </Box>
    )
}

export default ListRank;