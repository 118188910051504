import React from "react";

const container = {
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center"
};

const iconStyle = {
    width: 14,
    height: 14
};

const textStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 300,
    fontSize: 13,
    color: "#000000",
    marginLeft: 8
};

class Label extends React.Component {

    render() {
        return (
            <div style={container}>
                <img src={this.props.icon} alt="icon" style={iconStyle}/>
                <div style={textStyle}>{this.props.title}</div>
            </div>
        );
    }

}

export default Label;