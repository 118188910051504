import React from "react";
import {connect} from "react-redux";
import Toolbar from "../../common/toolbar";
import ListOwnCourse from "./list/list-own-course";
import ListCourse from "./list/list-course";
import {NEW_VOCAB} from '../../../constants/topics';
import { fetchCourseList } from "../../../state/action/course-action";
import {closeDialog, getCourseList, loading} from "./controller/course-controller";
import {refreshToken} from "../../../state/action/users-action";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";
import * as ROUTES from "../../../constants/routes";

const container = {
    display: "block"
};
class CoursesListScreen extends React.Component {

    constructor(props) {
        super(props);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onLoadCourseList = this.onLoadCourseList.bind(this);
        this.handleBackToLanding = this.handleBackToLanding.bind(this);
    }

    componentDidMount() {
        this.onLoadCourseList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { token } = nextProps.userState;
        if (token !== this.props.userState.token) {
            this.onLoadCourseList();
        }
    }

    onLoadCourseList() {
        this.props.onLoading();
        this.props.onFetchCourseList("");
    }

    onCloseDialog() {
        if (this.props.invalidateToken) {
            this.props.onRefreshToken();
        }
        this.props.onCloseDialog();
    }

    handleBackToLanding() {
        this.props.history.push(ROUTES.LANDING)
    }

    render() {
        return (
            <div style={container}>
                <Toolbar onBack={this.handleBackToLanding} title={NEW_VOCAB} history={this.props.history}/>
                <ListOwnCourse history={this.props.history}/>
                <ListCourse history={this.props.history}/>
                <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
                <ConfirmDialog
                    open={this.props.dialogError?.show}
                    message={this.props.dialogError?.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onCloseDialog}/>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        fetchCourseList: (token) => dispatch(fetchCourseList(token)),
        onFetchCourseList: (course_id) => dispatch(getCourseList(course_id)),
        onLoading: () => dispatch(loading()),
        onCloseDialog: () => dispatch(closeDialog()),
        onRefreshToken: () => dispatch(refreshToken())
    };
}

function mapStateToProps({ui, firebase, user, session, courseReducer}) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        loading: courseReducer.loading,
        dialogError: courseReducer.error,
        invalidateToken: courseReducer.shouldInvalidateToken
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesListScreen);