import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducer";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from "redux-thunk";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'user'
    ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    persistedReducer, /* preloadedState, */
    storeEnhancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

export default store;