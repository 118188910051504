import React from "react"
import ReactDOM from 'react-dom'
import App from "./components/App"
import { Provider } from "react-redux"
import store from "./state/store"
import { persistor } from "./state/store"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import "./app.css"
import './index.css'
import * as serviceWorker from './serviceWorker'
import { PersistGate } from 'redux-persist/integration/react'

const history = createBrowserHistory();

ReactDOM.render((
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <App/>
            </Router>
        </PersistGate>
    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();