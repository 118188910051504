import * as CON from "./const-challenge";
import {env} from "../../../../constants/config";

///////////////////////////////////////////////////////////////////////////////////

function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
}

function createChoiceList(vocab){
    if (vocab !== undefined) {
        return shuffle([
            {
                meaning: vocab.meaning,
                correct: true,
                state: "normal"
            },
            {
                meaning: vocab.choice2,
                correct: false,
                state: "normal"
            },
            {
                meaning: vocab.choice3,
                correct: false,
                state: "normal"
            },
        ]);
    }
    return [];
}



function updateChoice(vocab,selectedChoice){
    return vocab.choices.map((choice) => {
        if (choice.meaning === selectedChoice.meaning && selectedChoice.correct === true) {
            return {
                ...choice, 
                state: "true"
            }
        } else if (choice.meaning === selectedChoice.meaning && selectedChoice.correct === false) {
            return {
                ...choice,
                state: "false"
            }
        } else if (choice.correct === true && selectedChoice.correct === false) {
            return {
                ...choice,
                state: "true"
            }
        } else {
            return {
                ...choice
            }
        }
    })
}

function updateResultVocab(vocabs, updateVocab) {
    if (updateVocab !== undefined) {
        const vocabsList = [];
        vocabs.forEach((v) => {
            if (v.vocab_id === updateVocab.vocab_id) {
                vocabsList.push({
                    ...v,

                    isAnswerCorrect: true
                });
            } else {
                vocabsList.push(v);
            }
        });
        return vocabsList;
    }
    return vocabs;
}

function updateRequestApi(request, vocab_id, result) {
    return request.map((vocab) => {
        if (vocab.vocab_id === vocab_id && result === true) {
            return {
                ...vocab,
                result: true
            }
        } else if (vocab.vocab_id === vocab_id && result === false) {
            return {
                ...vocab,
                result: false
            }
        } else {
            return {
                ...vocab
            }
        }
    })
}


///////////////////////////////////////////////////////////////////////////////////

export function hiddenNextButton(){
    return {
        type: CON.IS_HIDDEN_NEXT_BUTTON,
        payload: {
            flag : true,
        }
    };
}

///////////////////////////////////////////////////////////////////////////////////

export function loading() {
    return {
        type: CON.LOADING_CHALLENGE_VOCAB
    }
}

export function closeChallengeDialog() {
    return {
        type: CON.CLOSE_CHALLENGE_DIALOG
    }
}

export function submitAnswer(selectedChoice){
    return function(dispatch, getState) {
        const vocabCurrentIndex = getState().challengeVocabReducer.vocabCurrentIndex;
        const challengeVocab = getState().challengeVocabReducer.challengeVocab;
        const correctVocab = getState().challengeVocabReducer.correctVocab;
        const updateVocabFirst = getState().challengeVocabReducer.updateVocabFirst;
        const requestUpdateVocab = getState().challengeVocabReducer.requestUpdateVocab;
        let tempIndexRequestUpdateVocab = getState().challengeVocabReducer.indexRequestUpdateVocab;
        let tempCorrectRequestUpdateVocab = getState().challengeVocabReducer.correctRequestUpdateVocab;
        let tempResultFirst = getState().challengeVocabReducer.vocabFirst;
        let tempResultSecond = getState().challengeVocabReducer.vocabSecond;

        let updateCorrectVocabs = correctVocab;
        let updateResultSelectedVocab = challengeVocab;
        let updateTempRequest = requestUpdateVocab;

        if (updateVocabFirst && tempResultFirst.isAnswer === false){
            tempResultFirst = {
                ...tempResultFirst,
                choices : updateChoice(tempResultFirst, selectedChoice),
                isAnswer: true,
                isHiddenUI: selectedChoice.correct
            };

            if (selectedChoice.correct === true) {
                updateCorrectVocabs.push(tempResultFirst);
                updateResultSelectedVocab = updateResultVocab(updateResultSelectedVocab, tempResultFirst);
                tempCorrectRequestUpdateVocab = tempCorrectRequestUpdateVocab + 1
            }

            updateTempRequest = updateRequestApi(updateTempRequest, tempResultFirst.vocab_id, selectedChoice.correct);

        } else if (!updateVocabFirst && tempResultSecond.isAnswer === false) {
            tempResultSecond = {
                ...tempResultSecond,
                choices : updateChoice(tempResultSecond, selectedChoice),
                isAnswer: true,
                isHiddenUI: selectedChoice.correct
            };

            if (selectedChoice.correct === true) {
                correctVocab.push(tempResultSecond);
                updateResultSelectedVocab = updateResultVocab(updateResultSelectedVocab, tempResultSecond);
                tempCorrectRequestUpdateVocab = tempCorrectRequestUpdateVocab + 1
            }

            updateTempRequest = updateRequestApi(updateTempRequest, tempResultSecond.vocab_id, selectedChoice.correct);

        } else {
            return;
        }

        dispatch({
            type : CON.SUBMIT_ANSWER,
            payload : {
                vocabFirst : tempResultFirst,
                vocabSecond : tempResultSecond,
                hiddenNextButton: selectedChoice.correct,
                correctVocab: updateCorrectVocabs,
                challengeVocab: updateResultSelectedVocab,
                requestUpdateVocab: updateTempRequest,
                correctRequestUpdateVocab : tempCorrectRequestUpdateVocab,
                indexRequestUpdateVocab: tempIndexRequestUpdateVocab + 1,
            }
        })
    }
}

export function setupSelectedVocab(listVocab){
    return function(dispatch, getState) {
        const getStateVocab = getState().challengeVocabReducer;
        const challengeVocab = listVocab.map((v) => {
            return {
                ...v,
                isAnswerCorrect: false
            }
        });
        const requestUpdateVocab = listVocab.map((v) => {
            return {
                vocab_id: v.vocab_id,
                result: false
            }
        });
        let tempVocab = listVocab[getStateVocab.vocabCurrentIndex];
        let tempNextVocab = listVocab[getStateVocab.vocabCurrentIndex+1];

        let tempStackVocabFirst = {
            ...tempVocab,
            isHiddenUI : true,
            isAnswer: false,
            choices : createChoiceList(tempVocab)
        };
        let tempStackVocabSecond= {
            ...tempNextVocab,
            isHiddenUI : true,
            isAnswer: false,
            choices : createChoiceList(tempNextVocab)
        };

        dispatch({
            type: CON.SETUP_CHALLENGE_VOCAB,
            payload: {
                challengeVocab: challengeVocab,
                requestUpdateVocab: requestUpdateVocab,
                vocabFirst : tempStackVocabFirst,
                vocabSecond : tempStackVocabSecond,
            }
        });
    }
}

export function nextVocab(){
    return function(dispatch, getState) {

        const {
            challengeVocab,
            vocabFirst,
            vocabSecond,
            vocabCurrentIndex,
            vocabNextIndex,
            updateVocabFirst
        } = getState().challengeVocabReducer;

        let tempStackVocabFirst = vocabFirst;
        let tempStackVocabSecond = vocabSecond;

        let tempCurrentIndexVocab = vocabNextIndex;
        let tempNextIndexVocab = vocabCurrentIndex;

        let nextReplaceVocab;
        let indexNextVocab = tempCurrentIndexVocab;
        for (let i = 0; i<challengeVocab.length; i++) {
            indexNextVocab++;
            const newIndex = indexNextVocab % challengeVocab.length;
            const nextVocab = challengeVocab[newIndex];
            if (nextVocab.isAnswerCorrect === false) {
                nextReplaceVocab = nextVocab;
                tempNextIndexVocab = newIndex;
                break;
            }
        }

        if(updateVocabFirst) {
            tempStackVocabFirst = {
                ...nextReplaceVocab,
                isHiddenUI : true,
                isAnswer: false,
                choices: createChoiceList(nextReplaceVocab)
            }
        } else {
            tempStackVocabSecond = {
                ...nextReplaceVocab,
                isHiddenUI : true,
                isAnswer: false,
                choices: createChoiceList(nextReplaceVocab)
            }
        }

        dispatch({
            type: CON.NEXT_VOCAB,
            payload: {
                vocabCurrentIndex : tempCurrentIndexVocab,
                vocabNextIndex: tempNextIndexVocab,
                vocabFirst : tempStackVocabFirst,
                vocabSecond : tempStackVocabSecond,
                hideNextButton : true,
                updateVocabFirst: vocabSecond.choices.length === 0 ? updateVocabFirst : !updateVocabFirst
            }
        });
    }
}

export function requestUpdateVocabChallengeVocab(shouldRepeat) {
    return (dispatch, getState) => {

        const { requestUpdateVocab, indexRequestUpdateVocab } = getState().challengeVocabReducer;
        const { token } = getState().user;

        let tempRequestUpdateVocab = []

        for (var i = 0; i < indexRequestUpdateVocab; i++) {
            tempRequestUpdateVocab.push(requestUpdateVocab[i])
        }

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
            body: JSON.stringify({
                should_repeat : shouldRepeat,
                vocab_result: tempRequestUpdateVocab
            })
        };

        const host = env.host + "/apiAsia/vocab/challenge/update";
        fetch(host, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    dispatch({
                        type: CON.UPDATE_CHALLENGE_VOCAB_ERROR,
                        payload: {
                            dialogError: {
                                show: true,
                                message: response.statusText
                            }
                        }
                    })
                }
            })
            .then((data) => {
                if (data.status_code === "00") {
                    dispatch({
                        type: CON.UPDATE_CHALLENGE_VOCAB_SUCCESS,
                        payload:{
                            ...data
                        }
                    });
                } else {
                    dispatch({
                        type: CON.UPDATE_CHALLENGE_VOCAB_ERROR,
                        payload: {
                            dialogError: {
                                show: true,
                                message: data.status_message
                            }
                        }
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: CON.UPDATE_CHALLENGE_VOCAB_ERROR,
                    payload: {
                        dialogError: {
                            show: true,
                            message: "ไม่สามารถดำเนินการได้ในขณะนี้"
                        }
                    }
                })
            })
    }
}

export function clearVocabState() {
    return {
        type: CON.CLEAR_VOCAB_STATE
    }
}

///////////////////////////////////////////////////////////////////////////////

export function fetchChallengeVocab(){
    return function(dispatch, getState) {
        const { token } = getState().user;
        
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
        };
        const host = env.host + "/apiAsia/vocab/challenge";
        fetch(host, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status_code === "00") {
                    dispatch({
                        type: CON.FETCH_VOCAB_CHALLENGE_SUCCESS,
                        payload: {
                            challengeVocab : data.vocabs,
                        }
                    });
                } else {
                    dispatch({
                        type: CON.FETCH_VOCAB_CHALLENGE_FAIL,
                        payload: {
                            error : data.status_message
                        }
                    });
                }
            
            })
            .catch((error) => {
                dispatch({
                    type: CON.FETCH_VOCAB_CHALLENGE_FAIL,
                    payload: {
                        error : "ไม่สามารถดำเนินการได้ในขณะนี้"
                    }
                });
            });


    }
}
