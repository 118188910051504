import * as CON from "./const-new";
import { env } from "../../../../constants/config";

export function loadingPage(){
    return {type: CON.LOADING_VOCAB_PAGE}
}

export function dispatchCloseErrorDialog(){
    return {type: CON.DIALOG_ERROR}
}

export function resetState(){
    return {type: CON.RESET_STATE}
}

export function fetchNewVocab(course){
    return function(dispatch, getState) {
        const { token } = getState().user;
        
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
        };
        const host = env.host + "/apiAsia/vocab/" + course.course_id + "/" + course.subCourse_id + "/new";
        fetch(host, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status_code === "00") {
                    let tempFirst = data.new_vocab[0];
                    let tempSecond = data.new_vocab[0];
                    if (data.new_vocab.length > 2){
                        tempSecond = data.new_vocab[1]
                    }
                    dispatch({
                        type: CON.FETCH_VOCAB_SUBCOURSE_SUCCESS,
                        payload: {
                            newVocab : data.new_vocab,
                            learnedVocab : data.learn_vocab,
                            totalVocab : data.total_vocab,
                            vocabFirstStack: tempFirst,
                            vocabSecondStack: tempSecond
                        }
                    });
                } else {
                    dispatch({
                        type: CON.FETCH_VOCAB_SUBCOURSE_FAIL,
                        payload: {
                            error : data.status_message
                        }
                    });
                }
            
            })
            .catch((error) => {
                dispatch({
                    type: CON.FETCH_VOCAB_SUBCOURSE_FAIL,
                    payload: {
                        error : "ไม่สามารถดำเนินการได้ในขณะนี้"
                    }
                });
            });


    }
}

export function updateVocabResultAPI(){
    return function(dispatch, getState) {
        const { token } = getState().user;
        const { resultVocab } = getState().newVocabReducer;
        
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                'Cache-Control': 'no-cache'
            },
            body : JSON.stringify({vocab_result: resultVocab})
        };
        const host = env.host + "/apiAsia/vocab/update";
        fetch(host, requestOptions)
            .then((response) => {
                if(response.ok) {
                    return response.json();
                }
                dispatch({
                    type: CON.DIALOG_ERROR,
                    payload: {
                        error : response.statusText
                    }
                });
                
            })
            .then((data) => {
                if (data.status_code === "00") {
                    dispatch({
                        type: CON.UPDATE_VOCAB_RESULT_DB_SUCCESS,
                        payload: {
                            result : true
                        }
                    });
                } else {
                    dispatch({
                        type: CON.UPDATE_VOCAB_RESULT_DB_FAILED,
                        payload: {
                            error : data.status_message
                        }
                    });
                }
            
            })
            .catch((error) => {
                dispatch({
                    type: CON.UPDATE_VOCAB_RESULT_DB_FAILED,
                    payload: {
                        error : "ไม่สามารถดำเนินการได้ในขณะนี้"
                    }
                });
            });

    }
}

export function dispatchVocabResult(vocab){
    return function(dispatch, getState) {
        dispatch({
            type: CON.STORE_VOCAB_RESULT,
            payload: {
                vocab : vocab,
            }
        });
    }
}

export function dispatchSelectedVocab(vocab){
    return function(dispatch, getState) {

        dispatch({
            type: CON.SELECTED_VOCABCARD,
            payload: {
                vocab : vocab
            }
        });
    }
}

export function nextVocab() {
    return (dispatch, getState) => {

        const {
            isUpdateFirstStack,
            vocabFirstStack,
            vocabSecondStack,
            nextIndexVocab,
            newVocab
        } = getState().newVocabReducer;

        let tempVocabFirstStack = vocabFirstStack;
        let tempVocabSecondStack = vocabSecondStack;

        let index = nextIndexVocab;
        let nextIndex = index + 1;

        const nextVocab = newVocab[nextIndex];

        if (isUpdateFirstStack) {
            tempVocabFirstStack = {
                ...nextVocab,
            }
        } else {
            tempVocabSecondStack = {
                ...nextVocab
            }
        }

        dispatch({
            type: CON.NEXT_NEW_VOCAB,
            payload: {
                vocabFirstStack: tempVocabFirstStack,
                vocabSecondStack: tempVocabSecondStack,
                currentIndexVocab: index,
                nextIndexVocab: nextIndex,
                isUpdateFirstStack: !isUpdateFirstStack
            }
        });

    }
}



