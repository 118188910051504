
import * as CON from "./const-new";
import {CLEAR_VOCAB_STATE} from "../../vocab-repeat/controller/const-repeat";

const initialState = {
    totalVocab : 0,
    learnedVocab : 0,
    currentIndexVocab: 0,
    nextIndexVocab: 1,
    isUpdateFirstStack: true,
    newVocab : [],
    selectedVocab : [],
    resultVocab : [],
    vocabFirstStack: {
        vocab_id : "",
        vocab : "",
        image : "",
        meaning : "",
        sentense : "",
        choice2 : "",
        choice3 : "",
        noun : false,
        verb : false,
        adjective : false,
        adverb : false,
        preposition : false,
        pronoun : false,
        conjunction : false,
    },
    vocabSecondStack: {
        vocab_id : "",
        vocab : "",
        image : "",
        meaning : "",
        sentense : "",
        choice2 : "",
        choice3 : "",
        noun : false,
        verb : false,
        adjective : false,
        adverb : false,
        preposition : false,
        pronoun : false,
        conjunction : false,
    },
    updateVocabResultAPI : false,
    shouldRefreshToken : false,
    loadingDialog : false,
    errorDialog : {
        show : false,
        message : ""
    },
    currentIndexVocabCard : 0
};

export default function newVocabReducer(state = initialState, { type, payload }) {
    switch (type) {
        case CON.RESET_STATE : {
            return {
                totalVocab : 0,
                learnedVocab : 0,
                currentIndexVocab: 0,
                nextIndexVocab: 1,
                isUpdateFirstStack: true,
                newVocab : [],
                selectedVocab : [],
                resultVocab : [],
                vocabFirstStack: {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                },
                vocabSecondStack: {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                },
                updateVocabResultAPI : false,
                shouldRefreshToken : false,
                loadingDialog : false,
                errorDialog : {
                    show : false,
                    message : ""
                },
                currentIndexVocabCard : 0,
            }
        }
        case CON.DIALOG_ERROR : {
            return {
                ...state,
                errorDialog : {
                    show : false,
                    message : ""
                },
            }
        }
        case CON.LOADING_VOCAB_PAGE : {
            return {
                ...state,
                loadingDialog : true,
            }
        }
////////////////////////////////////////////////////////////////////////////////////
        case CON.FETCH_VOCAB_SUBCOURSE_SUCCESS : {
            return {
                ...state,
                loadingDialog : false,
                newVocab : payload.newVocab,
                totalVocab : payload.totalVocab,
                learnedVocab : payload.learnedVocab,
                vocabFirstStack: payload.vocabFirstStack,
                vocabSecondStack: payload.vocabSecondStack
            }
        }
        case CON.FETCH_VOCAB_SUBCOURSE_FAIL : {
            return {
                ...state,
                loadingDialog : false,
                errorDialog : {
                    show : true,
                    message : payload.error
                },
            }
        }
////////////////////////////////////////////////////////////////////////////////////
        case CON.SELECTED_VOCABCARD : {
            const tempSelectedVocab = state.selectedVocab.map((data) => {
                return data
            });
            tempSelectedVocab.push(payload.vocab);
            return {
                ...state,
                currentIndexVocabCard : state.currentIndexVocabCard+1,
                learnedVocab : state.learnedVocab+1,
                selectedVocab : tempSelectedVocab
            }
        }
////////////////////////////////////////////////////////////////////////////////////
        case CON.STORE_VOCAB_RESULT : {
            const tempResultVocab = state.resultVocab.map((data) => {
                return data
            });
            tempResultVocab.push(payload.vocab);
            return {
                ...state,
                learnedVocab : state.learnedVocab+1,
                resultVocab : tempResultVocab,
                currentIndexVocabCard : state.currentIndexVocabCard+1
            }
        }
        case CON.UPDATE_VOCAB_RESULT_DB_SUCCESS : {
            return {
                ...state,
                updateVocabResultAPI : payload.result,
                loadingDialog: false,
                totalVocab : 0,
                learnedVocab : 0,
                currentIndexVocab: 0,
                nextIndexVocab: 1,
                isUpdateFirstStack: true,
                newVocab : [],
                selectedVocab : [],
                resultVocab : [],
                vocabFirstStack: {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                },
                vocabSecondStack: {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                },
                shouldRefreshToken : false,
                errorDialog : {
                    show : false,
                    message : ""
                },
                currentIndexVocabCard : 0
            }
        }
        case CON.UPDATE_VOCAB_RESULT_DB_FAILED : {
            return {
                ...state,
                errorDialog : {
                    show : true,
                    message : payload.error
                },
            }
        }
////////////////////////////////////////////////////////////////////////////////////
        case CON.NEXT_NEW_VOCAB: {
            return {
                ...state,
                currentIndexVocab: payload.currentIndexVocab,
                nextIndexVocab: payload.nextIndexVocab,
                vocabFirstStack: payload.vocabFirstStack,
                vocabSecondStack: payload.vocabSecondStack,
                isUpdateFirstStack: payload.isUpdateFirstStack
            }
        }
        case CLEAR_VOCAB_STATE: {
            return {
                ...state,
                totalVocab : 0,
                learnedVocab : 0,
                currentIndexVocab: 0,
                nextIndexVocab: 1,
                isUpdateFirstStack: true,
                newVocab : [],
                selectedVocab : [],
                resultVocab : [],
                vocabFirstStack: {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                },
                vocabSecondStack: {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                },
                updateVocabResultAPI : false,
                shouldRefreshToken : false,
                loadingDialog : false,
                errorDialog : {
                    show : false,
                    message : ""
                },
                currentIndexVocabCard : 0,
            }
        }
////////////////////////////////////////////////////////////////////////////////////
        default: {
            return state;
        }
    }

}