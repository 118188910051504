import { env } from "../../../../constants/config";
import {COURSE_ACTION} from "./course-reducer";

export function getCourseList(course_id) {
    return (dispatch, getState) => {
        const { token } = getState().user;
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            }
        };
        fetch(env.host + "/apiAsia/course/list", requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 403) {
                    dispatch({
                       type: COURSE_ACTION.GET_COURSE_FAILED_UNAUTHORIZED
                    });
                    return undefined;
                }
            })
            .then((body) => {
                if (body !== undefined) {
                    if (body.status_code === "00") {
                        
                        let courseSuccessful = {}
                        let tmpAllCourses = body.all_course
                        if( course_id !== ""){
                            for (let index = 0; index < tmpAllCourses.length; index++) {
                                const data = tmpAllCourses[index];
                                if (data.course_id === course_id){
                                    courseSuccessful = data
                                }
                            }
                        }
                        
                        dispatch({
                            type: COURSE_ACTION.GET_COURSE_LIST_SUCCESS,
                            payload: {
                                user_course: body.user_course,
                                all_course: body.all_course,
                                courseSuccessful: courseSuccessful,
                            }
                        });
                    } else {
                        dispatch({
                            type: COURSE_ACTION.GET_COURSE_FAILED,
                            payload: {
                                message: body.status_message
                            }
                        });
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: COURSE_ACTION.GET_COURSE_FAILED,
                    payload: {
                        message: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
                    }
                });
            });
    }
}

export function getCourseDetail(course_id) {
    return (dispatch, getState) => {
        const { token } = getState().user;
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            }
        };
        fetch(env.host + "/apiAsia/course/" + course_id, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 403) {
                    dispatch({
                        type: COURSE_ACTION.GET_COURSE_FAILED_UNAUTHORIZED
                    });
                    return undefined;
                }
            })
            .then((body) => {
                if (body !== undefined) {
                    if (body.status_code === "00") {
                        dispatch({
                            type: COURSE_ACTION.GET_COURSE_DETAIL_SUCCESS,
                            payload: {
                                course: {
                                    ...body
                                }
                            }
                        });
                    } else {
                        dispatch({
                            type: COURSE_ACTION.GET_COURSE_FAILED,
                            payload: {
                                message: body.status_message
                            }
                        });
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: COURSE_ACTION.GET_COURSE_FAILED,
                    payload: {
                        message: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
                    }
                });
            });
    }
}

export function registerCourse(course_id) {
    return (dispatch, getState) => {
        const { token } = getState().user;
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
            body: JSON.stringify({
                course_id: course_id
            })
        };
        fetch(env.host + "/apiAsia/course/register", requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 403) {
                    dispatch({
                        type: COURSE_ACTION.GET_COURSE_FAILED_UNAUTHORIZED
                    });
                    return undefined;
                }
            })
            .then((body) => {
                if (body !== undefined) {
                    if (body.status_code === "00") {
                        dispatch({
                            type: COURSE_ACTION.REGISTER_COURSE_SUCCESS
                        });
                    } else {
                        dispatch({
                            type: COURSE_ACTION.REGISTER_COURSE_FAILED,
                            payload: {
                                message: body.status_message
                            }
                        });
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: COURSE_ACTION.REGISTER_COURSE_FAILED,
                    payload: {
                        message: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
                    }
                });
            });
    }
}

export function loading() {
    return {
        type: COURSE_ACTION.LOADING
    }
}

export function closeDialog() {
    return {
        type: COURSE_ACTION.CLOSE_DIALOG
    }
}

export function finishRegisterCourse() {
    return {
        type: COURSE_ACTION.REGISTER_COURSE_FINISHED
    }
}