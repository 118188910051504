import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

class CancelButtons extends React.Component {

	constructor(props, context) {
		super(props, context);
		this.state = { matches: window.matchMedia("(max-width: 340px)").matches };
	}

	componentDidMount() {
        const handler = e => this.setState({matches: e.matches});
        window.matchMedia("(max-width: 340px)").addListener(handler);
    }
	

	render() {
		return (
			<div>
				<Button
					size="large"
					onClick={this.props.onClick}
					style={
						{
							color: "#4a4a4a",
							backgroundColor: "#ffffff",
							borderRadius: 23,
							textTransform: 'none',
							width: this.state.matches ? "100%" : 311,
							height: 50,
							paddingLeft: 60,
							paddingRight: 60,
							fontSize: 16,
							fontFamily: 'Prompt, sans-serif',
							fontWeight: "400",
							'&:hover': {
								backgroundColor: '#ffffff',
								borderColor: '#ffffff',
								boxShadow: 'none',
							},
						}
					}
				>
					จำแม่นอยู่แล้ว
			</Button>
			</div>
		);
	}
}

export default CancelButtons;
