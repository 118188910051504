import React from "react";
import {connect} from "react-redux";
import {LOG_IN} from "../../constants/routes";
import { refreshToken } from "../../state/action/users-action";

function withAuth(WrappedComponent) {

    function mapStateToProps({ session, user }) {
        return {
            session: session,
            user: user
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            onRefreshToken: () => dispatch(refreshToken())
        }
    }

    class InsideComponent extends React.Component {

        componentDidMount() {
            if (this.props.user.token === null || this.props.user.token === "") {
                this.props.history.push(LOG_IN);
            } else if (this.props.session.isTokenRefresh !== true) {
                this.props.onRefreshToken();
            }
        }

        render() {
            if ((this.props.user.token !== null && this.props.user.token !== "") && this.props.session.isTokenRefresh) {
                return (<WrappedComponent {...this.props}/>);
            } else {
                return (
                    <div/>
                );
            }
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(InsideComponent);
}

export default withAuth;