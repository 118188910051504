import React from "react";
import { Box, Grid, Button } from "@material-ui/core";
import { circleCounter, 
    counterButtonRepeatDisableStyle, 
    counterButtonRepeatEnableStyle,
    counterButtonNewStyle
 } from "./style";

class CounterButton extends React.Component {

    constructor(props, context) {
        super(props, context);
    }
    
    render() {

        if (this.props.btnType === "new") {
            return (
                <Button style={counterButtonNewStyle} onClick={this.props.onClickNew}>
                    <Grid direction="row" container>
                        <t>{this.props.name}</t>
                        <Box style={circleCounter} pl={1} pr={1} ml={1}>
                            {this.props.count}
                        </Box>
                    </Grid>
                </Button>
            );
        }
        if (this.props.count === 0) {
            return (
                <Button disabled style={counterButtonRepeatDisableStyle}>
                    <Grid direction="row" container>
                        <div>{this.props.name}</div>
                        <Box style={circleCounter} pl={1} pr={1} ml={1}>
                            {this.props.count}
                        </Box>
                    </Grid>
                </Button>
            );
        }
        return (
            <Button style={counterButtonRepeatEnableStyle} onClick={this.props.onClickRepeat}>
                <Grid direction="row" container>
                    <div>{this.props.name}</div>
                    <Box style={circleCounter} pl={1} pr={1} ml={1}>
                        {this.props.count}
                    </Box>
                </Grid>
            </Button>
        );
    }
}

export default CounterButton;