import React from 'react'
import MediaQuery from 'react-responsive'
import Toolbar from "../../common/toolbar"
import { connect } from 'react-redux'

class PrivacyScreen extends React.Component {
    render() {
        return (
            <div>
            <MediaQuery minDeviceWidth={1224}>
                <div class="term" style={{padding:"5%"}}>
                    <div class="content" style={{float: "center"}}>
                        <div class="header-term" style={{textAlign: "center",width:"100%"}}>
                            <Toolbar title="นโยบายความเป็นส่วนตัว" history={this.props.history}/>
                        </div>
                        <div class="term-container"
                        style={{padding:"3%",margin:"auto",borderRadius:20,width:372,height:"100%",fontSize:14,
                        boxShadow:"0 2px 20px 0 rgba(0, 64, 128, 0.1)", fontFamily: "Prompt, sans-serif", fontWeight: 300}}>
                        Aloha เคารพสิทธิความเป็นส่วนตัวของผู้ใช้ทุกคนที่เข้าใช้บริการเว็บไซต์ของเรา ดังนั้น เราจึงต้องการที่จะชี้แจงให้คุณทราบเกี่ยวกับการใช้งานข้อมูลส่วนบุคคลของคุณ 
                        เราขอแนะนำให้คุณอ่านนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ เพื่อที่จะได้เรียนรู้และเข้าใจหลักปฏิบัติที่เรายึดถือในการใช้ข้อมูลส่วนตัวของคุณ 
                        เมื่อคุณส่งข้อมูลส่วนตัวมาให้เรา เราจะถือว่าคุณยินยอมให้เราเปิดเผยข้อมูลเหล่านี้ได้ในกรณีที่จำเป็นและสมควร ดังที่ได้กล่าวไว้ในนโยบายฉบับนี้<br/>
                        1. การบริการผ่านทาง Social Login เช่น Facebook หรือ Google ทาง Aloha จะทำการจัดการเก็บข้อมูลโซเชียลพื้นฐาน 
                        เช่น ชื่อโปรไฟล์ อีเมล์ ตามที่คุณได้ให้คำอนุญาต และผู้ให้บริการโซเชียลนั้นๆ ยินยอมให้เข้าถึงได้ 
                        (ทาง Aloha ไม่เข้าถึงหรือรับรู้พาสเวิร์ดของบริการโซเชียลของคุณได้)<br/>
                        2. ทาง Aloha ได้จัดเก็บรวบรวมข้อมูลส่วนบุคคลของคุณไว้ เช่น ชื่อเล่น ที่อยู่อีเมล์ รหัสผ่าน 
                        ข้อมูลโซเชียลออนไลน์ ข้อมูลของการทบทวนคำศัพท์ และข้อมูลใดๆที่เกิดภายใต้ www.alohavocabulary.com เพื่อความสะดวกในการใช้งาน<br/>
                        3. www.alohavocabulary.com มีการเก็บข้อมูลทางอินเตอร์เน็ตพื้นฐาน เช่น หมายเลขไอพี เขตการให้บริการอินเตอร์เน็ต การเยี่ยมชมเว็บไซต์ 
                        เป็นต้น สำหรับจัดเก็บและวิเคราะห์การเข้าถึงและใช้งานส่วนต่างๆ ของ Aloha เพื่อการตลาด การปรับปรุงเนื้อหาให้ครอบคลุม และการพัฒนาให้ใช้งานได้ง่ายขึ้นต่อไป<br/>
                        4. www.alohavocabulary.com ทำการส่งข้อมูลภายใต้การเข้ารหัสผ่าน HTTPS ตลอดเวลา เพื่อความปลอดภัยของข้อมูลของคุณ<br/>
                        5. ทาง Aloha ขอรับรองว่าจะไม่นำข้อมูลส่วนบุคคลของคุณที่ได้เก็บรวบรวมไว้ ไปขายหรือเผยแพร่ให้กับบุคคลภายนอกโดยเด็ดขาด เว้นแต่จะได้รับอนุญาตจากคุณเท่านั้น<br/>
                        6. เพื่อประโยชน์ในการรักษาความเป็นส่วนตัวของคุณ ผู้ใช้สามารถร้องขอให้ลบหรือแก้ไขข้อมูลส่วนบุคคลได้โดยติดต่อเราที่ Line : @alohavocab เพื่อเป็นการปกป้องความปลอดภัยและความเป็นส่วนตัวของคุณ เราจะตรวจสอบตัวตนก่อนทำการลบหรือแก้ไขเสมอ โดยเราจะตอบกลับคำขอภายในกรอบเวลาที่เหมาะสม<br/>
                        <br/>
                        นโยบายเกี่ยวกับการใช้งานคุกกี้ (Cookies)<br/>
                        1. Aloha ใช้คุกกี้ชั่วคราวเพื่อคงสถานะการเข้าสู่ระบบของคุณตลอดการเยี่ยมชมบนเว็บไซต์ของเรา<br/>
                        2. Aloha อาจใช้คุกกี้ถาวรหรือเว็บแคชเพื่อจดจำข้อมูลเกี่ยวกับการเยี่ยมชมเนื้อหาบนเว็บไซต์ของผู้ใช้เอาไว้ เพื่ออำนวยความสะดวกในการใช้งานให้แก่ผู้ใช้<br/>
                        3. นอกจากนั้นทาง Aloha ใช้จดจำสิ่งที่คุณชอบ เช่น ภาษา และภูมิภาค เพื่อวิเคราะห์พฤติกรรมการเข้าชมใช้งาน 
                        และนำไปปรับปรุงเว็บไซต์และบริการ อีกทั้งปรับแต่งโฆษณาของเราให้เป็นไปตามความสนใจของผู้ใช้งาน<br/>
                        4. หากคุณไม่ต้องการที่จะยอมรับคุกกี้ คุณสามารถเลือกที่จะปฏิเสธได้โดยเปลี่ยนค่าที่ตั้งไว้ในเบราว์เซอร์ของคุณ โดยดูที่เมนู ช่วยเหลือ (Help) 
                        ในเบราว์เซอร์ โปรดทราบด้วยว่าเว็บเบราว์เซอร์ส่วนใหญ่จะยอมรับคุกกี้โดยอัตโนมัติ ดังนั้น หากคุณไม่ประสงค์ที่จะให้ใช้คุกกี้ คุณอาจจะต้องคอยสกัดกั้นหรือลบคุกกี้ทิ้งอยู่เสมอ<br/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={1224}>
                <div class="term" style={{padding:"5%"}}>
                    <div class="content" style={{float: "center"}}>
                        <div className="header-term" style={{textAlign: "center", width: "100%"}}>
                            <Toolbar title="นโยบายความเป็นส่วนตัว" history={this.props.history}/>
                        </div>
                        <div class="term-container"
                        style={{padding:"3%",borderRadius:20,height:"100%",fontSize:14,
                        boxShadow:"0 2px 20px 0 rgba(0, 64, 128, 0.1)", fontFamily: "Prompt, sans-serif", fontWeight: 300}}>
                        Aloha เคารพสิทธิความเป็นส่วนตัวของผู้ใช้ทุกคนที่เข้าใช้บริการเว็บไซต์ของเรา ดังนั้น เราจึงต้องการที่จะชี้แจงให้คุณทราบเกี่ยวกับการใช้งานข้อมูลส่วนบุคคลของคุณ 
                        เราขอแนะนำให้คุณอ่านนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ เพื่อที่จะได้เรียนรู้และเข้าใจหลักปฏิบัติที่เรายึดถือในการใช้ข้อมูลส่วนตัวของคุณ 
                        เมื่อคุณส่งข้อมูลส่วนตัวมาให้เรา เราจะถือว่าคุณยินยอมให้เราเปิดเผยข้อมูลเหล่านี้ได้ในกรณีที่จำเป็นและสมควร ดังที่ได้กล่าวไว้ในนโยบายฉบับนี้<br/>
                        1. การบริการผ่านทาง Social Login เช่น Facebook หรือ Google ทาง Aloha จะทำการจัดการเก็บข้อมูลโซเชียลพื้นฐาน 
                        เช่น ชื่อโปรไฟล์ อีเมล์ ตามที่คุณได้ให้คำอนุญาต และผู้ให้บริการโซเชียลนั้นๆ ยินยอมให้เข้าถึงได้ 
                        (ทาง Aloha ไม่เข้าถึงหรือรับรู้พาสเวิร์ดของบริการโซเชียลของคุณได้)<br/>
                        2. ทาง Aloha ได้จัดเก็บรวบรวมข้อมูลส่วนบุคคลของคุณไว้ เช่น ชื่อเล่น ที่อยู่อีเมล์ รหัสผ่าน 
                        ข้อมูลโซเชียลออนไลน์ ข้อมูลของการทบทวนคำศัพท์ และข้อมูลใดๆที่เกิดภายใต้ www.alohavocabulary.com เพื่อความสะดวกในการใช้งาน<br/>
                        3. www.alohavocabulary.com มีการเก็บข้อมูลทางอินเตอร์เน็ตพื้นฐาน เช่น หมายเลขไอพี เขตการให้บริการอินเตอร์เน็ต การเยี่ยมชมเว็บไซต์ 
                        เป็นต้น สำหรับจัดเก็บและวิเคราะห์การเข้าถึงและใช้งานส่วนต่างๆ ของ Aloha เพื่อการตลาด การปรับปรุงเนื้อหาให้ครอบคลุม และการพัฒนาให้ใช้งานได้ง่ายขึ้นต่อไป<br/>
                        4. www.alohavocabulary.com ทำการส่งข้อมูลภายใต้การเข้ารหัสผ่าน HTTPS ตลอดเวลา เพื่อความปลอดภัยของข้อมูลของคุณ<br/>
                        5. ทาง Aloha ขอรับรองว่าจะไม่นำข้อมูลส่วนบุคคลของคุณที่ได้เก็บรวบรวมไว้ ไปขายหรือเผยแพร่ให้กับบุคคลภายนอกโดยเด็ดขาด เว้นแต่จะได้รับอนุญาตจากคุณเท่านั้น<br/>
                        <br/>
                        นโยบายเกี่ยวกับการใช้งานคุกกี้ (Cookies)<br/>
                        1. Aloha ใช้คุกกี้ชั่วคราวเพื่อคงสถานะการเข้าสู่ระบบของคุณตลอดการเยี่ยมชมบนเว็บไซต์ของเรา<br/>
                        2. Aloha อาจใช้คุกกี้ถาวรหรือเว็บแคชเพื่อจดจำข้อมูลเกี่ยวกับการเยี่ยมชมเนื้อหาบนเว็บไซต์ของผู้ใช้เอาไว้ เพื่ออำนวยความสะดวกในการใช้งานให้แก่ผู้ใช้<br/>
                        3. นอกจากนั้นทาง Aloha ใช้จดจำสิ่งที่คุณชอบ เช่น ภาษา และภูมิภาค เพื่อวิเคราะห์พฤติกรรมการเข้าชมใช้งาน 
                        และนำไปปรับปรุงเว็บไซต์และบริการ อีกทั้งปรับแต่งโฆษณาของเราให้เป็นไปตามความสนใจของผู้ใช้งาน<br/>
                        4. หากคุณไม่ต้องการที่จะยอมรับคุกกี้ คุณสามารถเลือกที่จะปฏิเสธได้โดยเปลี่ยนค่าที่ตั้งไว้ในเบราว์เซอร์ของคุณ โดยดูที่เมนู ช่วยเหลือ (Help) 
                        ในเบราว์เซอร์ โปรดทราบด้วยว่าเว็บเบราว์เซอร์ส่วนใหญ่จะยอมรับคุกกี้โดยอัตโนมัติ ดังนั้น หากคุณไม่ประสงค์ที่จะให้ใช้คุกกี้ คุณอาจจะต้องคอยสกัดกั้นหรือลบคุกกี้ทิ้งอยู่เสมอ<br/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        username: state.user.username,
        email: state.user.email
    };
}

export default connect(mapStateToProps)(PrivacyScreen);