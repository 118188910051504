import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FilterToolbar from "../../common/toolbar-filter";
import { mainContainer, AlohaTabs, AlohaTab } from "./styles";
import EmptyVocabSection from "./vocab-empty";
import CustomVocabSection from "./vocab-custom";
import CourseVocabSection from "./vocab-course";
import { LANDING } from "../../../constants/routes";

function MyBookScreen(props) {

    const [page, setPage] = useState(0);
    const [customVocabFilter, setCustomVocabFilter] = useState(false);
    const [courseVocabFilter, setCourseVocabFilter] = useState(false);
    let detail;

    const onSelectFilter = () => {
        if (page === 0) {
            setCustomVocabFilter(true);
        } else {
            setCourseVocabFilter(true);
        }
    }

    const onCloseFilter = () => {
        setCustomVocabFilter(false);
        setCourseVocabFilter(false);
    }

    if (page === 0) {
        detail = (<CustomVocabSection history={props.history} filterOn={customVocabFilter} onCloseFilter={onCloseFilter}/>);
    } else if (page === 1) {
        detail = (<CourseVocabSection history={props.history} filterOn={courseVocabFilter} onCloseFilter={onCloseFilter}/>);
    } else {
        detail = (<EmptyVocabSection/>);
    }

    const handleTabChange = (event, newValue) => {
        setPage(newValue);
    }

    const gotoHome = () => {
        props.history.push(LANDING);
    }

    return(
        <div style={mainContainer}>
            <FilterToolbar 
                title={"สมุดคำศัพท์"}
                history={props.history}
                onBack={gotoHome}
                onFilter={onSelectFilter}/>
            <AlohaTabs value={page} onChange={handleTabChange}>
                <AlohaTab label="ศัพท์จดเพิ่ม"/>
                <AlohaTab label="ศัพท์จากคอร์ส"/>
            </AlohaTabs>
            {detail}
        </div>
    );

}

function mapDispatchToProps(dispatch) {
    return {
        
    };
}

function mapStateToProps() {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBookScreen);