import React from "react"
import MediaQuery from 'react-responsive'
import Toolbar from "../../common/toolbar"
import { connect } from 'react-redux'

class TermScreen extends React.Component {

    render() {
        return (
            <div>
            <MediaQuery minDeviceWidth={1224}>
                <div class="term" style={{padding:"5%"}}>
                    <div class="content" style={{float: "center"}}>
                        <div class="header-term" style={{textAlign: "center",width:"100%"}}>
                            <Toolbar title="เงื่อนไขการใช้งาน" history={this.props.history}/>
                        </div>
                        <div class="term-container" 
                        style={{padding:"3%",margin:"auto",borderRadius:20,width:372,height:"100%",fontSize:14,
                        boxShadow:"0 2px 20px 0 rgba(0, 64, 128, 0.1)", fontFamily: "Prompt, sans-serif", fontWeight: 300}}>
                        โปรดอ่านเงื่อนไขและข้อตกลง ผู้ที่สมัครเรียนกับ Aloha ทุกคนจะถือว่ายอบรับเงื่อนไขและข้อตกลงเหล่านี้<br/>
                        1. เว็บไซต์ www.alohavocabulary.com ให้บริการข้อมูลเกี่ยวกับการทบทวนคำศัพท์ภาษาอังกฤษ ทั้งในการสอบและการใช้ในชีวิตประจำวัน<br/>
                        2. ผู้ผลิต Aloha ขอแจ้งให้ผู้ใช้บริการทราบว่า เนื้อหา รูปภาพ ชื่อ ชื่อทางการค้า ส่วนประกอบใดๆ ที่ปรากฏบนเว็บไซต์นี้
                        เป็นงานอันได้รับความคุ้มครองตามกฎหมาย ห้ามมิให้บุคคลใดลอกเลียน ปลอมแปลง ทำซ้ำ คัดลอก ดัดแปลง เผยแพร่ต่อสาธารณชน
                        จำหน่าย หรือ กระทำการใดๆ ในลักษณะที่เป็นการแสวงหาประโยชน์ ทางการค้าหรือ ประโยชน์โดยมิชอบ ไม่ว่าโดยประการใดๆ
                        จากทรัพย์สินทางปัญญาดังกล่าวข้างต้น โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษร<br/>
                        3. ผู้ผลิต Aloha ขอสงวนสิทธิความรับผิดชอบต่อความเสียหายใดๆ อันอาจจะเกิดขึ้นจากข้อมูล เนื้อหา
                        หรือการใช้บริการของ www.alohavocabulary.com รวมถึงความความเสียหายใดๆ อันอาจจะเกิดขึ้นจากการไม่สามารถใช้บริการของ Aloha<br/>
                        4. หากมีการละเมิดข้อตกลงข้อใดข้อหนึ่ง ระบบจะทำการยกเลิกบัญชีของผู้ใช้นั้นทันทีและไม่มีการคืนเงิน<br/>
                        5. ทาง Aloha สามารถแก้ไข ปรับปรุง เงื่อนไขและข้อตกลงการใช้งานได้ตลอดเวลา ผู้ใช้บริการทุกท่านควรเข้ามาเยี่ยชมหน้านี้อย่างสม่ำเสมอ<br/>
                        เงื่อนไขและข้อตกลงแก้ไข ปรับปรุงล่าสุดเมื่อวันที่ 30 กันยายน 2563<br/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={1224}>
                <div class="term" style={{padding:"5%"}}>
                    <div class="content" style={{float: "center"}}>
                        <div className="header-term" style={{textAlign: "center", width: "100%"}}>
                            <Toolbar title="เงื่อนไขการใช้งาน" history={this.props.history}/>
                        </div>
                        <div class="term-container" 
                        style={{padding:"3%",borderRadius:20,height:"100%",fontSize:14,
                        boxShadow:"0 2px 20px 0 rgba(0, 64, 128, 0.1)", fontFamily: "Prompt, sans-serif", fontWeight: 300}}>
                        โปรดอ่านเงื่อนไขและข้อตกลง ผู้ที่สมัครเรียนกับ Aloha ทุกคนจะถือว่ายอบรับเงื่อนไขและข้อตกลงเหล่านี้<br/>
                        1. เว็บไซต์ www.alohavocabulary.com ให้บริการข้อมูลเกี่ยวกับการทบทวนคำศัพท์ภาษาอังกฤษ ทั้งในการสอบและการใช้ในชีวิตประจำวัน<br/>
                        2. ผู้ผลิต Aloha ขอแจ้งให้ผู้ใช้บริการทราบว่า เนื้อหา รูปภาพ ชื่อ ชื่อทางการค้า ส่วนประกอบใดๆ ที่ปรากฏบนเว็บไซต์นี้
                        เป็นงานอันได้รับความคุ้มครองตามกฎหมาย ห้ามมิให้บุคคลใดลอกเลียน ปลอมแปลง ทำซ้ำ คัดลอก ดัดแปลง เผยแพร่ต่อสาธารณชน
                        จำหน่าย หรือ กระทำการใดๆ ในลักษณะที่เป็นการแสวงหาประโยชน์ ทางการค้าหรือ ประโยชน์โดยมิชอบ ไม่ว่าโดยประการใดๆ
                        จากทรัพย์สินทางปัญญาดังกล่าวข้างต้น โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษร<br/>
                        3. ผู้ผลิต Aloha ขอสงวนสิทธิความรับผิดชอบต่อความเสียหายใดๆ อันอาจจะเกิดขึ้นจากข้อมูล เนื้อหา
                        หรือการใช้บริการของ www.alohavocabulary.com รวมถึงความความเสียหายใดๆ อันอาจจะเกิดขึ้นจากการไม่สามารถใช้บริการของ Aloha<br/>
                        4. หากมีการละเมิดข้อตกลงข้อใดข้อหนึ่ง ระบบจะทำการยกเลิกบัญชีของผู้ใช้นั้นทันทีและไม่มีการคืนเงิน<br/>
                        5. ทาง Aloha สามารถแก้ไข ปรับปรุง เงื่อนไขและข้อตกลงการใช้งานได้ตลอดเวลา ผู้ใช้บริการทุกท่านควรเข้ามาเยี่ยชมหน้านี้อย่างสม่ำเสมอ<br/>
                        เงื่อนไขและข้อตกลงแก้ไข ปรับปรุงล่าสุดเมื่อวันที่ 30 กันยายน 2563<br/>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        username: state.user.username,
        email: state.user.email
    };
}

export default connect(mapStateToProps)(TermScreen);