import React from "react";
import Parser from 'html-react-parser';

const detailContainer = {
    display: "block",
    flexGrow: 1
};

const coverContainer = {
    margin: 8,
    width: 79,
    height: 82
};

const titleStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 500,
    fontSize: 20,
    color: "#2d3142",
    marginLeft: 8
};

const descriptionStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 300,
    fontSize: 13,
    color: "#9b9b9b",
    marginLeft: 8,
    whiteSpace: "pre-wrap"
};

const freeStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 500,
    fontSize: 16,
    color: "#006d6e",
    marginLeft: 8,
    marginTop: 8
};

const specialPriceContainer = {
    display: "block",
    marginTop: 8
};

const specialPriceStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 600,
    fontSize: 16,
    color: "#d0021b",
    marginLeft: 8,
    display: "inline"
};

const oldPriceStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 400,
    fontSize: 12,
    color: "#9b9b9b",
    marginLeft: 8,
    display: "inline",
    textDecoration: "line-through"
};

const priceStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: "#3b3b3b",
    marginLeft: 8,
    marginTop: 8
};

class ItemCourse extends React.Component {

    constructor(props) {
        super(props);
        this.gotoCourseDetail = this.gotoCourseDetail.bind(this);
    }

    gotoCourseDetail() {
        this.props.history.push("/course/" + this.props.course.course_id);
    }

    render() {
        let priceDiv = null;
        if (this.props.course.price === "0") {
            priceDiv = <div style={freeStyle}>Free</div>
        } else if (this.props.course.special_price !== "0") {
            priceDiv =
                <div style={specialPriceContainer}>
                    <div style={specialPriceStyle}>{this.props.course.special_price} บาท</div>
                    <div style={oldPriceStyle}>{this.props.course.price}</div>
                </div>
        } else {
            priceDiv = priceDiv = <div style={priceStyle}>{this.props.course.price} บาท</div>
        }
        return (
            <button className="rounded-button" onClick={this.gotoCourseDetail}>
                <div style={detailContainer}>
                    <div style={titleStyle}>{this.props.course.course_title}</div>
                    <div style={descriptionStyle}>{Parser(this.props.course.summary)}</div>
                    {priceDiv}
                </div>
                <div style={coverContainer}>
                    <img src={this.props.course.thumbnail} alt="cover"/>
                </div>
            </button>
        );
    }

}

export default ItemCourse;