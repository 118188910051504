import React from "react";
import { filterItem, horizontalContainer } from "../styles";
import checkedImage from "../../../../assets/images/checkIcon@3x.png";
import Spacer from "../../../common/space";

const itemIcon = {
    width : 14,
    height : 13
}

export default function FilterItem(props) {
    
    return (
        <button style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%"
        }} className="item-vocab" onClick={() => props.onSelected(props.filter.id)}>
            {
                props.filter.selected === true ?
                <img src={checkedImage} style={itemIcon} /> : <div style={itemIcon}/>
            }
            <Spacer spaceWidth={8} spaceHeight={0}/>
            <div style={filterItem}>{props.filter.name}</div>
        </button>
    );

}