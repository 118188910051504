import React, { useState, useEffect } from "react";
import { 
    actionSectionContainer, 
    itemCustomVocabContainer, 
    itemTextMain, 
    itemTextSub } 
from "../styles";
import soundImg from "../../../../assets/images/sound-icon@3x.png";
import DialogVocabCardScreen from "../../../common/Dialogs/dialogVocabCard";

export default function ItemCourseVocab(props) {

    const [ vocabDialog, setVocabDialog ] = useState(false);

    const vocab = props.vocab;
    let vocabMeaning = vocab.meaning;

    const onOpenDialog = () => {
        setVocabDialog(true);
    }

    const onCloseDialog = () => {
        setVocabDialog(false);
    }

    const onPlaySound = (vocab) => {
        const audio = new Audio(vocab);
        audio.play();
    }
    
    return (
        <div style={itemCustomVocabContainer}>
            <button className="item-vocab" onClick={onOpenDialog}>
                <div style={itemTextMain}>{vocab.vocab}</div>
                <div style={itemTextSub}>{vocabMeaning}</div>
            </button>
            <div style={actionSectionContainer}>
                <button className="item-vocab" onClick={() => onPlaySound(vocab.voice)}>
                    <img src={soundImg} style={{width: 24, height: 20, paddingTop: 8, paddingBottom: 8}}/>
                </button>
            </div>
            <DialogVocabCardScreen 
                dialogOpen={vocabDialog}
                handleDialogClose={onCloseDialog}
                data={props.vocab}/>
        </div>
    );

}