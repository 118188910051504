import React from "react";
import { Box, IconButton, Divider } from "@material-ui/core";
import { boxShadowAlohaStyle } from "./style";
import ChallengeTitle from "./challenge-title";
import ListRank from "./list-rank";
import Zoom from '@material-ui/core/Zoom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { helpIconStyle, AlohaToolTip } from "./style";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const rankingInfoContainer = {
    display: "flex"
}

class SectionChallenge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.handleToolTipClose = this.handleToolTipClose.bind(this);
        this.handleToolTipOpen = this.handleToolTipOpen.bind(this);
    }

    handleToolTipClose() {
        this.setState({
            open: false
        });
    }

    handleToolTipOpen() {
        this.setState({
            open: true
        });
    }

    render() {
        return (
            <Box style={boxShadowAlohaStyle} mt={3} width={1} clone>
                <div style={{width:"100%", alignContent:"start"}}>
                    <Box p={2}>
                        <Box mb={1.7}>
                            <ChallengeTitle rank={179} history={this.props.history}/>
                        </Box>
                        <Divider/>
                        <Box mt={2} mb={1} display="flex" justifyContent="flex-start">
                            <t style={{ fontFamily: "Prompt, sans-serif", fontSize:"17px", fontWeight:"500" }}>อันดับของเดือนนี้</t>
                            <ClickAwayListener onClickAway={() => this.handleToolTipClose()}>
                                <AlohaToolTip
                                    title="ตารางแสดงเปอร์เซ็นของจำนวนคนที่อยู่ในอันดับนั้นๆ"
                                    placement="top-start"
                                    open={this.state.open}
                                    onClose={() => this.handleToolTipClose()}
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    disableFocusListener
                                    disableHoverListener
                                    TransitionComponent={Zoom}
                                    arrow>
                                    <IconButton aria-label="help" size="small" onClick={() => this.handleToolTipOpen()}>
                                        <HelpOutlineOutlinedIcon style={helpIconStyle} />
                                    </IconButton>
                                </AlohaToolTip>
                            </ClickAwayListener>
                        </Box>
                        <Box>
                            <ListRank ranking={this.props.ranking}/>
                        </Box>
                    </Box>
                </div>
            </Box>
        );
    }

}

export default SectionChallenge;