import * as CON from "./const-vcard";

const initialState = {
    googleTextToSpeechStatus : false,
    translate: {
        flag: false,
        displayN: {display:"none"},
        displayB: {display:"block"},
    },
    dialogError: {
        show: false,
        message: ""
    },
}

export default function vocabCardReducer(state = initialState, { type, payload }) {
    switch (type) {
        case CON.GOOGLE_TEXT_TO_SPEECH_SUCCESS: {
            return {
                ...state,
                googleTextToSpeechStatus : true,
                dialogError: {
                    show: false,
                    message: ""
                },
            }
        }
        case CON.GOOGLE_TEXT_TO_SPEECH_FAIL: {
            return {
                ...state,
                googleTextToSpeechStatus : false,
                dialogError: {
                    show: true,
                    message: payload.message
                },
            }
        }
        case CON.CLOSE_DIALOG: {
            return {
                ...state,
                dialogError: {
                    show: false,
                    message: "",
                }
            }
        }
        case CON.TRANSLATE_FLAG: {
            return  {
                ...state,
                translate : {
                    flag: !state.translate.flag,
                    displayN: {display:"none"},
                    displayB: {display:"block"},
                },
            }
        }
        case CON.TRANSLATE_FLAG_N: {
            return  {
                ...state,
                translate: {
                    flag: false,
                    displayN: {display:"none"},
                    displayB: {display:"block"},
                },
            }
        }
        default : {
            return state;
        }
    }
}