import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CancelDialogButtons from "../../../common/Dialogs/cancelDialogButton";
import SubmitDialogButtons from "../../../common/Dialogs/submitDialogButton";
import Spacer from "../../../common/space";
import { customVocabHeader, customVocabInfo, customVocabMeaning, customVocabRepetition, formTextData, formTextTitle, horizontalContainer, verticalCenterContainer, VerticalContainer } from "../styles";

const upTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getFormatDate = (date) => {
    const dateObj = new Date(date);
    let day = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
    let year = dateObj.getFullYear() + 543;
    return day + "/" + month + "/" + year;
}

export default function CustomVocabDialog(props) {

    const vocab = props.vocab;

    let noteSection = null;
    if (vocab.note !== undefined) {
        noteSection = (
            <div style={{...customVocabInfo, paddingBottom: 16}}>
                {vocab.note}
            </div>
        );
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={upTransition}
            PaperProps={{
                style: {
                    width : "100%",
                    borderRadius: 20,
                },
            }}
            open={props.open}
            onClose={props.onClose}>

            <div style={VerticalContainer}>
                <div style={verticalCenterContainer}>
                    <div style={customVocabHeader}>
                        {props.vocab.vocab}
                    </div>
                    <div style={customVocabMeaning}>
                        {props.vocab.meaning}
                    </div>
                    {noteSection}
                    <div style={customVocabInfo}>
                        จำได้ต่อเนื่อง <span style={customVocabRepetition}>{vocab.repetition}</span> ครั้ง
                    </div>
                    <div style={customVocabInfo}>
                        (ทบทวนครั้งถัดไปวันที่ {getFormatDate(vocab.next_available_at)})
                    </div>
                    <div style={{...horizontalContainer, paddingTop: 24}}>
                        <CancelDialogButtons textButton="ลบ" onClick={() => props.onDelete(vocab.id)}/>
                        <Spacer spaceWidth={20} spaceHeight={0}/>
                        <SubmitDialogButtons textButton="ปิด" onClick={props.onClose}/>
                    </div>
                </div>
            </div>

        </Dialog>
    );

}