import * as CON from "./const-vbook";
import { env } from "../../../../constants/config";

export function clearState() {
    return {
        type: CON.CLEAR_STATE
    }
}

export function loading() {
    return {
        type: CON.LOADING_VOCAB_BOOK
    }
}

export function toggleDrawer() {
    return {
        type: CON.TOGGLE_DRAWER
    }
}

///////////////////////////////////////////////////////////////////////////////

export function filterVocab() {
    return function (dispatch, getState) {
        let tempVocabBook = getState().vocabBookReducer.vocabBookForFilter.map(data => { return data });
        const tempType = getState().vocabBookReducer.checkedType
        const tempGroup = getState().vocabBookReducer.checkedGroup

        let listTmpType = []
        let listTmpGroup = []

        for (var i in tempType){
            if (tempType[i].check === true){
                listTmpType.push(tempType[i]) 
            }
        }

        for (var i in tempGroup){
            if (tempGroup[i].check === true){
                listTmpGroup.push(tempGroup[i]) 
            }
        }

        let tmpVocab = []
        for (var i in tempVocabBook){
            const vocab = tempVocabBook[i]
            if((listTmpType[0].checkType === "all") ||
                (vocab.noun === true && listTmpType[0].checkType === "noun") || 
                (vocab.verb === true && listTmpType[0].checkType === "verb") || 
                (vocab.adjective === true && listTmpType[0].checkType === "adjective") || 
                (vocab.adverb === true && listTmpType[0].checkType === "adverb") || 
                (vocab.preposition === true && listTmpType[0].checkType === "preposition") || 
                (vocab.pronoun === true && listTmpType[0].checkType === "pronoun") || 
                (vocab.conjunction === true && listTmpType[0].checkType === "conjunction")){
                
                const courseRef = vocab.course_ref
                for (var j in courseRef){
                    if ((courseRef[j] === listTmpGroup[0].id || listTmpGroup[0].id === "all") && (tmpVocab.includes(vocab) === false)){
                        tmpVocab.push(vocab)
                    }
                }
            }
        }

        dispatch({
            type: CON.FILTER_VOCAB,
            payload: {
                vocabBook: tmpVocab,
            }
        });

    }
}

///////////////////////////////////////////////////////////////////////////////

export function updateCheckedType(inputIndex, flagCheck) {
    return function (dispatch, getState) {

        let typeGroup = getState().vocabBookReducer.checkedType;
        if(flagCheck === "group"){
            typeGroup = getState().vocabBookReducer.checkedGroup;
        }

        let tempChecked = typeGroup.map(data => { return data })
        let itemCheck = tempChecked[inputIndex]
        if (itemCheck.check === true){
            return
        }
        itemCheck.check = !itemCheck.check
        tempChecked[inputIndex] = itemCheck

        tempChecked = tempChecked.map((data, index) => {
            if (index !== inputIndex) {
                return {
                    ...data,
                    check: false
                }
            }
            return data
        })

        if(flagCheck === "type"){
            dispatch({
                type: CON.CHECKED_TYPE,
                payload: {
                    checkedType: tempChecked,
                }
            });
        } else if(flagCheck === "group"){
            dispatch({
                type: CON.CHECKED_GROUP,
                payload: {
                    checkedGroup: tempChecked,
                }
            });
        }
    }
}

///////////////////////////////////////////////////////////////////////////////

export function fetchVocabBook() {
    return function (dispatch, getState) {
        const { token } = getState().user;
        const checkedGroup = getState().vocabBookReducer.checkedGroup

        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
        };
        const host = env.host + "/apiAsia/vocab/my";
        fetch(host, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status_code === "00") {
                    let tempCheckedGroup = checkedGroup.map(data => { return data })

                    for (var i in data.sub_courses){
                        tempCheckedGroup.push({check : false , type : data.sub_courses[i].title, id : data.sub_courses[i].id})
                    }
                    
                    dispatch({
                        type: CON.FETCH_VOCAB_BOOK_SUCCESS,
                        payload: {
                            vocabBook: data.new_vocab,
                            checkedGroup: tempCheckedGroup
                        }
                    });
                } else {
                    dispatch({
                        type: CON.FETCH_VOCAB_BOOK_FAIL,
                        payload: {
                            error: data.status_message
                        }
                    });
                }

            })
            .catch((error) => {
                dispatch({
                    type: CON.FETCH_VOCAB_BOOK_FAIL,
                    payload: {
                        error: "ไม่สามารถดำเนินการได้ในขณะนี้"
                    }
                });
            });
    }
}


