
export const LOADING_VOCAB_PAGE = "loading_vocab_page";
export const DIALOG_ERROR = "dialog_error";
export const FETCH_VOCAB_SUBCOURSE_SUCCESS = "fetch_vocab_subcourse_success";
export const FETCH_VOCAB_SUBCOURSE_FAIL = "fetch_vocab_subcourse_fail";
export const SELECTED_VOCABCARD = "selected_vocabcard";
export const STORE_VOCAB_RESULT = "store_vocab_result";
export const UPDATE_VOCAB_RESULT_DB_SUCCESS = "update_vocab_result_db_success";
export const UPDATE_VOCAB_RESULT_DB_FAILED = "update_vocab_result_db_failed";
export const NEXT_NEW_VOCAB = "next_new_vocab";
export const RESET_STATE = "reset_state";
export const CHECK_UPDATE_VOCAB_RESULT = "check_update_vocab_result";