import React, { useState, useCallback, useEffect, useRef } from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { cardSubTitle, cardTitle, formTextData, formTextTitle, horizontalContainer, line, verticalCenterContainer, VerticalContainer } from "../styles";
import { TextField } from "@material-ui/core";
import CancelDialogButtons from "../../../common/Dialogs/cancelDialogButton";
import SubmitDialogButtons from "../../../common/Dialogs/submitDialogButton";
import Spacer from "../../../common/space";
import { getTranslateVocab } from "../../../../client/api-client";
import DialogLoadingScreen from "../../../common/Dialogs/dialogLoading";
import { connect } from "react-redux";

const upTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddCustomVocabDialog(props) {

    const [ vocabName, setVocabName ] = useState("");
    const [ vocabMeaning, setVocabMeaning ] = useState("");
    const [ vocabNote, setVocabNote ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ currentEdit, setCurrentEdit ] = useState(null);
    const stateVocabRef = useRef();
    const stateMeaningRef = useRef();
    const stateCurrentEdit = useRef();
    stateVocabRef.current = vocabName;
    stateMeaningRef.current = vocabMeaning;
    stateCurrentEdit.current = currentEdit;

    const onAddNewVocab = (vocab, meaning, note) => {
        if (vocab !== "" && meaning !== "") {
            props.onAdd(vocab, meaning, note);
            setVocabName("");
            setVocabMeaning("");
            setVocabNote("");
        }
    }

    const onTranslate = () => {
        let originalWord = stateVocabRef.current;

        if (originalWord === undefined || originalWord === "") {
            return;
        }
        setLoading(true);
        getTranslateVocab(props.token, originalWord, "th",
        (response) => {
            setLoading(false);
            const wording = response.translate;
            setVocabMeaning(wording);
        }, 
        (response) => {
            setLoading(false);
        });
    }

    const onFindVocab = () => {
        let originalWord = stateMeaningRef.current;

        if (originalWord === undefined || originalWord === "") {
            return;
        }
        setLoading(true);
        getTranslateVocab(props.token, originalWord, "en",
        (response) => {
            setLoading(false);
            const wording = response.translate;
            setVocabName(wording);
        }, 
        (response) => {
            setLoading(false);
        });
    }

    const onCancel = () => {
        setVocabName("");
        setVocabMeaning("");
        props.onClose();
    }

    const returnFunction = useCallback((event) => {
        if(event.keyCode === 13) {
            if (stateCurrentEdit.current === 0 && stateVocabRef.current !== "") {
                onTranslate();
            } else if (stateCurrentEdit.current === 1 && stateMeaningRef.current !== "") {
                onFindVocab();
            }
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", returnFunction, false);

        return () => {
            document.removeEventListener("keydown", returnFunction, false);
        };
    }, []);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={upTransition}
            PaperProps={{
                style: {
                    width : "100%",
                    borderRadius: 20,
                },
                }}
            open={props.open}
            onClose={props.onClose}>

            <div style={VerticalContainer}>
                <div style={verticalCenterContainer} >
                    <div style={cardTitle}>
                        จดศัพท์เพิ่ม
                    </div>
                    <div style={cardSubTitle}>
                        (ศัพท์ที่จดเพิ่ม จะไม่ถูกนำมารวมกับศัพท์จากคอร์สหลัก)
                    </div>
                </div>
                <div style={VerticalContainer}>
                    <div style={formTextTitle}>คำศัพท์</div>
                    <div className="aloha-text-field">
                        <input 
                            className="aloha-text-field-input" 
                            placeholder="ระบุคำศัพท์"
                            value={vocabName}
                            onChange={(e) => { 
                                setVocabName(e.target.value);
                                setCurrentEdit(0);
                            }} />
                        <button className="aloha-text-field-button" onClick={onTranslate}>แปลศัพท์</button>
                    </div>
                </div>
                <div style={VerticalContainer}>
                    <div style={formTextTitle}>ความหมาย</div>
                    <div className="aloha-text-field">
                        <input 
                            className="aloha-text-field-input" 
                            placeholder="ระบุความหมายของคำศัพท์"
                            value={vocabMeaning}
                            onChange={(e) => { 
                                setVocabMeaning(e.target.value);
                                setCurrentEdit(1);
                            }}/>
                            <button className="aloha-text-field-button" onClick={onFindVocab}> ค้นหาศัพท์</button>
                    </div>
                </div>
                <div style={VerticalContainer}>
                    <div style={formTextTitle}>โน๊ต (หากมี)</div>
                    <div className="aloha-text-field">
                        <input 
                            className="aloha-text-field-input" 
                            placeholder="เช่น ตัวอย่างประโยคหรือรายละเอียดอื่นๆ"
                            value={vocabNote}
                            onChange={(e) => { setVocabNote(e.target.value) }}/>
                    </div>
                </div>
                <div style={horizontalContainer}>
                    <CancelDialogButtons textButton="ยกเลิก" onClick={() => onCancel()}/>
                    <Spacer spaceWidth={20} spaceHeight={0}/>
                    <SubmitDialogButtons textButton="เพิ่ม" onClick={() => onAddNewVocab(vocabName, vocabMeaning, vocabNote)}/>
                </div>
            </div>
            <DialogLoadingScreen dialogLoadingOpen={loading}/>
        </Dialog>
    );

}

function mapStateToProps({user}) {
    return {
        token: user.token
    }
}

function mapDispatchToProps(dispatch) {

}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomVocabDialog);