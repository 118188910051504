import normalModal from "../../../../assets/images/rank-normal.png";
import bronzeModal from "../../../../assets/images/rank-bronze.png";
import silverModal from "../../../../assets/images/rank-silver.png";
import goldModal from "../../../../assets/images/rank-gold.png";
import platinumModal from "../../../../assets/images/rank-platinum.png";
import diamondModal from "../../../../assets/images/rank-diamond.png";
import conquerorModal from "../../../../assets/images/rank-conqueror.png";
import defaultCover from "../../../../assets/images/aloha-level-1.png";

export const HOME_ACTION = {
    LOADING: "home_loading",
    GET_OVERVIEW: "action_get_overview",
    GET_OVERVIEW_ERROR: "action_get_overview_error",
    CHALLENGE_NOT_ENOUGH: "challenge_not_enough",
    CLOSE_ERROR_DIALOG: "home_close_error_dialog",
    TOOLTIP: "tooltip",
}

const initialState = {
    loading: false,
    toolTipToggle: {
        toolTip1: false, 
        toolTip2: false, 
        toolTip3: false
    },
    pending_vocab: 0,
    energy: 0,
    total_vocab: 0,
    master_vocab: 0,
    inprogress_vocab: 0,
    waiting_vocab: 0,
    total_vocab: 0,
    level_info: {
        level: 0,
        login: 0,
        next_require_login: 0,
        next_require_vocab: 0,
        next_require_rank: "-",
        login_pass: false,
        vocab_pass: false,
        rank_pass: false,
        cover_url: defaultCover,
    },
    challenge:{
        challenge_energy: 4,
        global_rank: {
            bronze: 0,
            silver: 0,
            gold: 0,
            platinum: 0,
            diamond: 0,
            conqueror: 0
        },
        user_rank: "-",
        user_score: 0,
        rank_score: 25
    },
    packRank : {
        colorRank: "#000000",
        textRank: "-",
        srcRank: normalModal,
        // size: {marginTop:"20%",width:268,height:268},
        sizeHome: {marginTop:5,width:40,height:40},
    },
    error: {
        show: false,
        message: ""
    },
    api_error: {

    }
}

export default function homeReducer(state = initialState, { type, payload }) {
    switch(type) {
        case HOME_ACTION.LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case HOME_ACTION.TOOLTIP: {
            const toolTipNo = payload.toolTipNo
            const mapToolTipToggle = {toolTip1:false,toolTip2:false,toolTip3:false}

            if(toolTipNo === 1){
                mapToolTipToggle.toolTip1 = !mapToolTipToggle.toolTip1
            }
            if(toolTipNo === 2){
                mapToolTipToggle.toolTip2 = !mapToolTipToggle.toolTip2
            }
            if(toolTipNo === 3){
                mapToolTipToggle.toolTip3 = !mapToolTipToggle.toolTip3
            }

            return {
                ...state,
                toolTipToggle: mapToolTipToggle
            }
        }
        case HOME_ACTION.GET_OVERVIEW: {
            const user_rank = payload.challenge.user_rank
            let packScore = {
                colorRank: "#000000",
                textRank: "-",
                srcRank: normalModal,
                // size: {marginTop:"20%",width:268,height:268},
                sizeHome: {marginTop:5,width:40,height:40},
            }
            if (user_rank === "Bronze"){
                packScore = {
                    colorRank: "#8e2e29",
                    textRank: user_rank,
                    srcRank: bronzeModal,
                    // size: {marginTop:"20%",width:215,height:282},
                    sizeHome: {marginTop:5,width:40,height:40},
                }
            }
            if (user_rank === "Silver"){
                packScore = {
                    colorRank: "#adb8cc",
                    textRank: user_rank,
                    srcRank: silverModal,
                    // size: {marginTop:"20%",width:215,height:268},
                    sizeHome: {marginTop:5,width:40,height:40},
                }
            }
            if (user_rank === "Gold"){
                packScore = {
                    colorRank: "#fcc555",
                    textRank: user_rank,
                    srcRank: goldModal,
                    // size: {marginTop:"20%",width:215,height:282},
                    sizeHome: {marginTop:5,width:40,height:40},
                }
            }
            if (user_rank === "Platinum"){
                packScore = {
                    colorRank: "#474f54",
                    textRank: user_rank,
                    srcRank: platinumModal,
                    // size: {marginTop:"20%",width:198,height:282},
                    sizeHome: {marginTop:5,width:40,height:40},
                }
            }
            if (user_rank === "Diamond"){
                packScore = {
                    colorRank: "#01d0fb",
                    textRank: user_rank,
                    srcRank: diamondModal,
                    // size: {marginTop:"20%",width:282,height:198},
                    sizeHome: {marginTop:5,width:40,height:40},
                }
            }
            if (user_rank === "Conqueror"){
                packScore = {
                    colorRank: "#ff193d",
                    textRank: user_rank,
                    srcRank: conquerorModal,
                    // size: {marginTop:"20%",width:282,height:282},
                    sizeHome: {marginTop:5,width:40,height:40},
                }
            }

            return {
                ...state,
                loading: false,
                pending_vocab: payload.pending_today,
                energy: payload.energy,
                total_vocab: payload.total,
                master_vocab: payload.master,
                inprogress_vocab: payload.inprogress,
                waiting_vocab: payload.waiting,
                challenge: payload.challenge,
                packRank: packScore,
                level_info: {
                    login: payload.level_info.login,
                    level: payload.level_info.level,
                    next_require_rank: payload.level_info.next_require_rank,
                    next_require_login: payload.level_info.next_require_login,
                    next_require_vocab: payload.level_info.next_require_vocab,
                    login_pass: payload.level_info.login_pass,
                    vocab_pass: payload.level_info.vocab_pass,
                    rank_pass: payload.level_info.rank_pass,
                    cover_url: payload.level_info.cover_url
                }
            }
        }
        case HOME_ACTION.GET_OVERVIEW_ERROR: {
            return {
                ...state,
                loading: false,
                api_error: {
                    show: true,
                    message: payload.message
                }
            }
        }
        case HOME_ACTION.CLOSE_ERROR_DIALOG: {
            return {
                ...state,
                loading: false,
                error: {
                    show: false,
                    message: ""
                },
                api_error: {
                    show: false,
                    message: ""
                }
            }
        }
        case HOME_ACTION.CHALLENGE_NOT_ENOUGH: {
            return {
                ...state,
                loading: false,
                error: {
                    show: true,
                    message: payload.message
                }
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}