import React from "react";
import {connect} from "react-redux";
import Toolbar from "../../common/progressBarChallenge";
import HeadCount from "./headCount";
import SlideChoice from "./slideChoice";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";
import * as ROUTES from "../../../constants/routes";
import {
    setupSelectedVocab,
    closeChallengeDialog,
    clearVocabState,
    loading,
    requestUpdateVocabChallengeVocab,
    fetchChallengeVocab,
} from "./controller/challenge-action";

const container = {
    display: "block",
    margin : "auto",
    textAlign : "center",
};

class ChallengeVocab extends React.Component {

    constructor(props) {
        super(props);

        this.props.onLoading();
        this.props.fetchChallengeVocab();
        
        this.onCloseErrorDialog = this.onCloseErrorDialog.bind(this);
        this.handlerGoToLanding = this.handlerGoToLanding.bind(this);
    }

    componentWillReceiveProps(nextProps,nextState){
        if (nextProps.challengeVocab.length !== this.props.challengeVocab.length){
            nextProps.setupSelectedVocab(nextProps.challengeVocab)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.updateChallengeVocabSuccess !== this.props.updateChallengeVocabSuccess &&
            this.props.updateChallengeVocabSuccess === true) {
            this.props.history.push(ROUTES.CHALLENGESUM);
        }
    }

    onCloseErrorDialog() {
        // window.location.reload();
        this.props.closeErrorDialog();
        this.props.history.push(ROUTES.LANDING)
    }

    handlerGoToLanding(){
        this.props.clearVocabState();
        this.props.history.push(ROUTES.LANDING)
    }

    render() {
        return (
            <div style={container}>
                <Toolbar 
                    displayClose="flex"
                    percentage={{
                        learn:this.props.correctRequestUpdateVocab,
                        total:this.props.rank_score}}
                    history={this.props.history}
                    progressBarColor={"#005b8e"}
                    handlerGoToLanding={this.handlerGoToLanding}
                    dialog={{
                        headerMessage:"ยอมแพ้แล้วหรอ ??",
                        bodyMessage:"",
                        cancelLabelButton:"ยอมแพ้",
                        submitLabelButton:"สู้ต่อ",
                    }}
                />
                <HeadCount count={{
                        learn:this.props.correctRequestUpdateVocab,
                        total:this.props.rank_score}}/>
                <SlideChoice 
                    history={this.props.history} 
                    challengeVocab={this.props.challengeVocab}
                />
                <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
                <ConfirmDialog
                    open={this.props.dialogError.show}
                    message={this.props.dialogError.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onCloseErrorDialog}/>
            </div>
        );
    }

}


function mapDispatchToProps(dispatch) {
    return {
        setupSelectedVocab: (challengeVocab) => dispatch(setupSelectedVocab(challengeVocab)),
        closeErrorDialog: () => dispatch(closeChallengeDialog()),
        clearVocabState: () => dispatch(clearVocabState()),
        onLoading : () => dispatch(loading()),
        onUpdate: () => dispatch(requestUpdateVocabChallengeVocab()),
        fetchChallengeVocab: () => dispatch(fetchChallengeVocab()),
     };
}


function mapStateToProps({ui, firebase, user, session, challengeVocabReducer, homeReducer}) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        challengeVocab: challengeVocabReducer.challengeVocab,
        correctVocab: challengeVocabReducer.correctVocab,
        loading: challengeVocabReducer.loading,
        dialogError: challengeVocabReducer.dialogError,
        updateChallengeVocabSuccess: challengeVocabReducer.updateChallengeVocabSuccess,
        user_score: homeReducer.challenge.user_score,
        rank_score: homeReducer.challenge.rank_score,
        correctRequestUpdateVocab: challengeVocabReducer.correctRequestUpdateVocab
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeVocab);