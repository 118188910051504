import { env } from "../../../../constants/config";
import { HOME_ACTION } from "./home-reducer";

export function setErrorOverview(input_message) {
    return (dispatch, getState) => {
        dispatch({
            type: HOME_ACTION.CHALLENGE_NOT_ENOUGH,
            payload: {
                message: input_message
            }
        })
    }
}

export function getOverview() {
    return (dispatch, getState) => {
        const { token } = getState().user;
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            }
        };
        fetch(env.host + "/apiAsia/user/overview", requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 403) {
                    dispatch({
                        type: HOME_ACTION.GET_OVERVIEW_ERROR,
                        payload: {
                            message: "เกิดข้อผิดพลาดในขณะทำรายการ"
                        }
                    })
                    return undefined;
                }
            })
            .then((body) => {
                if (body !== undefined) {
                    if (body.status_code === "00") {
                        dispatch({
                            type: HOME_ACTION.GET_OVERVIEW,
                            payload: {
                                ...body
                            }
                        });
                        return;
                    } else {
                        dispatch({
                            type: HOME_ACTION.GET_OVERVIEW_ERROR,
                            payload: {
                                message: body.status_message
                            }
                        });
                        return;
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: HOME_ACTION.GET_OVERVIEW_ERROR,
                    payload: {
                        message: "เกิดข้อผิดพลาดในขณะทำรายการ"
                    }
                })
            })
    }
}

export function homeLoading() {
    return {
        type: HOME_ACTION.LOADING
    }
}

export function closeHomeDialog() {
    return {
        type: HOME_ACTION.CLOSE_ERROR_DIALOG
    }
}

export function homeToolTip(toolTipNo) {
    return (dispatch, getState) => {
        dispatch({
            type: HOME_ACTION.TOOLTIP,
            payload: {
                toolTipNo: toolTipNo
            }
        })
    }
}