import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
	root: {
        color: "#9b9b9b",
        boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.25)`,
        border: `solid 1px #9b9b9b`,
		backgroundColor: "white",
		borderRadius: 23,
		textTransform: 'none',
		width: 99,
		height: 40,
		fontSize:16,
		fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
		'&:hover': {
			backgroundColor: 'white',
			boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.25)`,
			border: `solid 1px #9b9b9b`,
		},
	},
}))(Button);

function CancelDialogButtons({onClick,textButton}) {
	return (
		<div>
			<ColorButton size="large" onClick={onClick}>
                {textButton}
			</ColorButton>
		</div>
	);
}

export default CancelDialogButtons;
