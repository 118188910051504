import React from "react"
import {Link} from "react-router-dom"
import * as ROUTES from '../../constants/routes'

const footerFontStyle = {
    fontFamily: 'Prompt, sans-serif',
    fontWeight: "300"
};

class FooterScreen extends React.Component {
    render() {
        return (
            <div style={{fontSize:12,marginTop: 12, textAlign: "center", marginBottom: "20px"}}>
                <div><span style={{color: "#4a4a4a"}}>การดำเนินการต่อไปหมายความว่าคุณยอมรับ</span></div>
                <span style={footerFontStyle}>
                    <Link to={ROUTES.TERM} style={{color: "#006b6d"}}>เงื่อนไขการใช้งาน</Link>และ 
                    <Link to={ROUTES.PRIVACY} style={{color: "#006b6d"}}> นโยบายความเป็นส่วนตัว</Link> ของ Aloha
                    {/* <a href="#" style={{color: "#006b6d"}}> ติดต่อเรา</a> */}
                </span>
                <div><span style={{color: "#4a4a4a"}}>© 2020 Aloha - English Vocabulary</span></div>
            </div>
        );
    }
}

export default FooterScreen;