import React from "react";
import { Box, Button } from "@material-ui/core";

class MenuHidden extends React.Component {
    render() {
        return (
            <Button style={{fontFamily: "Prompt", fontWeight: "300", color: "#005b8e", borderRadius: "0px"}} onClick={this.props.onClick}>
                <Box pt={1} pb={1}>
                    <t>{this.props.name}</t>
                </Box>
            </Button>
        );
    }
}

export default MenuHidden;