import React, { useState, useEffect } from "react";
import soundImg from "../../../../../assets/images/sound-icon@3x.png";

const itemCustomVocabContainer = {
    display: "flex",
    flexDirection: "row",  
}

const actionSectionContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 4,
    paddingRight: 4
}

const itemTextMain = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 15,
    fontWeight: "500",
    color: "#000",
    paddingLeft: 0,
    paddingTop: 4
}

const itemTextSub = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 11,
    fontWeight: "300",
    color: "#717479",
    paddingLeft: 0,
    paddingBottom: 4
}

export default function ItemVocab(props) {

    const vocab = props.vocab;
    let vocabMeaning = vocab.meaning;

    const onPlaySound = () => {
        if (vocab.voice !== undefined) {
            const audio = new Audio(vocab.voice);
            audio.play();
        } else {
            const sound = "https://audio.oxforddictionaries.com/en/mp3/" + vocab.vocab.toLowerCase() + "_gb_1.mp3";
            const audio = new Audio(sound);
            audio.play();
        }
    }
    
    return (
        <div style={itemCustomVocabContainer}>
            <button className="item-vocab">
                <div style={itemTextMain}>{vocab.vocab}</div>
                <div style={itemTextSub}>{vocabMeaning}</div>
            </button>
            <div style={actionSectionContainer}>
                <button className="item-vocab" onClick={() => onPlaySound()}>
                    <img src={soundImg} style={{width: 24, height: 20, paddingTop: 8, paddingBottom: 8}}/>
                </button>
            </div>
        </div>
    );

}