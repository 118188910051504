import React from "react";
import { connect } from "react-redux";
import { Paper, Box, Grid } from "@material-ui/core";
import { boxShadowAlohaStyle } from "./style";
import CounterDetail from "./counter";
import CounterButton from "./counter-button";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import * as ROUTES from "../../../constants/routes";

const contentsStyle = {
    fontFamily: 'Prompt, sans-serif',
    fontWeight: "400",
    color: "#9b9b9b",
    fontSize: 12,
}

class SectionDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {

    }

    gotoCourseList() {
        this.props.history.push(ROUTES.COURSES);
    }

    gotoVocabRepeat() {
        if (this.props.pendingVocab !== 0) {
            this.props.history.push({
                pathname: ROUTES.REPEAT_VOCAB,
                state: {
                    selectedVocab: [],
                    resultVocab: [],
                    home: true,
                }
            });
        }
    }

    render() {
        let contents;
        contents = <div style={contentsStyle}>วันนี้ได้เรียนรู้ศัพท์ใหม่ๆหรือยังเอ่ย~</div>;
        if (this.props.energy < 30) {
            contents = <div style={contentsStyle}>พรุ่งนี้อย่าลืมเข้ามาทบทวนนะ ^^~</div>;
        }
        

        return (
            <Box width={1} style={boxShadowAlohaStyle} clone>
                <Paper >
                    <Box pl={2} pr={2} width={1} pt={3} pb={0} clone>
                        <Grid container >
                            <CounterDetail
                                title="จำแม่น" toolTipNo={1} toolTipShow={this.props.toolTipToggle.toolTip1}
                                total={this.props.masterVocab} tip="จำนวนคำศัพท์ที่ทบทวนแล้วและตอบถูกต่อเนื่อง 3 ครั้ง" />
                            <CounterDetail
                                title="ทวนอยู่" toolTipNo={2} toolTipShow={this.props.toolTipToggle.toolTip2}
                                total={this.props.inprogressVocab} tip="จำนวนคำศัพท์ที่เพิ่งเรียนและทบทวนไม่ถึง 3 ครั้ง" />
                            <CounterDetail
                                title="รอเรียนรู้" toolTipNo={3} toolTipShow={this.props.toolTipToggle.toolTip3}
                                total={this.props.waitingVocab} tip="จำนวนคำศัพท์ที่รอการเรียนรู้จากคอร์สที่มี" />
                        </Grid>
                    </Box>
                    <Box width={1} pb={1} pt={1} >
                        <Grid container direction="row" justify="space-evenly" alignItems="center">
                            <CounterButton name="ทบทวนศัพท์" count={this.props.pendingVocab} onClickRepeat={() => this.gotoVocabRepeat()} style={{marginTop: 8}} />
                            <CounterButton name="เรียนรู้ศัพท์ใหม่" count={this.props.energy} btnType="new" onClickNew={() => this.gotoCourseList()} style={{marginTop: 8}} />
                        </Grid>
                    </Box>
                    <Box width={1} pb={1} pt={1} >
                        <Grid container justify="center">
                            {contents}
                        </Grid>
                    </Box>

                </Paper>
            </Box>
        );
    }

}


function mapDispatchToProps(dispatch) {
    return {
    };
}


function mapStateToProps({ homeReducer }) {
    return {
        masterVocab: homeReducer.master_vocab,
        inprogressVocab: homeReducer.inprogress_vocab,
        waitingVocab: homeReducer.waiting_vocab,
        pendingVocab: homeReducer.pending_vocab,
        energy: homeReducer.energy,
        totalVocab: homeReducer.total_vocab,
        toolTipToggle: homeReducer.toolTipToggle,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetail);