import {
    ACTION_GET_USER_FROM_DB,
    ACTION_LOGOUT_FAILED,
    ACTION_LOGOUT_SUCCESS, ACTION_UPDATE_USER_DB_SUCCESS,
    UPDATE_USER_STATE, VALIDATE_EMAIL_STATE, VALIDATE_FORMAT_EMAIL_STATE
} from "../../constants/action-type";
import {USER_ACTION} from "./users";

const sessionState = {
    isSignIn: null,
    tempUser: null,
    isValidEmail: null,
    errValidDes : "",
    errFormat : null,
    errFormatDes : "",
    token: "",
    isTokenRefresh: false
};

export default function sessionReducer(state = sessionState, { type, payload }) {
    switch (type) {
        case ACTION_LOGOUT_SUCCESS: {
            return {
                ...state,
                isSignIn: false
            }
        }
        case ACTION_LOGOUT_FAILED: {
            return state
        }
        case USER_ACTION.REFRESH_TOKEN: {
            return {
                ...state,
                isTokenRefresh: true
            }
        }
        case USER_ACTION.UPDATE_TOKEN: {
            return {
                ...state,
                isTokenRefresh: true
            }
        }
        default: {
            return state
        }
    }
}