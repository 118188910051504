import React from "react";
import { Container, Grid } from "@material-ui/core";
import { mainContainerStyle } from "./style";
import Header from "./header";
import personReader from "../../../assets/images/cover.svg";
import SectionDetail from "./section-detail";
import FooterScreen from '../../common/footer';
import SectionVocabs from './section-vocabs';
import SectionChallenge from './section-challenge';
import { connect } from "react-redux";
import { getOverview, homeLoading, closeHomeDialog } from "./controller/home-controller";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";
import SectionFooter from "./section-footer";
import { LOGOUT } from "../../../constants/routes";

class HomeScreen extends React.Component {

    constructor(props) {
        super(props);
        this.onGetOverviewError = this.onGetOverviewError.bind(this);
    }

    componentDidMount() {
        this.props.onLoading();
        this.props.onLoadOverview();
    }

    onGetOverviewError() {
        this.props.onClose();
        this.props.history.push(LOGOUT);
    }

    render() {
        const ranking = [
            {
                "user": "Conqueror",
                "score": this.props.conqueror+" %",
                "rank": "1"
            },
            {
                "user": "Diamond",
                "score": this.props.diamond+" %",
                "rank": "2"
            },
            {
                "user": "Platinum",
                "score": this.props.platinum+" %",
                "rank": "3"
            },
            {
                "user": "Gold",
                "score": this.props.gold+" %",
                "rank": "4"
            },
            {
                "user": "Silver",
                "score": this.props.silver+" %",
                "rank": "5"
            },
            {
                "user": "Bronze",
                "score": this.props.bronze+" %",
                "rank": "6"
            },
        ];

        return (
            <Container style={mainContainerStyle} >
                <Grid container direction="column" justify="center" alignItems="center">
                    <Header history={this.props.history}/>
                    <img src={this.props.cover_url} alt="cover" style={{width:285, height:195}} />
                    <SectionDetail history={this.props.history}/>
                    <SectionVocabs history={this.props.history}/>
                    <SectionChallenge ranking={ranking} history={this.props.history}/>
                    <SectionFooter/>
                </Grid>
                <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
                <ConfirmDialog
                    open={this.props.api_error?.show}
                    message={this.props.api_error?.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onGetOverviewError}/>
                <ConfirmDialog
                    open={this.props.error?.show}
                    message={this.props.error?.message}
                    positiveText={"ตกลง"}
                    onPositive={() => this.props.onClose()}/>
            </Container>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        onLoadOverview: () => dispatch(getOverview()),
        onLoading: () => dispatch(homeLoading()),
        onClose: () => dispatch(closeHomeDialog())
    }
}

function mapStateToProps({ homeReducer }) {
    return {
        loading: homeReducer.loading,
        error: homeReducer.error,
        conqueror: homeReducer.challenge.global_rank.conqueror,
        diamond: homeReducer.challenge.global_rank.diamond,
        platinum: homeReducer.challenge.global_rank.platinum,
        gold: homeReducer.challenge.global_rank.gold,
        silver: homeReducer.challenge.global_rank.silver,
        bronze: homeReducer.challenge.global_rank.bronze,
        api_error: homeReducer.api_error,
        cover_url: homeReducer.level_info.cover_url
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);