import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../common/toolbar";
import { PURCHASE_PAGE } from "../../../../constants/topics";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import HomeButtons from './buttons/homeButton';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../../../constants/routes';
import * as css from './pageCss';
import { COURSES } from "../../../../constants/routes";
import { getCourseList, loading } from "../controller/course-controller";

const container = {
    fontFamily: "Prompt, sans-serif",
    textSize: 14,
    paddingLeft: "5%",
    paddingRight: "5%",
    position: "relative",
    height: "100%",
};


class CoursesPurchaseSuccessScreen extends React.Component {

    constructor(props) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {
        let { course_id } = this.props.match.params;
        this.props.onLoading();
        this.props.onFetchCourseList(course_id);
    }


    handleOnClick(event) {
        this.props.history.push(ROUTES.LANDING);
    }

    onBack() {
        this.props.history.push(COURSES);
    }

    render() {
        let gridDetail = <div></div>
        if (Object.keys(this.props.courseSuccessful).length !== 0 && this.props.courseSuccessful.constructor !== Object) {
            gridDetail = <div style={css.containerSummary}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        {this.props.courseSuccessful.course_title}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        {this.props.courseSuccessful.price} บาท
                </Grid>
                    <Grid item xs={8}>
                        ส่วนลด
                </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        {parseInt(this.props.courseSuccessful.price.split(",").join("")) - parseInt(this.props.courseSuccessful.special_price)} บาท
                </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                    <Grid item xs={8} style={{ fontWeight: 500, fontSize: 16 }}>
                        ยอดรวม
                </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center', fontWeight: 500, fontSize: 16, color: "#d0021b" }}>
                        {this.props.courseSuccessful.special_price} บาท
                </Grid>
                </Grid>
            </div>
        }
        return (
            <div style={container}>
                <Toolbar title={PURCHASE_PAGE} history={this.props.history} onBack={this.onBack} />
                <div style={css.container}>
                    {gridDetail}
                    <div style={css.detail}>
                        <div style={css.topics}>สถานะการชำระเงิน</div>
                        <div style={css.topicWaiting}>รออนุมัติ</div>
                        <div style={css.contentWaiting}>(สามารถตรวจสอบผลการได้ภายใน 3 ชั่วโมง)</div>
                    </div>
                    <div style={css.ButtonBottomPosition}>
                        <HomeButtons onClick={this.handleOnClick} />
                    </div>

                    <div style={{ paddingBottom: 60 }} />

                </div>
            </div>
        );
    }

}
function mapDispatchToProps(dispatch) {
    return {
        onFetchCourseList: (course_id) => dispatch(getCourseList(course_id)),
        onLoading: () => dispatch(loading()),
    };
}

function mapStateToProps({ courseReducer }) {
    return {
        courseSuccessful: courseReducer.courseSuccessful,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoursesPurchaseSuccessScreen));