import React from 'react';
import SoundButton from './soundButton';
import * as CSS from './vocabCardCss';
import { connect } from "react-redux";
import "./soundButton.css";
import defaultVocabImg from "../../../assets/images/default-vocab-img.jpg";
import ConfirmDialog from '../Dialogs/confirm-dialog';
import {
    googleTextToSpeech,
    onCloseDialog,
    translateFlag,
} from './controller/vcard-action';

class VocabCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            typeVocab: "",
            checkSound: [],
        };

        this.handleSoundOnClick = this.handleSoundOnClick.bind(this)
        this.updateType = this.updateType.bind(this)
        this.onClickTranSlate = this.onClickTranSlate.bind(this)
    }

    updateType(input) {
        if (input !== undefined) {
            let typeV = ""
            if (input.noun) {
                typeV = typeV + ", Noun"
            }
            if (input.verb) {
                typeV = typeV + ", Verb"
            }
            if (input.adjective) {
                typeV = typeV + ", Adjective"
            }
            if (input.adverb) {
                typeV = typeV + ", Adverb"
            }
            if (input.preposition) {
                typeV = typeV + ", Preposition"
            }
            if (input.pronoun) {
                typeV = typeV + ", Pronoun"
            }
            if (input.conjunction) {
                typeV = typeV + ", Conjunction"
            }

            this.setState({
                typeVocab: typeV.substring(2)
            });
        }
    }

    componentDidMount() {
        this.updateType(this.props.data);
    }

    componentWillReceiveProps(nextProps) {
        this.updateType(nextProps.data);
    }

    handleSoundOnClick() {
        let audio = new Audio(this.props.data.voice)
        audio.play()
    }

    onCloseErrorDialog() {
        window.location.reload();
        this.props.onCloseDialog();
    }

    onClickTranSlate() {
        this.props.translateFlag();
    }

    render() {
        
        let vocabImage = this.props.data.image;
        if (vocabImage === "" || vocabImage === undefined) {
            vocabImage = defaultVocabImg;
        }

        return (
            <div style={this.props.isHidden ? CSS.conrainerCard :
                {
                    width: "100%",
                    borderRadius: 20,
                    boxShadow: `0 5px 10px 0 rgba(0, 64, 128, 0.15)`,
                    backgroundColor: `#ffffff`,
                }
            }>
                <div style={CSS.containerHeader}>
                    <div style={CSS.soundButton}>
                        <SoundButton soundOnClick={() => this.handleSoundOnClick()} />
                    </div>
                    <div style={CSS.containerVocabImg}>
                        <div style={CSS.vocabImgStyle}>
                            <img
                                src={vocabImage}
                                alt="vocab" style={CSS.vocabImgStyle2} />
                        </div>
                    </div>
                    <div style={{
                        alignSelf: "flex-start",
                        width: 50,
                        height: 50,
                        marginTop: 15,
                        visibility: this.props.isHidden ? "hidden" : "visible",
                    }}>
                        <button style={CSS.itemButton} onClick={this.props.onClose}>
                            <i className="material-icons-round md-48">clear</i>
                        </button>
                    </div>
                </div>


                <div style={CSS.textDetial}>
                    <div style={CSS.textVocab}>
                        {this.props.data.vocab}
                    </div>
                    <div style={CSS.textVocabType}>
                        {this.state.typeVocab}
                    </div>
                    <div style={CSS.textVocabMeaning}>
                        {this.props.data.meaning}
                    </div>
                    <div style={this.props.translate.flag ? this.props.translate.displayN : this.props.translate.displayB}>
                        <div style={CSS.textVocabExample}>
                            {this.props.data.sentense}
                        </div>
                        <div style={CSS.textVocabSolve}>
                            <p style={CSS.textVocabSolveTagP} onClick={this.onClickTranSlate}>ดูคำแปล</p>
                        </div>
                    </div>
                    <div style={this.props.translate.flag ? this.props.translate.displayB : this.props.translate.displayN}>
                        <div style={CSS.textVocabExample}>
                            {this.props.data.translate}
                        </div>
                        <div style={CSS.textVocabSolve}>
                            <p style={CSS.textVocabSolveTagP} onClick={this.onClickTranSlate}>ดูต้นฉบับ (แปลโดย Google)</p>
                        </div>
                    </div>

                </div>
                <ConfirmDialog
                    open={this.props.dialogError.show}
                    message={this.props.dialogError.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onCloseErrorDialog} />
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return {
        googleTextToSpeech: (vocab) => dispatch(googleTextToSpeech(vocab)),
        onCloseDialog: () => dispatch(onCloseDialog()),
        translateFlag: () => dispatch(translateFlag()),
    }
}

function mapStateToProps({ session, vocabCardReducer }) {
    return {
        session: session,
        dialogError: vocabCardReducer.dialogError,
        translate: vocabCardReducer.translate,
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(VocabCard);
