import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
	root: {
		color: "white",
		backgroundColor: "#005b8e",
		borderRadius: 23,
		textTransform: 'none',
		width: 311,
		height: 50,
		paddingLeft: 60,
		paddingRight: 60,
		fontSize:16,
		fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
		'&:hover': {
			backgroundColor: '#005b8e',
			borderColor: '#005b8e',
			boxShadow: 'none',
		},
	},
}))(Button);

function HomeButtons({onClick}) {
	return (
		<div>
			<ColorButton size="large" onClick={onClick}>
                กลับหน้าหลัก
			</ColorButton>
		</div>
	);
}

export default HomeButtons;
