import React, { useState, useEffect } from "react";
import { 
    actionSectionContainer, 
    itemCustomVocabContainer, 
    itemTextMain, 
    itemTextSub, 
    rememberText,
    forgetText } 
from "../styles";
import { CUSTOM_VOCAB_STATE } from "../vocab-custom";
import CustomVocabDialog from "./dialog-custom-vocab";
import soundImg from "../../../../assets/images/sound-icon@3x.png";

function ActionButton(props) {
    let customButtonColor = {
        fontFamily: "Prompt, sans-serif",
        fontSize: 12,
        fontWeight: "400",
        color: props.buttonColor,
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 23,
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
        border: "solid 1px " + props.buttonColor,
        backgroundColor: "#ffffff",
        outline: "none",
        cursor: "pointer",
        marginLeft: 4,
        marginRight: 4,
        WebkitTapHighlightColor: "transparent",
        "& > active": {
            backgroundColor: "rgba(0, 0, 0, 0.11)"
        }
    };

    if (props.width !== undefined) {
        customButtonColor = {
            ...customButtonColor,
            width: props.width
        }
    }

    return (
        <div>
            <button onClick={props.onClick} style={customButtonColor}>
                { props.name }
            </button>
        </div>
    );
}

export default function ItemCustomVocab(props) {

    const [ vocabDialog, setVocabDialog ] = useState(false);

    const vocab = props.vocab;
    let actionSection;
    let vocabMeaning = vocab.meaning;

    const onOpenDialog = () => {
        if (props.enable_open === true) {
            setVocabDialog(true);
        }
    }

    const onCloseDialog = () => {
        setVocabDialog(false);
    }

    const onDeleteVocab = (id) => {
        setVocabDialog(false);
        props.onDelete(id);
    }

    const onPlaySound = (vocab) => {
        const sound = "https://audio.oxforddictionaries.com/en/mp3/" + vocab.toLowerCase() + "_gb_1.mp3";
        const audio = new Audio(sound);
        audio.play();
    }

    if (vocab.status === CUSTOM_VOCAB_STATE.WAITING) {
        actionSection = (
            <div style={actionSectionContainer}>
                <button className="item-vocab" onClick={() => onPlaySound(vocab.vocab)}>
                    <img src={soundImg} style={{width: 24, height: 20, paddingTop: 8, paddingBottom: 8}}/>
                </button>
            </div>
        );

    } else if (vocab.status === CUSTOM_VOCAB_STATE.PENDING) {
        vocabMeaning = "กดเพื่อดูคำแปล";
        actionSection = (
            <div style={actionSectionContainer}>
                <ActionButton name={"ดูคำแปล"} onClick={() => props.onTranslate(vocab.id)} buttonColor={"#006b6d"} width={75} />
            </div>
        );

    } else if (vocab.status === CUSTOM_VOCAB_STATE.READY) {
        actionSection = (
            <div style={actionSectionContainer}>
                <ActionButton name={"ลืม"} onClick={() => props.onForget(vocab.id)} buttonColor={"#e1436a"} />
                <ActionButton name={"จำได้อยู่"} onClick={() => props.onRemember(vocab.id)} buttonColor={"#1fbe7a"} width={75} />
            </div>
        );

    } else if (vocab.status === CUSTOM_VOCAB_STATE.REMEMBER) {
        actionSection = (
            <div style={actionSectionContainer}>
                <div style={rememberText}>
                    ยอดเยี่ยม
                </div>
            </div>
        );

    } else if (vocab.status === CUSTOM_VOCAB_STATE.FORGET) {
        actionSection = (
            <div style={actionSectionContainer}>
                <div style={forgetText}>
                    พรุ่งนี้ทวนใหม่
                </div>
            </div>
        );
    }
    
    return (
        <div style={itemCustomVocabContainer}>
            <button className="item-vocab" onClick={onOpenDialog}>
                <div style={itemTextMain}>{vocab.vocab}</div>
                <div style={itemTextSub}>{vocabMeaning}</div>
            </button>
            {actionSection}
            <CustomVocabDialog open={vocabDialog} onClose={onCloseDialog} vocab={vocab} onDelete={onDeleteVocab}/>
        </div>
    );

}