import React from "react";
import ItemVocab from "./item-vocab";

const cardContainer = {
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 20,
    boxShadow: "0 10px 20px 0 rgba(0, 64, 128, 0.15)",
    backgroundColor: "#FFFFFF",
    marginTop: 16,
    marginBottom: 16
}

export const cardTitle = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 16,
    fontWeight: "500",
    color: "#000000",
}

export default function CardVocab(props) {
    if (props.list.length === 0) {
        return <div/>
    }

    let title;
    if (props.incorrect === true) {
        title = (
            <div>
                ❌ ยังไม่แม่น พรุ่งนี้ทวนใหม่นะ
            </div>
        );
    } else {
        title = (
            <div>
                ✅ เก่งมาก
            </div>
        );
    }

    return (
        <div style={cardContainer}>
            <div style={cardTitle}>
                {title}
                {   props.list.map((vocab) => {
                    return <ItemVocab key={vocab.id} vocab={vocab}/>
                })}
            </div>
        </div>
    );

}