import {PENDING_LOGIN} from "../../../../constants/storage";

export const LOGIN_ACTION = {
    LOADING: "login_loading",
    LOGIN_REQUEST: "login_request",
    LOGIN_SUCCESS: "login_success",
    LOGIN_FAILED: "login_failed",
    GET_USER_SUCCESS: "get_user_success",
    GET_USER_FAILED: "get_user_failed",
    GET_USER_UNREGISTERED: "get_user_unregistered",
    CLEAR_STATE: "clear_state"
};

const initialState = {
    loading: window.sessionStorage.getItem(PENDING_LOGIN) === "true",
    logged_in: false,
    register_user_detail: null,
    logged_in_user_detail: null,
    error_dialog: {
        showing: false,
        message: ""
    },
};

export default function loginReducer(state = initialState, { type, payload }) {
    switch (type) {
        case LOGIN_ACTION.LOADING: {
            return handlerLoading(state, true);
        }
        case LOGIN_ACTION.LOGIN_REQUEST: {
            return handlerLoading(state, true);
        }
        case LOGIN_ACTION.LOGIN_SUCCESS: {
            return {...state};
        }
        case LOGIN_ACTION.LOGIN_FAILED: {
            return handlerLoading(state, false)
        }
        case LOGIN_ACTION.GET_USER_FAILED: {
            return handleLoginFailed(state, payload);
        }
        case LOGIN_ACTION.GET_USER_SUCCESS: {
            return handleLoginSuccess(state, payload);
        }
        case LOGIN_ACTION.GET_USER_UNREGISTERED: {
            return handleUnregisteredUser(state, payload);
        }
        case LOGIN_ACTION.CLEAR_STATE: {
            return clearState();
        }
        default: {
            return state;
        }
    }
}

export function loginLoading() {
    return {
        type: LOGIN_ACTION.LOADING,
        payload: {
            loading: true
        }
    }
}

function handlerLoading(state, isLoading) {
    return {
        ...state,
        loading: isLoading
    }
}

function handleLoginSuccess(state, payload) {
    return {
        ...state,
        loading: false,
        logged_in: true,
        logged_in_user_detail: {
            username: payload.username,
            email: payload.email
        }
    }
}

function handleUnregisteredUser(state, payload) {
    return {
        ...state,
        loading: false,
        register_user_detail: {
            username: payload.username,
            email: payload.email,
            register_token: payload.register_token
        }
    }
}

function handleLoginFailed(state, payload) {
    return {
        ...state,
        loading: false
    }
}

function clearState() {
    return {
        loading: window.sessionStorage.getItem(PENDING_LOGIN) === "true",
        logged_in: false,
        register_user_detail: null,
        logged_in_user_detail: null,
        error_dialog: {
            showing: false,
            message: ""
        },
    }
}