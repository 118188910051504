export const mainContainer = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "felx-start"
}

export const headerText = {
    display: "flex",
    justifyContent: "center",
    paddingTop: "105px",
    paddingBottom: "36px",
    fontSize: "32px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.19,
    color: "#2d3142",
    textAlign: "center"
}

export const menuContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    paddingLeft: "24px",
    paddingRight: "24px",
    alignItems: "center",
}

export const menuNotifyText = {
    fontSize: "14px",
    color: "#006b6d",
    flexGrow: 1
}

export const menuDescriptionText = {
    fontSize: "12px",
    color: "#888992",
    paddingLeft: "24px",
    paddingRight: "24px",
}

export const radioGroupContainer = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "12px",
    paddingBottom: "12px",
    height: "100px"
}

export const radioTextContainer = {
    display: "flex",
    flexDirection: "row",
    fontSize: "14px",
    fontFamily: "Prompt, sans-serif",
    fontWeight: 400,
    color: "#2d3142",
    alignItems: "center"
}

export const buttonContainer = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center"
}