export const filterRepetition = [
    {
        id: 0,
        name: "การทบทวน",
        selected: true
    },
    {
        id: 1,
        name: "ตัวอักษร",
        selected: false
    }
];

export const filterType = [
    {
        id: 0,
        name: "ทั้งหมด",
        selected: true
    },
    {
        id: 1,
        name: "คำนาม (noun)",
        selected: false
    },
    {
        id: 2,
        name: "คำกริยา (verb)",
        selected: false
    },
    {
        id: 3,
        name: "คำวิเศษณ์ขยายนาม (adjective)",
        selected: false
    },
    {
        id: 4,
        name: "คำวิเศษณ์ขยายกริยา (adverb)",
        selected: false
    },
    {
        id: 5,
        name: "คำบุพบท (preposition)",
        selected: false
    },
    {
        id: 6,
        name: "คำสรรพนาม (pronoun)",
        selected: false
    },
    {
        id: 7,
        name: "คำเชื่อมประโยค (conjunction)",
        selected: false
    }
];