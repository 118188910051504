import React from 'react';
import Button from '@material-ui/core/Button';

const CopyButtons = ({ onClick }) => {
	return (
		<div>
			<Button
				size="large"
				onClick={onClick}
				style={{
					color: "white",
					backgroundColor: "#006b6d",
					borderRadius: 23,
					textTransform: 'none',
					height: 22,
					fontSize: 14,
					marginTop: 10,
					padding: 0,
					paddingLeft: 10,
					paddingRight: 10,
					fontFamily: 'Prompt, sans-serif',
					fontWeight: "400",
					'&:hover': {
						backgroundColor: '#006b6d',
						borderColor: '#006b6d',
						boxShadow: 'none',
					},
				}}
			>
				คัดลอกเลขบัญชี
			</Button>
		</div>
	)
}

export default CopyButtons;
