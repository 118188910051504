import React from "react";
import {connect} from "react-redux";
import Toolbar from "./toolbar";
import DetailVocabBook from "./detailbook";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import bgHeader from '../../../assets/images/bg-header-card3.png';
import {
    clearState,
    loading,
    fetchVocabBook
} from "./controller/vbook-action";
import * as ROUTES from '../../../constants/routes';

const bgHeaderStyle = {
    transform: `translate(-16, 0)`,
    position:"fixed",
    zIndex:-1,
    width:"100%",
    height: 312,
    margin : 0,
    padding : 0,
    top : 0,
    left : 0,
};

class VocabBookScreen extends React.Component {

    constructor(props) {
        super(props);

        this.handleBackToLanding = this.handleBackToLanding.bind(this)
    }

    componentDidMount() {
        this.props.clearState();
        this.props.onLoading();
        this.props.fetchVocabBook();
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    handleBackToLanding (){
        this.props.history.push(ROUTES.LANDING);
    }

    render() {
        return (
            <div style={{height:"auto"}}>
                <div style={bgHeaderStyle}>
                    <img alt={""} src={bgHeader} style={{width:"inherit",height:"inherit"}}/>
                </div>
                <Toolbar onBack={this.handleBackToLanding} title={"สมุดคำศัพท์"} history={this.props.history}/>
                <DetailVocabBook 
                    repeatBook={this.props.repeatBook} 
                    doneBook={this.props.doneBook} 
                    vocabBook={this.props.vocabBook} 
                />
                <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        // fetchCourseList: (token) => dispatch(fetchCourseList(token)),
        clearState: () => dispatch(clearState()),
        onLoading: () => dispatch(loading()),
        fetchVocabBook: () => dispatch(fetchVocabBook()),
    };
}

function mapStateToProps({vocabBookReducer}) {
    return {
        vocabBook : vocabBookReducer.vocabBook,
        repeatBook : vocabBookReducer.repeatBook,
        doneBook : vocabBookReducer.doneBook,
        loading : vocabBookReducer.loading,

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VocabBookScreen);