import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import config from '../../constants/config';

app.initializeApp(config);

const firebaseState = {
    app: app,
    db: app.firestore(),
    storage: app.storage()
};

export function firebaseReducer(state=firebaseState, action) {
    switch(action.type) {
        default: {
            return state;
        }
    }
}