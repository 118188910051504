import React,  { useState, useEffect } from "react";
import { connect } from "react-redux";
import CourseCardGroup from "./component/list-card-course";
import { mainContainer } from "./styles";
import { filterRepetition, filterType } from "./const";
import { getAllVocab } from "../../../client/api-client";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import CourseVocabFilter from "./component/filter-course-vocab";
import EmptyVocabSection from "./vocab-empty";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";

function isSameCategory(categoryId, vocab) {
    if (categoryId === 0) {
        return true;
    }

    if (categoryId === 1 && vocab.noun === true) {
        return true;
    } else if (categoryId === 2 && vocab.verb === true) {
        return  true;
    } else if (categoryId === 3 && vocab.adjective === true) {
        return true;
    } else if (categoryId === 4 && vocab.adverb === true) {
        return true;
    } else if (categoryId === 5 && vocab.preposition === true) {
        return true;
    } else if (categoryId === 6 && vocab.pronoun === true) {
        return true;
    } else if (categoryId === 7 && vocab.conjunction === true) {
        return true;
    }
    return false;
}

function isSameCourse(courseId, courseRef) {
    if (courseId === "00") {
        return true;
    }
    for (let ref of courseRef) {
        if (ref === courseId) {
            return true;
        }
    }
    return false;
}

function CourseVocabSection(props) {

    const [ originalPendingVocab, setOriginalPendingVocab ] = useState([]);
    const [ originalMasterVocab, setOriginalMasterVocab ] = useState([]); 
    const [ pendingVocab, setPendingVocab ] = useState([]);
    const [ masterVocab, setMasterVocab ] = useState([]);
    const [ optionCourse, setOptionCourse ] = useState([]);
    const [ optionRepetition, setOptionRepetition ] = useState([...filterRepetition]);
    const [ optionType, setOptionType ] = useState([...filterType]);
    const [ initial, setInitial ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ errorDialog, setErrorDialog ] = useState({
        open: false,
        statusCode: "",
        statusMessage: ""
    });

    const fetchAllVocab = () => {
        setLoading(true);
        getAllVocab(props.token, 
        (response) => {
            const pending = [];
            const master = [];
            for (const vocab of response.new_vocab) {
                if (vocab.repetition >= 4) {
                    master.push({...vocab});
                } else {
                    pending.push({...vocab});
                }
            }
            setOriginalPendingVocab([...pending]);
            setOriginalMasterVocab([...master]);
            setPendingVocab([...pending]);
            setMasterVocab([...master]);

            const updateOptionCourse = [{ id: "00", name: "ทั้งหมด", selected: true }, ...response.sub_courses.map((filter, index) => {
                return {
                    ...filter,
                    name: filter.title,
                    selected: false
                }
            })];
            setOptionCourse([...updateOptionCourse]);
            setLoading(false);
        }, 
        (response) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: response.statusCode,
                statusMessage: response.statusMessage
            });
        })
    }

     const onFilter = (orderId, typeId, courseId) => {
        setLoading(true);
        const filterOrder = optionRepetition[orderId];
        const filterType = optionType[typeId];
        let filterCourse;
        for (let c of optionCourse) {
            if (c.id === courseId) {
                filterCourse = c;
                break;
            }
        }

        let updatePending = [];
        let updateMaster = [];
        if (filterOrder.id === 0) {
            updatePending = originalPendingVocab.sort((first, second) => first.repetition - second.repetition);
            updateMaster = originalMasterVocab.sort((first, second) => first.repetition - second.repetition);
        } else {
            updatePending = originalPendingVocab.sort((first, second) => (first.vocab.toLowerCase() > second.vocab.toLowerCase()) ? 1 : -1);
            updateMaster = originalMasterVocab.sort((first, second) => (first.vocab.toLowerCase() > second.vocab.toLowerCase()) ? 1 : -1);
        }

        updatePending = updatePending.filter((vocab) => {
            if (isSameCategory(filterType.id, vocab) && isSameCourse(filterCourse.id, vocab.course_ref) === true) {
                return {...vocab};
            }
        });

        updateMaster = updateMaster.filter((vocab) => {
            if (isSameCategory(filterType.id, vocab) && isSameCourse(filterCourse.id, vocab.course_ref) === true) {
                return {...vocab};
            }
        });

        setPendingVocab([...updatePending]);
        setMasterVocab([...updateMaster]);
        setOptionType((optionType) => 
            optionType.map((op) => {
                if (filterType.id === op.id) {
                    return {
                        ...op,
                        selected: true
                    }
                }
                return {
                    ...op, 
                    selected: false
                }
            })
        );
        setOptionRepetition((optionRepetition) => 
            optionRepetition.map((op) => {
                if (filterOrder.id === op.id) {
                    return {
                        ...op,
                        selected: true
                    }
                }
                return {
                    ...op, 
                    selected: false
                }
            })
        );
        setOptionCourse((optionCourse) => 
        optionCourse.map((op) => {
                if (filterCourse.id === op.id) {
                    return {
                        ...op,
                        selected: true
                    }
                }
                return {
                    ...op, 
                    selected: false
                }
            })
        );
        props.onCloseFilter();

        setLoading(false);
     }

    useEffect(() => {
        if (initial === true) {
            fetchAllVocab();
            setInitial(false);
        }
    }, [])

    const onCloseErrorDialog = () => {
        setLoading(false);
        setErrorDialog({
            open: false,
            statusCode: "",
            statusMessage: ""
        });
    }

    let page;


    if (pendingVocab.length === 0 && masterVocab.length === 0) {
        page = <div>
                    <EmptyVocabSection/>
                </div>
    } else {
        page = (
            <div>
                <CourseCardGroup title={"ทวนอยู่"} list={pendingVocab}/>
                <CourseCardGroup title={"จำแม่น"} list={masterVocab}/>
            </div>
        );
    }

    return (
        <div style={mainContainer}>
            {page}
            <CourseVocabFilter open={props.filterOn} onClose={props.onCloseFilter} order={optionRepetition} types={optionType} course={optionCourse} onSubmitFilter={onFilter} />
            <ConfirmDialog
                open={errorDialog.open}
                onClose={onCloseErrorDialog}
                message={errorDialog.statusMessage}
                positiveText={"ตกลง"}
                onPositive={onCloseErrorDialog}/>
            <DialogLoadingScreen dialogLoadingOpen={loading}/>
        </div>
    );
}

function mapStateToProps({user}) {
    return {
        token: user.token
    }
}

function mapDispatchToProps(dispatch) {

}

export default connect(mapStateToProps, mapDispatchToProps)(CourseVocabSection);