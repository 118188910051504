import React from 'react';
import soundImg from '../../../assets/images/sound-icon@3x.png';

const conrainerButton = {
    display : "flex",
    alignItems : "center",
    justifyContent : "center",
    width : 25,
    height : 25,
}

const imageStyle = {
    width : "100%",
}

class SoundButton extends React.Component {

    render(){
        return (
            <div style={conrainerButton} onClick={this.props.soundOnClick}>
                <img src={soundImg} alt="Sound" style={imageStyle}/>
            </div>
        )
    }

}



export default SoundButton;