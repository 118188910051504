import {
    FETCH_VOCAB_SUBCOURSE,
    SELECTED_VOCAB_SUBCOURSE,
    SELECTED_VOCABCARD,
    STORE_VOCAB_RESULT,
    ANSWER_VOCAB,
    RESET_STATE_VOCAB,
    UPDATE_VOCAB_RESULT_DB_SUCCESS,
    UPDATE_VOCAB_RESULT_DB_FAILED,
} from "../../constants/action-type";
import { env } from "../../constants/config";

export function fetchVocabSubCourse(token, ID) {

    return function(dispatch) {
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
        };
        fetch(env.host + "/apiAsia/vocab/" + ID.courseID
                 + "/" + ID.subCourseID + "/new", requestOptions)
            .then(response => response.json())
            .then((data) => {

                if (data.status_code === "00") {
                    dispatch({
                        type: FETCH_VOCAB_SUBCOURSE,
                        payload: data
                    });
                } 
            
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_VOCAB_SUBCOURSE,
                    payload: error
                });
            });
    }

}

export function selectedVocabSubCourse(selectedVocabs, counts) {
    return function(dispatch) {
        dispatch({
            type: SELECTED_VOCAB_SUBCOURSE,
            payload: {
                selectedVocab : selectedVocabs,
                count : counts
            }
        });
    }
}

export function selectedVocabCard(arrCards) {
    return function(dispatch) {
        dispatch({
            type: SELECTED_VOCABCARD,
            payload: {
                arrCard : arrCards,
            }
        });
    }
}


export function putVocabResultAPI(token, result) {

    return function(dispatch) {
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
            body: JSON.stringify({
                vocab_result :result
            })
        };
        fetch(env.host + "/apiAsia/vocab/update", requestOptions)
        .then(response => response.json())
        .then((data) => {
            if (data.status_code === "00") {
                dispatch({
                    type: UPDATE_VOCAB_RESULT_DB_SUCCESS,
                    payload: {

                    }
                })
            } 
        })
        .catch((error) => {
            dispatch({
                type: UPDATE_VOCAB_RESULT_DB_FAILED,
                payload: {
                    error: error
                }
            })
        });
    }

}


export function resetStateVocab() {
    return function(dispatch) {
        dispatch({
            type: RESET_STATE_VOCAB,
        });
    }
}

export function putVocabResult(result,vocab_id) {
    /*
        -result(true,false) separate correct and incorrect answers.
        -vocab is the vocab that shows the choice page
    */
    return function(dispatch) {
        dispatch({
            type: STORE_VOCAB_RESULT,
            payload: {
                result : result,
                vocab_id : vocab_id,
            }
        });
    }
}

export function putAnswerVocab(result,vocab,lastVocab) {
    /*
        -result(true,false) take control isHidden and Separate correct and incorrect answers into the store.
        -vocab is the vocab that shows the choice page
        -lastVocab Used when reaching the final vocab and needs to reset the ArrCard.
    */
    return function(dispatch) {
        dispatch({
            type: ANSWER_VOCAB,
            payload: {
                vocab : vocab,
                result : result,
                lastVocab : lastVocab,
            }
        });
    }
}


