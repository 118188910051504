import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
	root: {
        color: "#ffffff",
        boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.25)`,
        border: `solid 1px #006b6d`,
		backgroundColor: "#006b6d",
		borderRadius: 23,
		textTransform: 'none',
		width: 145,
		height: 40,
		fontSize:16,
		fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
		'&:hover': {
			backgroundColor: '#006b6d',
			boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.25)`,
			border: `solid 1px #006b6d`,
		},
	},
}))(Button);

function SubmitButton({onClick,textButton}) {
	return (
		<div>
			<ColorButton size="large" onClick={onClick}>
                {textButton}
			</ColorButton>
		</div>
	);
}

export default SubmitButton;
