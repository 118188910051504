import React from "react";
import {connect} from "react-redux";
import Toolbar from "../../common/progressBarChallenge";
import HeadCount from "./headCount";
import bgHeader from '../../../assets/images/bg-header-challenge@3x.png';
import HomeButtons from "./homeButton";
import * as ROUTES from "../../../constants/routes";
import {
    loading,
    clearVocabState,
} from "./controller/challenge-action";

const container = {
    display: "block",
    margin : "auto",
    textAlign : "center",
};

const bgHeaderStyle = {
    transform: `translate(-16, 0)`,
    position:"fixed",
    zIndex:-1,
    width:"100%",
    height: 312,
    margin : 0,
    padding : 0,
    top : 0,
    left : 0,
};

const fontModal = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 45,
    fontWeight: 500,
    marginTop: "7%",
}

const fontContent = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 16,
    fontWeight: 400,
    color: "#202027",
}

const containterFooter = {
    width: "100%",
    textAlign : "center",
    justifyContent : "center",
    boxSizing: "border-box",
    position: "fixed",
    bottom: 30,
    left: 0,
    right: 0,
    paddingRight : 15,
    paddingLeft : 15,
}
                
class SummaryChallenge extends React.Component {

    constructor(props) {
        super(props);

        // this.props.onLoading();
        this.updateVocabResultAPI = this.updateVocabResultAPI.bind(this)
        this.goToLandingPage = this.goToLandingPage.bind(this)
    }

    updateVocabResultAPI(){
        this.props.clearVocabState();
        this.props.history.push(ROUTES.LANDING)
    }

    goToLandingPage(){
        this.props.clearVocabState();
        this.props.history.push(ROUTES.LANDING)
    }


    render() {
        return (
            <div style={container}>
                <div style={bgHeaderStyle}>
                    <img alt={""} src={bgHeader} style={{width:"inherit",height:"inherit"}}/>
                </div>
                <Toolbar 
                    displayClose="none"
                    percentage={{
                        learn:this.props.correctRequestUpdateVocab,
                        total:this.props.rank_score}}
                    history={this.props.history}
                    progressBarColor={"#005b8e"}
                    updateVocabResultAPI={this.updateVocabResultAPI}
                    dialog={{
                        headerMessage:"ยอมแพ้แล้วหรอ ??",
                        bodyMessage:"",
                        cancelLabelButton:"สรุปผล",
                        submitLabelButton:"สู้ต่อ",
                    }}
                />
                <HeadCount count={{
                        learn:this.props.correctRequestUpdateVocab,
                        total:this.props.rank_score}}/>
                <img alt={""} src={this.props.packRank.srcRank} style={this.props.packRank.size}/>
                <div style={fontModal}><div style={{color:this.props.packRank.colorRank}}>{this.props.packRank.textRank}</div></div>
                <div style={fontContent}>(อันดับของคุณ)</div>

                <div style={containterFooter}>
                    <HomeButtons onClick={this.goToLandingPage} />
                </div>
            </div>
        );
    }

}


function mapDispatchToProps(dispatch) {
    return {
        // onLoading : () => dispatch(loading()),
        clearVocabState: () => dispatch(clearVocabState()),
     };
}


function mapStateToProps({ homeReducer, challengeVocabReducer }) {
    return {
        user_score: homeReducer.challenge.user_score,
        rank_score: homeReducer.challenge.rank_score,
        // loading: challengeVocabReducer.loading,
        packRank: challengeVocabReducer.packRankChallenge,
        correctRequestUpdateVocab: challengeVocabReducer.correctRequestUpdateVocab,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryChallenge);