import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
	root: {
		color: "white",
		backgroundColor: "#e9446a",
		borderRadius: 23,
		textTransform: 'none',
		width: 99,
		height: 40,
		fontSize:15,
		fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
		'&:hover': {
			backgroundColor: '#e9446a',
			borderColor: '#e9446a',
			boxShadow: 'none',
		},
	},
}))(Button);

function SubmitDialogButtons({onClick,textButton}) {
	return (
		<div>
			<ColorButton size="large" onClick={onClick}>
                {textButton}
			</ColorButton>
		</div>
	);
}

export default SubmitDialogButtons;
