import React from "react";
import { cardTitle, cardContainer } from "../styles";
import ItemCustomVocab from "./item-custom-vocab";


export default function CardVocabGroup(props) {
    if (props.list.length === 0) {
        return <div/>
    }

    return (
        <div style={cardContainer}>
            <div style={cardTitle}>
                {props.title}
                {   props.list.map((vocab) => {
                    return <ItemCustomVocab key={vocab.id} vocab={vocab} onTranslate={props.onTranslate} onRemember={props.onRemember} onForget={props.onForget} onDelete={props.onDelete} enable_open={true}/>
                })}
            </div>
        </div>
    );

}