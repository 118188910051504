import * as CON from "./const-challenge";
import normalModal from "../../../../assets/images/rank-normal@3x.png";
import bronzeModal from "../../../../assets/images/rank-bronze@3x.png";
import silverModal from "../../../../assets/images/rank-silver@3x.png";
import goldModal from "../../../../assets/images/rank-gold@3x.png";
import platinumModal from "../../../../assets/images/rank-platinum@3x.png";
import diamondModal from "../../../../assets/images/rank-diamond@3x.png";
import conquerorModal from "../../../../assets/images/rank-conqueror@3x.png";

const initialState = {
    challengeVocab : [],
    correctVocab: [],
    requestUpdateVocab: [],
    indexRequestUpdateVocab: 0,
    correctRequestUpdateVocab: 0,
    vocabCurrentIndex : 0,
    vocabNextIndex: 1,
    updateVocabFirst: true,
    vocabFirst : {
        vocab_id : "",
        vocab : "",
        image : "",
        meaning : "",
        sentense : "",
        choice2 : "",
        choice3 : "",
        noun : false,
        verb : false,
        adjective : false,
        adverb : false,
        preposition : false,
        pronoun : false,
        conjunction : false,
        isHiddenUI : true,
        choices : [],
        isAnswer: false
    },
    vocabSecond : {
        vocab_id : "",
        vocab : "",
        image : "",
        meaning : "",
        sentense : "",
        choice2 : "",
        choice3 : "",
        noun : false,
        verb : false,
        adjective : false,
        adverb : false,
        preposition : false,
        pronoun : false,
        conjunction : false,
        isHiddenUI : true,
        choices : [],
        isAnswer: false
    },
    hiddenNextButton : true,
    loading: false,
    dialogError: {
        show: false,
        message: ""
    },
    updateChallengeVocabSuccess: false,
    packRankChallenge: {
        colorRank: "#000000",
        textRank: "-",
        srcRank: normalModal,
        size: {marginTop:"20%",width:268,height:268},
    }
};

export default function challengeVocabReducer(state = initialState, { type, payload }) {
    switch (type) {
        case CON.IS_HIDDEN_NEXT_BUTTON : {
            return {
                ...state,
                hiddenNextButton : payload.flag,
            }
        }
        case CON.LOADING_CHALLENGE_VOCAB: {
            return {
                ...state,
                loading: true
            }
        }
        case CON.SETUP_CHALLENGE_VOCAB : {
            return {
                ...state,
                vocabFirst : payload.vocabFirst,
                vocabSecond : payload.vocabSecond,
                challengeVocab : payload.challengeVocab,
                requestUpdateVocab: payload.requestUpdateVocab,
                loading: false,
            }
        }
        case CON.NEXT_VOCAB : {
            return {
                ...state,
                vocabCurrentIndex : payload.vocabCurrentIndex,
                vocabNextIndex: payload.vocabNextIndex,
                vocabFirst : payload.vocabFirst,
                vocabSecond : payload.vocabSecond,
                hiddenNextButton: payload.hideNextButton,
                updateVocabFirst: payload.updateVocabFirst
            }
        }
        case CON.SUBMIT_ANSWER : {
            return {
                ...state,
                vocabFirst : payload.vocabFirst,
                vocabSecond : payload.vocabSecond,
                hiddenNextButton: payload.hiddenNextButton,
                correctVocab: payload.correctVocab,
                challengeVocab: payload.challengeVocab,
                requestUpdateVocab: payload.requestUpdateVocab,
                indexRequestUpdateVocab: payload.indexRequestUpdateVocab,
                correctRequestUpdateVocab: payload.correctRequestUpdateVocab,
            }
        }
        case CON.CLOSE_CHALLENGE_DIALOG: {
            return {
                ...state,
                loading: false,
                dialogError: {
                    show: false,
                    message: ""
                }
            }
        }
        case CON.UPDATE_CHALLENGE_VOCAB_SUCCESS: {
            const user_rank = payload.ranking
            let packScore = {
                colorRank: "#000000",
                textRank: "-",
                srcRank: normalModal,
                size: {marginTop:"20%",width:268,height:268},
            }
            if (user_rank === "Bronze"){
                packScore = {
                    colorRank: "#8e2e29",
                    textRank: user_rank,
                    srcRank: bronzeModal,
                    size: {marginTop:"20%",width:215,height:282},
                }
            }
            if (user_rank === "Silver"){
                packScore = {
                    colorRank: "#adb8cc",
                    textRank: user_rank,
                    srcRank: silverModal,
                    size: {marginTop:"20%",width:215,height:268},
                }
            }
            if (user_rank === "Gold"){
                packScore = {
                    colorRank: "#fcc555",
                    textRank: user_rank,
                    srcRank: goldModal,
                    size: {marginTop:"20%",width:215,height:282},
                }
            }
            if (user_rank === "Platinum"){
                packScore = {
                    colorRank: "#474f54",
                    textRank: user_rank,
                    srcRank: platinumModal,
                    size: {marginTop:"20%",width:198,height:282},
                }
            }
            if (user_rank === "Diamond"){
                packScore = {
                    colorRank: "#01d0fb",
                    textRank: user_rank,
                    srcRank: diamondModal,
                    size: {marginTop:"20%",width:282,height:198},
                }
            }
            if (user_rank === "Conqueror"){
                packScore = {
                    colorRank: "#ff193d",
                    textRank: user_rank,
                    srcRank: conquerorModal,
                    size: {marginTop:"20%",width:282,height:282},
                }
            }
            return {
                ...state,
                updateChallengeVocabSuccess: true,
                packRankChallenge: packScore,
                loading: false,
            }
        }
        case CON.UPDATE_CHALLENGE_VOCAB_ERROR: {
            return {
                ...state,
                loading: false,
                dialogError: {
                    ...payload.dialogError
                }
            }
        }
        case CON.CLEAR_VOCAB_STATE: {
            return {
                ...state,
                challengeVocab : [],
                correctVocab: [],
                requestUpdateVocab: [],
                indexRequestUpdateVocab: 0,
                correctRequestUpdateVocab: 0,
                vocabCurrentIndex : 0,
                vocabNextIndex: 1,
                updateVocabFirst: true,
                vocabFirst : {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                    isHiddenUI : true,
                    choices : [],
                    isAnswer: false
                },
                vocabSecond : {
                    vocab_id : "",
                    vocab : "",
                    image : "",
                    meaning : "",
                    sentense : "",
                    choice2 : "",
                    choice3 : "",
                    noun : false,
                    verb : false,
                    adjective : false,
                    adverb : false,
                    preposition : false,
                    pronoun : false,
                    conjunction : false,
                    isHiddenUI : true,
                    choices : [],
                    isAnswer: false
                },
                hiddenNextButton : true,
                loading: false,
                dialogError: {
                    show: false,
                    message: ""
                },
                updateChallengeVocabSuccess: false,
                packRankChallenge: {
                    colorRank: "#000000",
                    textRank: "-",
                    srcRank: normalModal,
                    size: {marginTop:"20%",width:268,height:268},
                }
            }
        }
////////////////////////////////////////////////////////////////////////////////////
        case CON.FETCH_VOCAB_CHALLENGE_SUCCESS : {
            return {
                ...state,
                loading : false,
                challengeVocab : payload.challengeVocab,
            }
        }
        case CON.FETCH_VOCAB_CHALLENGE_FAIL : {
            return {
                ...state,
                loading : false,
                dialogError : {
                    show : true,
                    message : payload.error
                },
            }
        }
        default : {
            return state;
        }
    }

}