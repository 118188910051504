import {USER_ACTION} from "../reducer/users";
import "firebase/auth";

export function invalidateToken(user) {
    return (dispatch, getState) => {
        const { app } = getState().firebase;
        app.auth().currentUser.getIdToken(false)
            .then((idToken) => [
                dispatch({
                    type: USER_ACTION.UPDATE_TOKEN,
                    payload: {
                        token: idToken,
                        username: user.username,
                        email: user.email
                    }
                })
            ]);
    }
}

export function refreshToken() {
    return (dispatch, getState) => {
        const { app } = getState().firebase;
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken()
                    .then((idToken) => {
                        let token = idToken;
                        if (token === null || token === undefined) {
                            token = "";
                        }
                        dispatch({
                            type: USER_ACTION.REFRESH_TOKEN,
                            payload: {
                                token: token
                            }
                        });
                    })
            } else {
                dispatch({
                    type: USER_ACTION.USER_STATE_NOT_FOUND,
                    payload: {
                        token: ""
                    }
                })
            }
        });
    }
}