import React from "react";

export default function Spacer(props) {
    
    const style = {
        width: props.spaceWidth,
        height: props.spaceHeight
    }

    return (
        <div style={style}/>
    );
}