import React from "react";
import Slider from "react-slick";
import RepeatVocab from "./repeatVocab";
import NextButtons from "./nextButton";
import { connect } from "react-redux";
import {
    nextVocab,
    countLoopRepeat,
    setAudio,
} from "./controller/repeat-action";
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

class SlideChoice extends React.Component {

    constructor(props) {
        super(props);

        this.onNextVocab = this.onNextVocab.bind(this);
    }

    componentDidMount() {
        this.props.setAudio(this.props.stackVocabFirst.voice)
    }

    onNextVocab() {
        this.slider.slickNext();
        let srcAudio = this.props.stackVocabFirst.voice;

        if (this.props.toggleVoice === false) {
            srcAudio = this.props.stackVocabSecond.voice;

        }
        this.props.setAudio(srcAudio);

        this.props.countLoopRepeat();
    }

    onAfterChange(current) {
        this.props.nextVocab();
    }

    render() {
        let vocabAudio;
        if (this.props.currentIndexVocab === 0 && this.props.loopRepeat === 0) {
            vocabAudio = <div style={{ display: "none" }}>
                <AudioPlayer
                    autoPlay
                    src={this.props.stackVocabFirst.voice}
                // onPlay={e => console.log("onPlay")}
                />
            </div>
        } else {
            vocabAudio = <div style={{ display: "none" }}>
                <AudioPlayer
                    autoPlay
                    src={this.props.getAudio}
                // onPlay={e => console.log("onPlay")}
                />
            </div>
        }

        const settings = {
            infinite: true,
            lazyLoad: true,
            initialSlide: 0,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 500,
            arrows: false,
            adaptiveHeight: true,
            accessibility: false,
            swipe: false,
            // fade : true,
            beforeChange: function (currentSlide, nextSlide) {
            },
            afterChange: (current) => {
                this.onAfterChange(current);
            }
        };

        let showSlider = <Slider ref={slider => (this.slider = slider)} {...settings}>
            <RepeatVocab
                onNextVocab={this.onNextVocab}
                vocab={this.props.stackVocabFirst} />
            <RepeatVocab
                onNextVocab={this.onNextVocab}
                vocab={this.props.stackVocabSecond} />
        </Slider>

        if (this.props.stackVocabSecond.choices.length === 0) {
            showSlider = <Slider ref={slider => (this.slider = slider)} {...settings}>
                <RepeatVocab
                    onNextVocab={this.onNextVocab}
                    vocab={this.props.stackVocabFirst} />
            </Slider>
        }

        return (
            <div>
                <div style={{ height: "700px", overflow: "scroll" }}>
                    {showSlider}
                </div>
                {vocabAudio}
                <div style={{
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    position: "fixed",
                    bottom: 50,
                    left: 0,
                    right: 0,
                    visibility: this.props.isHiddenNextButton ? "hidden" : "visible",
                }}>
                    <NextButtons onClick={this.onNextVocab} />
                </div>

            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        nextVocab: () => dispatch(nextVocab()),
        countLoopRepeat: () => dispatch(countLoopRepeat()),
        setAudio: (audio) => dispatch(setAudio(audio)),
    };
}

function mapStateToProps({ ui, firebase, user, session, repeatVocabReducer }) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        stackVocabFirst: repeatVocabReducer.stackVocabFirst,
        stackVocabSecond: repeatVocabReducer.stackVocabSecond,
        isHiddenNextButton: repeatVocabReducer.isHiddenNextButton,
        currentIndexVocab: repeatVocabReducer.currentIndexVocab,
        loopRepeat: repeatVocabReducer.loopRepeat,
        toggleVoice: repeatVocabReducer.toggleVoice,
        getAudio: repeatVocabReducer.audio,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideChoice);
