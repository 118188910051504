export const conrainerCard = {
    // width: "100%",
    width: "94%",
    borderRadius: 20,
    boxShadow: `0 5px 10px 0 rgba(0, 64, 128, 0.15)`,
    backgroundColor: `#ffffff`,
    padding : "auto",
    paddingTop : 10,
    paddingBottom : 10,
    marginTop : "3%",
    marginLeft : "3%",
    marginRight : "3%",
    marginBottom : "4%"
}


export const containerHeader = {
    width: '100%',
    display: "flex",
}

export const soundButton = {
    width: 45,
    height: 45,
    borderRadius: 14,
    boxShadow: `0 2px 20px 0 rgba(0, 64, 128, 0.1)`,
    backgroundColor: `#ffffff`,
    display : "flex",
    alignItems : "center",
    justifyContent : "center",
    marginLeft : 20,
    marginTop : 20,
}

export const containerVocabImg = {
    textAlign : "center",
    alignSelf: "center",
    flexGrow : 1,
    transform: `translateY(-10%)`,

}

export const vocabImgStyle = {
    width: 145,
    height: 145,
    margin : "auto",
    paddingTop : 40,
    borderRadius: 10,
    backgroundPosition: `center center`,
    backgroundRepeat: `no-repeat`,
    overflow: `hidden`,
    transform: `translateX(-8px)`
}

export const vocabImgStyle2 = {
    width: 145,
    height: 145,
    objectFit: `cover`, /* Do not scale the image */
    objectPosition: `center`, /* Center the image within the element */
    borderRadius: 10,
}

// export const containerCloseCard = {
//     alignSelf: "flex-start",
//     width: 45,
//     height: 45,
// }


export const itemButton = {
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
    cursor: "pointer"
}
export const textDetial = {
    fontFamily: "Prompt, sans-serif",
    textAlign : "center",
    
}
export const textVocab = {
    marginLeft : 20,
    marginRight : 20,
    fontSize: `calc(1vw + 4vh)`,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#202027",
    wordWrap: "break-word",
}
export const textVocabType = {
    fontSize: 14,
    marginTop: 10,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#888992",
}
export const textVocabMeaning = {
    fontSize: 21,
    fontWeight: 500,
    marginTop : "5%",
    marginBottom : "5%",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4a4a4a",
    marginLeft : 20,
    marginRight : 20,
}
export const textVocabExample = {
    fontSize: 14,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4a4a4a",
    marginLeft : 20,
    marginRight : 20,
}
export const textVocabSolve = {
    fontSize: 14,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4a4a4a",
    marginBottom: 25,
}
export const textVocabSolveTagP = {
    margin: 0,
}
