import React, { useState } from "react";
import Drawer from '@material-ui/core/Drawer';
import { VerticalContainer, bottomContainerPadding } from "../styles";
import FilterList from "./list-filter";
import Spacer from "../../../common/space";

export default function CustomVocabFilter(props) {

    const [ currentSelect, setCurrentSelect ] = useState(0);

    const setSelectFilterId = (id) => {
        setCurrentSelect(id);
    }

    return (
        <Drawer
            anchor="bottom"
            open={props.open}
            onClose={props.onClose}>
            <div style={VerticalContainer}>
                <FilterList title={"เรียงลำดับ"} filter={props.items} onSelect={setSelectFilterId}/>
                <div>
                    <button onClick={() => props.onSubmitFilter(currentSelect)} className="bottom-button">
                        ยืนยัน
                    </button>
                </div>
                <Spacer spaceHeight={8} spaceWidth={0}/>
            </div>
        </Drawer>
    );

}