import React from "react";
import { connect } from "react-redux";
import ChallengeButton from "./challenge-button";

const container = {
    display: "flex",
};

const detailStyle = {
    flexGrow: 1,
    display: "block",
    marginLeft: 12,
    marginRight: 12
};


const fontTitle = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 15,
    fontWeight: 500,
    color: "#000000"
}

const fontModal = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 15,
    fontWeight: 500,
    
}

const fontScore = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 10,
    color: "#888992",
    marginLeft: 6,
    marginTop: 4,
}

class ChallengeTitle extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={container}>
                <img style={this.props.packRank.sizeHome} src={this.props.packRank.srcRank} alt="icon"/>
                <div style={detailStyle}>
                    <div style={fontTitle}>อันดับของคุณ</div>
                    <div style={container}>
                        <div style={fontModal}><div style={{color: this.props.packRank.colorRank}}>{this.props.packRank.textRank}</div></div> 
                        <div style={fontScore}>({this.props.user_score}/{this.props.rank_score})</div>
                    </div>
                </div>
                <div>
                    <ChallengeButton 
                        history={this.props.history} 
                    />
                </div>
            </div>
        );
    }

}
function mapDispatchToProps(dispatch) {
    return {
    }
}

function mapStateToProps({ homeReducer }) {
    return {
        loading: homeReducer.loading,
        error: homeReducer.error,
        packRank: homeReducer.packRank,
        user_score: homeReducer.challenge.user_score,
        rank_score: homeReducer.challenge.rank_score,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeTitle);
