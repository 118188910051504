import * as CON from "./const-repeat";
import {env} from "../../../../constants/config";

///////////////////////////////////////////////////////////////////////////////////

function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
}

function createChoiceList(vocab){
    if (vocab !== undefined) {
        return shuffle([
            {
                meaning: vocab.meaning,
                correct: true,
                state: "normal"
            },
            {
                meaning: vocab.choice2,
                correct: false,
                state: "normal"
            },
            {
                meaning: vocab.choice3,
                correct: false,
                state: "normal"
            },
        ]);
    }
    return [];
}



function updateChoice(vocab,selectedChoice){
    return vocab.choices.map((choice) => {
        if (choice.meaning === selectedChoice.meaning && selectedChoice.correct === true) {
            return {
                ...choice, 
                state: "true"
            }
        } else if (choice.meaning === selectedChoice.meaning && selectedChoice.correct === false) {
            return {
                ...choice,
                state: "false"
            }
        } else if (choice.correct === true && selectedChoice.correct === false) {
            return {
                ...choice,
                state: "true"
            }
        } else {
            return {
                ...choice
            }
        }
    })
}

function updateResultVocab(vocabs, updateVocab) {
    if (updateVocab !== undefined) {
        const vocabsList = [];
        vocabs.forEach((v) => {
            if (v.vocab_id === updateVocab.vocab_id) {
                vocabsList.push({
                    ...v,

                    isAnswerCorrect: true
                });
            } else {
                vocabsList.push(v);
            }
        });
        return vocabsList;
    }
    return vocabs;
}

function updateRequestApi(request, vocab_id, result) {
    return request.map((vocab) => {
        if (vocab.vocab_id === vocab_id && result === true) {
            return {
                ...vocab,
                attemp: vocab.attemp + 1,
                result: true
            }
        } else if (vocab.vocab_id === vocab_id && result === false) {
            return {
                ...vocab,
                attemp: vocab.attemp + 1,
                result: false
            }
        } else {
            return {
                ...vocab
            }
        }
    })
}


///////////////////////////////////////////////////////////////////////////////////

export function hiddenNextButton(){
    return {
        type: CON.IS_HIDDEN_NEXT_BUTTON,
        payload: {
            flag : true,
        }
    };
}

///////////////////////////////////////////////////////////////////////////////////

export function setAudio(audio) {
    return function(dispatch, getState) {
        dispatch({
            type: CON.SET_AUDIO,
            payload: {
                audio:audio
            }
        })

    }
}

export function loading() {
    return {
        type: CON.LOADING_REPEAT_VOCAB
    }
}

export function countLoopRepeat() {
    return {
        type: CON.COUNT_LOOP_REPEAT
    }
}

export function closeRepeatDialog() {
    return {
        type: CON.CLOSE_REPEAT_DIALOG
    }
}

export function submitAnswer(selectedChoice){
    return function(dispatch, getState) {
        const currentIndexVocab = getState().repeatVocabReducer.currentIndexVocab;
        const selectedVocab = getState().repeatVocabReducer.selectedVocab;
        const correctVocabs = getState().repeatVocabReducer.correctVocabs;
        const updateStackFirst = getState().repeatVocabReducer.updateStackFirst;
        const requestUpdate = getState().repeatVocabReducer.requestUpdate;
        let tempResultFirst = getState().repeatVocabReducer.stackVocabFirst;
        let tempResultSecond = getState().repeatVocabReducer.stackVocabSecond;

        let updateCorrectVocabs = correctVocabs;
        let updateResultSelectedVocab = selectedVocab;
        let updateTempRequest = requestUpdate;

        if (updateStackFirst && tempResultFirst.isAnswer === false){
            tempResultFirst = {
                ...tempResultFirst,
                choices : updateChoice(tempResultFirst, selectedChoice),
                isAnswer: true,
                isHiddenUI: selectedChoice.correct
            };

            if (selectedChoice.correct === true) {
                updateCorrectVocabs.push(tempResultFirst);
                updateResultSelectedVocab = updateResultVocab(updateResultSelectedVocab, tempResultFirst);
            }

            updateTempRequest = updateRequestApi(updateTempRequest, tempResultFirst.vocab_id, selectedChoice.correct);

        } else if (!updateStackFirst && tempResultSecond.isAnswer === false) {
            tempResultSecond = {
                ...tempResultSecond,
                choices : updateChoice(tempResultSecond, selectedChoice),
                isAnswer: true,
                isHiddenUI: selectedChoice.correct
            };

            if (selectedChoice.correct === true) {
                correctVocabs.push(tempResultSecond);
                updateResultSelectedVocab = updateResultVocab(updateResultSelectedVocab, tempResultSecond);
            }

            updateTempRequest = updateRequestApi(updateTempRequest, tempResultSecond.vocab_id, selectedChoice.correct);

        } else {
            return;
        }

        dispatch({
            type : CON.SUBMIT_ANSWER,
            payload : {
                stackVocabFirst : tempResultFirst,
                stackVocabSecond : tempResultSecond,
                isHiddenNextButton: selectedChoice.correct,
                correctVocabs: updateCorrectVocabs,
                selectedVocab: updateResultSelectedVocab,
                requestUpdate: updateTempRequest
            }
        })
    }
}

export function setupSelectedVocab(listVocab, resultVocab){
    return function(dispatch, getState) {
        const tempResultVocab = resultVocab.map((data) => {
            return data
        })
        const getStateVocab = getState().repeatVocabReducer;
        const selectedVocab = listVocab.map((v) => {
            return {
                ...v,
                isAnswerCorrect: false
            }
        });
        const requestUpdate = listVocab.map((v) => {
            return {
                vocab_id: v.vocab_id,
                attemp: 0
            }
        });
        let tempVocab = listVocab[getStateVocab.currentIndexVocab];
        let tempNextVocab = listVocab[getStateVocab.currentIndexVocab+1];

        let tempStackVocabFirst = {
            ...tempVocab,
            isHiddenUI : true,
            isAnswer: false,
            choices : createChoiceList(tempVocab)
        };
        let tempStackVocabSecond= {
            ...tempNextVocab,
            isHiddenUI : true,
            isAnswer: false,
            choices : createChoiceList(tempNextVocab)
        };

        dispatch({
            type: CON.SETUP_SELECTED_VOCAB,
            payload: {
                selectedVocab: selectedVocab,
                requestUpdate: requestUpdate,
                stackVocabFirst : tempStackVocabFirst,
                stackVocabSecond : tempStackVocabSecond,
                resultVocab : tempResultVocab,
                isRepeat: false
            }
        });
    }
}

export function nextVocab(){
    return function(dispatch, getState) {

        const {
            selectedVocab,
            stackVocabFirst,
            stackVocabSecond,
            currentIndexVocab,
            nextIndexVocab,
            updateStackFirst
        } = getState().repeatVocabReducer;

        let tempStackVocabFirst = stackVocabFirst;
        let tempStackVocabSecond = stackVocabSecond;

        let tempCurrentIndexVocab = nextIndexVocab;
        let tempNextIndexVocab = currentIndexVocab;

        let nextReplaceVocab;
        let indexNextVocab = tempCurrentIndexVocab;
        for (let i = 0; i<selectedVocab.length; i++) {
            indexNextVocab++;
            const newIndex = indexNextVocab % selectedVocab.length;
            const nextVocab = selectedVocab[newIndex];
            if (nextVocab.isAnswerCorrect === false) {
                nextReplaceVocab = nextVocab;
                tempNextIndexVocab = newIndex;
                break;
            }
        }

        if(updateStackFirst) {
            tempStackVocabFirst = {
                ...nextReplaceVocab,
                isHiddenUI : true,
                isAnswer: false,
                choices: createChoiceList(nextReplaceVocab)
            }
        } else {
            tempStackVocabSecond = {
                ...nextReplaceVocab,
                isHiddenUI : true,
                isAnswer: false,
                choices: createChoiceList(nextReplaceVocab)
            }
        }

        dispatch({
            type: CON.NEXT_VOCAB,
            payload: {
                currentIndexVocab : tempCurrentIndexVocab,
                nextIndexVocab: tempNextIndexVocab,
                stackVocabFirst : tempStackVocabFirst,
                stackVocabSecond : tempStackVocabSecond,
                hideNextButton : true,
                updateStackFirst: stackVocabSecond.choices.length === 0 ? updateStackFirst : !updateStackFirst
            }
        });
    }
}

export function requestUpdateRepeatVocab() {
    return (dispatch, getState) => {

        const { requestUpdate, resultVocab, isRepeat } = getState().repeatVocabReducer;
        const { token } = getState().user;

        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
            body: JSON.stringify({
                is_repeat: isRepeat,
                vocab_result: requestUpdate.concat(resultVocab)
            })
        };

        const host = env.host + "/apiAsia/vocab/update";
        fetch(host, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    dispatch({
                        type: CON.UPDATE_REPEAT_VOCAB_ERROR,
                        payload: {
                            dialogError: {
                                show: true,
                                message: response.statusText
                            }
                        }
                    })
                }
            })
            .then((data) => {
                if (data.status_code === "00") {
                    dispatch({
                        type: CON.UPDATE_REPEAT_VOCAB_SUCCESS,
                    });
                } else {
                    dispatch({
                        type: CON.UPDATE_REPEAT_VOCAB_ERROR,
                        payload: {
                            dialogError: {
                                show: true,
                                message: data.status_message
                            }
                        }
                    })
                }
            })
            .catch((error) => {
                dispatch({
                    type: CON.UPDATE_REPEAT_VOCAB_ERROR,
                    payload: {
                        dialogError: {
                            show: true,
                            message: "ไม่สามารถดำเนินการได้ในขณะนี้"
                        }
                    }
                })
            })
    }
}

export function clearVocabState() {
    return {
        type: CON.CLEAR_VOCAB_STATE
    }
}

///////////////////////////////////////////////////////////////////////////////

export function fetchRepeatVocab(){
    return function(dispatch, getState) {
        const { token } = getState().user;
        
        const requestOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + token,
                "Cache-Control": "no-cache"
            },
        };
        const host = env.host + "/apiAsia/vocab/repeat";
        fetch(host, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status_code === "00") {
                    dispatch({
                        type: CON.FETCH_VOCAB_REPEAT_SUCCESS,
                        payload: {
                            selectedVocab : data.new_vocab,
                        }
                    });
                } else {
                    dispatch({
                        type: CON.FETCH_VOCAB_REPEAT_FAIL,
                        payload: {
                            error : data.status_message
                        }
                    });
                }
            
            })
            .catch((error) => {
                dispatch({
                    type: CON.FETCH_VOCAB_REPEAT_FAIL,
                    payload: {
                        error : "ไม่สามารถดำเนินการได้ในขณะนี้"
                    }
                });
            });


    }
}

export function gotoRepeat() {
    return function(dispatch, getState) {
        dispatch({
            type: CON.SET_FIRST_TIME,
            payload: {
                firstTime: false
            }
        })
    }
}

export function setRepeat(repeat) {
    return function(dispatch, getState) {
        dispatch({
            type: CON.SET_REPEAT,
            payload: {
                repeat: repeat
            }
        })
    }
}

export function setCustomVocabAnswer(vocab, remember) {
    return function(dispatch, getState) {
        dispatch({
            type: CON.SET_CUSTOM_VOCAB_ANSWER,
            payload: {
                vocab: vocab,
                remember: remember
            }
        })
    }
}