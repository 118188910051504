import React,  { useState, useEffect } from "react";
import { connect } from "react-redux";
import CardVocabGroup from "./component/list-card-custom-vocab";
import { mainContainer } from "./styles";
import { addCustomVocab, checkCanAddCustomVocab, deleteVocab, getCustomVocab, updateCustomVocab } from "../../../client/api-client";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import EmptyVocabSection from "./vocab-empty";
import { ActionBottomButton } from "./component/button-bottom-action";
import AddCustomVocabDialog from "./component/dialog-add-custom-vocab"
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";
import Spacer from "../../common/space";
import { COURSES } from "../../../constants/routes";
import CustomVocabFilter from "./component/filter-custom-vocab";

export const CUSTOM_VOCAB_STATE = {
    WAITING: "waiting",
    PENDING: "pending",
    READY: "ready",
    REMEMBER: "remember",
    FORGET: "forget"
}

function CustomVocabSection(props) {

    const [ customPendingVocab, setCustomPendingVocab ] = useState([]);
    const [ customWaitingVocab, setCustomWaitingVocab ] = useState([]);
    const [ userVocabInfo, setUserVocabInfo ] = useState({
        isFirstTime: true,
        customVocabCount: 0,
        paidCourseCount: 0
    });
    const [ filter, setFilter ] = useState([
        {
            id: 0,
            name: "การทบทวน",
            selected: true
        },
        {
            id: 1,
            name: "ตัวอักษร",
            selected: false
        },
    ]);
    const [ isInitial, setInitial ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ openAddDialog, setOpenAddDialog ] = useState(false);
    const [ limitDialogBuyCourse, setLimitDialogBuyCourse ] = useState(false);
    const [ limitDialogClose, setLimitDialogClose ] = useState(false);
    const [ errorDialog, setErrorDialog ] = useState({
        open: false,
        statusCode: "",
        statusMessage: ""
    });
    const token = props.token;

    const refreshVocab = () => {
        getCustomVocab(props.token, 
        (successResponse) => {
            setLoading(false);
            setCustomPendingVocab(successResponse.pending_vocab.map(vocab => {
                return {
                    ...vocab,
                    status: CUSTOM_VOCAB_STATE.WAITING
                }
            }));
            setCustomWaitingVocab(successResponse.waiting_vocab.map(vocab => {
                return {
                    ...vocab,
                    status: CUSTOM_VOCAB_STATE.WAITING
                }
            }));
        }, 
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        });
    }

    const refreshAddVocabState = () => {
        setLoading(true);
        checkCanAddCustomVocab(props.token, 
        (successResponse) => {
            setLoading(false);
            setUserVocabInfo({
                ...userVocabInfo,
                isFirstTime: false,
                customVocabCount: successResponse.custom_vocab_count,
                paidCourseCount: successResponse.paid_course_count
            });
        },
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        });
    }

    useEffect(() => {
        if (isInitial === false) {
            setLoading(true);
            refreshVocab()
            refreshAddVocabState();
            setInitial(true);
        }
    }, []);

    const onAddCustomVocab = (vocab, meaning, note) => {
        setLoading(true);
        addCustomVocab(props.token, 
        {
            vocab: vocab,
            meaning: meaning,
            note: note
        }, 
        (successResponse) => {
            setLoading(false);
            setOpenAddDialog(false);
            setUserVocabInfo({
                ...userVocabInfo,
                customVocabCount: userVocabInfo.customVocabCount+1
            })
            refreshVocab();
        }, 
        (errorResponse) => {
            setLoading(false);
            setOpenAddDialog(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        });
    }

    const onTranslate = (id) => {
        setCustomPendingVocab((customPendingVocab) => 
            customPendingVocab.map((vocab) => {
                if (vocab.id === id) {
                    return {
                        ...vocab,
                        status: CUSTOM_VOCAB_STATE.READY
                    }
                }
                return {
                    ...vocab
                }
        }));
    }

    const onRemember = (id) => {
        setLoading(true);
        updateCustomVocab(props.token, {
            id: id,
            result: true
        }, 
        (successResponse) => {
            setLoading(false);
            let popVocab;
            setCustomPendingVocab((customPendingVocab) => 
                customPendingVocab.map(vocab => {
                    if (vocab.id === id) {
                        popVocab = {
                            ...vocab,
                            status: CUSTOM_VOCAB_STATE.WAITING
                        };
                        return {
                            ...vocab,
                            status: CUSTOM_VOCAB_STATE.REMEMBER
                        }
                    }
                    return {
                        ...vocab
                    }
                })
            );
            setTimeout(() => {
                setCustomPendingVocab((customPendingVocab) => 
                customPendingVocab.filter(vocab => {
                    if (vocab.id !== id) {
                        return {...vocab}
                    }
                }));
                setCustomWaitingVocab((customWaitingVocab) => 
                    [popVocab, ...customWaitingVocab.map((vocab) => {
                        return {...vocab};
                    })]
                );
            }, 3000)
        },
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        })
    }

    const onForget = (id) => {
        setLoading(true);
        updateCustomVocab(props.token, {
            id: id,
            result: false
        }, 
        (successResponse) => {
            setLoading(false);
            let popVocab;
            setCustomPendingVocab((customPendingVocab) => 
                customPendingVocab.map(vocab => {
                    if (vocab.id === id) {
                        popVocab = {
                            ...vocab,
                            status: CUSTOM_VOCAB_STATE.WAITING
                        }
                        return {
                            ...vocab,
                            status: CUSTOM_VOCAB_STATE.FORGET
                        }
                    }
                    return {
                        ...vocab
                    }
                })
            );
            setTimeout(() => {
                
                setCustomPendingVocab((customPendingVocab) => 
                customPendingVocab.filter(vocab => {
                    if (vocab.id !== id) {
                        return {...vocab}
                    }  
                }));
                setCustomWaitingVocab((customWaitingVocab) => 
                    [popVocab, ...customWaitingVocab.map((vocab) => {
                        return {...vocab};
                    })]
                );
            }, 3000)
        },
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        })
    }

    const onOpenAddCustomVocabDialog = () => {
        if (userVocabInfo.paidCourseCount === 0) {
            if (userVocabInfo.customVocabCount >= 50) {
                setLimitDialogBuyCourse(true);
            } else {
                setOpenAddDialog(true);
            }
        } else if (userVocabInfo.paidCourseCount > 0) {
            if (userVocabInfo.customVocabCount >= 3000) {
                setLimitDialogClose(true);
            } else {
                setOpenAddDialog(true);
            }
        }
    }

    const onCloseAddCustomDialog = () => {
        setOpenAddDialog(false);
    }

    const onGotoCourseDialog = () => {
        props.history.push(COURSES);
    }

    const onCloseLimitDialog = () => {
        setLimitDialogBuyCourse(false);
        setLimitDialogClose(false);
        setErrorDialog({
            open: false,
            statusCode: "",
            statusMessage: ""
        });
    }

    const onDeleteVocab = (id) => {
        setLoading(true);
        deleteVocab(props.token, id, 
        (successResposne) => {
            setLoading(false);
            refreshVocab();
            setUserVocabInfo({
                ...userVocabInfo, 
                customVocabCount: userVocabInfo.customVocabCount-1
            });
        },
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        })
    }

    const onFilter = (id) => {
        let item;
        let isSortAble = false;
        props.onCloseFilter();

        setFilter((filter) => 
            filter.map((f) => {
                if (f.selected === true && f.id !== id) {
                    isSortAble = true;
                }
                if (f.id === id) {
                    item = {...f};
                    return {
                        ...f,
                        selected: true
                    }
                } else {
                    return {
                        ...f,
                        selected: false
                    }
                }
            })
        );
        
        if (item.id === 0) {
            sortRepetition();
        } else if (item.id === 1) {
            sortAlphabet();
        }
    }

    const sortRepetition = () => {
        setCustomPendingVocab((customPendingVocab) => 
            customPendingVocab.sort((first, second) => first.repetition - second.repetition)
        );
        setCustomWaitingVocab((customWaitingVocab) => 
            customWaitingVocab.sort((first, second) => first.repetition - second.repetition)
        );
    }

    const sortAlphabet = () => {
        setCustomPendingVocab((customPendingVocab) => 
            customPendingVocab.sort((first, second) => (first.vocab.toLowerCase() > second.vocab.toLowerCase()) ? 1 : -1)
        );
        setCustomWaitingVocab((customWaitingVocab) => 
            customWaitingVocab.sort((first, second) => (first.vocab.toLowerCase() > second.vocab.toLowerCase()) ? 1 : -1)
        );
    }

    if (customPendingVocab.length === 0 && customWaitingVocab.length === 0) {
        return (
            <div>
                <EmptyVocabSection/>
                <ActionBottomButton onClick={onOpenAddCustomVocabDialog} name={"+ จดศัพท์เพิ่ม"}/>
                <AddCustomVocabDialog open={openAddDialog} onClose={onCloseAddCustomDialog} onAdd={onAddCustomVocab}/>
                <CustomVocabFilter open={props.filterOn} onClose={props.onCloseFilter} items={filter} onSubmitFilter={onFilter}/>
                <ConfirmDialog
                    open={errorDialog.open}
                    onClose={onCloseLimitDialog}
                    message={errorDialog.statusMessage}
                    positiveText={"ตกลง"}
                    onPositive={onCloseLimitDialog}/>
                <DialogLoadingScreen dialogLoadingOpen={loading}/>
            </div>
        );
    }

    return (
        <div style={mainContainer}>
            <CardVocabGroup title={"ถึงเวลาทบทวน"} list={customPendingVocab} onTranslate={onTranslate} onRemember={onRemember} onForget={onForget} onDelete={onDeleteVocab} />
            <CardVocabGroup title={"ทั้งหมด"} list={customWaitingVocab} onDelete={onDeleteVocab} />
            <Spacer spaceHeight="100px" spaceWidth={0}/>
            <ActionBottomButton onClick={onOpenAddCustomVocabDialog} name={"+ จดศัพท์เพิ่ม"}/>
            <AddCustomVocabDialog open={openAddDialog} onClose={onCloseAddCustomDialog} onAdd={onAddCustomVocab}/>
            <CustomVocabFilter open={props.filterOn} onClose={props.onCloseFilter} items={filter} onSubmitFilter={onFilter}/>
            <ConfirmDialog 
                open={limitDialogBuyCourse} 
                onClose={onCloseLimitDialog}
                message={"จดศัพท์ได้สูงสุด 50 คำ คุณสามารถอัพเกรดได้จากคอร์สหลัก"} 
                onPositive={onGotoCourseDialog} 
                onNegative={onCloseLimitDialog} 
                positiveText={"อัพเกรด"}
                negativeText={"ปิด"}/>
            <ConfirmDialog
                open={limitDialogClose}
                onClose={onCloseLimitDialog}
                message={"จดศัพท์ได้สูงสุด 3000 คำ"} 
                onNegative={onCloseLimitDialog} 
                negativeText={"ปิด"}/>
            <ConfirmDialog
                open={errorDialog.open}
                onClose={onCloseLimitDialog}
                message={errorDialog.statusMessage}
                positiveText={"ตกลง"}
                onPositive={onCloseLimitDialog}/>
            <DialogLoadingScreen dialogLoadingOpen={loading}/>
        </div>
    );
}

function mapStateToProps({user}) {
    return {
        token: user.token
    }
}

function mapDispatchToProps(dispatch) {

}

export default connect(mapStateToProps, mapDispatchToProps)(CustomVocabSection);