import React from 'react';
import Button from '@material-ui/core/Button';

function InformButtons({ onClick }) {
	return (
		<div>
			<Button
				size="large"
				onClick={onClick}
				style={{
					color: "white",
					backgroundColor: "#d0021b",
					borderRadius: 23,
					textTransform: 'none',
					width: 311,
					height: 50,
					paddingLeft: 60,
					paddingRight: 60,
					fontSize: 16,
					fontFamily: 'Prompt, sans-serif',
					fontWeight: "400",
					'&:hover': {
						backgroundColor: '#d0021b',
						borderColor: '#d0021b',
						boxShadow: 'none',
					},
				}}
			>
				แจ้งโอน
			</Button>
		</div>
	);
}

export default InformButtons;
