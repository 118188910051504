import * as CON from "./const-vbook";

const initialState = {
    vocabBook : [],
    vocabBookForFilter : [],
    repeatBook : [],
    doneBook : [],
    checkedGroup : [{check : true , type : "ทั้งหมด", id : "all"},],
    checkedType : [
        {check : true , type : "ทั้งหมด", checkType : "all" ,},
        {check : false , type : "คำนาม (noun)", checkType : "noun" ,},
        {check : false , type : "คำกริยา (verb)", checkType : "verb" ,},
        {check : false , type : "คำวิเศษณ์ขยายนาม (adjective)", checkType : "adjective" ,},
        {check : false , type : "คำวิเศษณ์ขยายกริยา (adverb)", checkType : "adverb" ,},
        {check : false , type : "คำบุพบท (preposition)", checkType : "preposition" ,},
        {check : false , type : "คำสรรพนาม (pronoun)", checkType : "pronoun" ,},
        {check : false , type : "คำเชื่อมประโยค (conjunction)", checkType : "conjunction" ,},
    ],
    loading: false,
    drawerOpen: false,
    checkGroup : [],
    dialogError: {
        show: false,
        message: ""
    },
}

export default function vocabBookReducer(state = initialState, { type, payload }) {
    switch (type) {
        case CON.LOADING_VOCAB_BOOK: {
            return {
                ...state,
                loading: true
            }
        }
        case CON.TOGGLE_DRAWER : {
            return {
                ...state,
                drawerOpen : !state.drawerOpen
            }
        }
        case CON.FILTER_VOCAB: {

            const tempVocabBook = payload.vocabBook;
            let tempRepeatBook = []
            let tempDoneBook = []

            tempVocabBook.map(data => {
                if (data.repetition >= 4) {
                    tempDoneBook.push(data)
                } else {
                    tempRepeatBook.push(data)
                }
            })

            return {
                ...state,
                loading : false,
                vocabBook : payload.vocabBook,
                repeatBook : tempRepeatBook,
                doneBook : tempDoneBook,
                // drawerOpen : payload.drawerOpen,
            }
        }
        case CON.CLEAR_STATE: {
            return {
                ...state,
                vocabBook : [],
                vocabBookForFilter : [],
                repeatBook : [],
                doneBook : [],
                checkedGroup : [{check : true , type : "ทั้งหมด", id : "all"},],
                checkedType : [
                    {check : true , type : "ทั้งหมด", checkType : "all" ,},
                    {check : false , type : "คำนาม (noun)", checkType : "noun" ,},
                    {check : false , type : "คำกริยา (verb)", checkType : "verb" ,},
                    {check : false , type : "คำวิเศษณ์ขยายนาม (adjective)", checkType : "adjective" ,},
                    {check : false , type : "คำวิเศษณ์ขยายกริยา (adverb)", checkType : "adverb" ,},
                    {check : false , type : "คำบุพบท (preposition)", checkType : "preposition" ,},
                    {check : false , type : "คำสรรพนาม (pronoun)", checkType : "pronoun" ,},
                    {check : false , type : "คำเชื่อมประโยค (conjunction)", checkType : "conjunction" ,},
                ],
                loading: false,
                drawerOpen: false,
                checkGroup : [],
                dialogError: {
                    show: false,
                    message: ""
                },
            }
        }
        case CON.FETCH_VOCAB_BOOK_SUCCESS : {
            let tempVocabBook = payload.vocabBook;
            let tempRepeatBook = []
            let tempDoneBook = []

            tempVocabBook.map(data => {
                if (data.repetition > 0 && data.repetition < 4){
                    tempRepeatBook.push(data)
                } else if (data.repetition >= 4) {
                    tempDoneBook.push(data)
                } 
            })

            tempVocabBook = tempDoneBook.concat(tempRepeatBook);

            return {
                ...state,
                loading : false,
                vocabBook : tempVocabBook.sort(),
                vocabBookForFilter : tempVocabBook,
                repeatBook : tempRepeatBook,
                doneBook : tempDoneBook,
                checkedGroup : payload.checkedGroup,
            }
        }
        case CON.FETCH_VOCAB_BOOK_FAIL : {
            return {
                ...state,
                loading : false,
                dialogError : {
                    show : true,
                    message : payload.error
                },
            }
        }
        case CON.CHECKED_TYPE: {
            return {
                ...state,
                checkedType: payload.checkedType
            }
        }
        case CON.CHECKED_GROUP: {
            return {
                ...state,
                checkedGroup: payload.checkedGroup
            }
        }

        default : {
            return state;
        }
    }
}