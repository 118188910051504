import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCustomVocab, updateCustomVocab } from "../../../../client/api-client";
import { gotoRepeat, setCustomVocabAnswer } from "../../vocab-repeat/controller/repeat-action";
import Toolbar from "../../../common/progressBarChallenge";
import DialogLoadingScreen from "../../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../../common/Dialogs/confirm-dialog";
import { ActionBottomButton } from "../../mybook/component/button-bottom-action";
import CardVocabGroup from "../../mybook/component/list-card-custom-vocab";
import { CUSTOM_VOCAB_STATE } from "../../mybook/vocab-custom"
import { LANDING, REPEAT_RESULT } from "../../../../constants/routes";
import { compare } from "../utils";

function CustomVocabSection(props) {

    const [customVocab, setCustomVocab] = useState([]);
    const [progress, setProgress] = useState(0);

    const [ isInitial, setInitial ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ errorDialog, setErrorDialog ] = useState({
        open: false,
        statusCode: "",
        statusMessage: ""
    });

    const refreshVocab = () => {
        getCustomVocab(props.token, 
        (successResponse) => {
            setLoading(false);
            const vocab = successResponse.pending_vocab
            if (vocab !== undefined && vocab.length === 0) {
                props.gotoRepeat();
            } else {
                setCustomVocab(vocab.map(v => {
                    return {
                        ...v,
                        status: CUSTOM_VOCAB_STATE.PENDING
                    }
                }))
            }
        }, 
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        });
    }

    const onCloseErrorDialog = () => {
        setLoading(false);
        setErrorDialog({
            open: false,
            statusCode: "",
            statusMessage: ""
        });
    }

    const onTranslate = (id) => {
        setCustomVocab((customVocab) => 
            customVocab.map((vocab) => {
                if (vocab.id === id) {
                    return {
                        ...vocab,
                        status: CUSTOM_VOCAB_STATE.READY
                    }
                }
                return {
                    ...vocab
                }
        }));
    }

    const onRemember = (id) => {
        setLoading(true);
        updateCustomVocab(props.token, {
            id: id,
            result: true
        }, 
        (successResponse) => {
            let shouldGoNext = true;
            setLoading(false);
            setCustomVocab(customVocab.map(vocab => {
                if ((vocab.status === CUSTOM_VOCAB_STATE.PENDING || vocab.status === CUSTOM_VOCAB_STATE.READY) && vocab.id !== id) {
                    shouldGoNext = false;
                }

                if (vocab.id === id) {
                    props.setCustomVocabAnswer(vocab, true);
                    return {
                        ...vocab,
                        status: CUSTOM_VOCAB_STATE.REMEMBER
                    }
                }
                return {
                    ...vocab
                }
            }));
            setProgress(progress+1);
            if (shouldGoNext) {
                setTimeout(() => {
                    props.gotoRepeat();
                }, 1000)
            }
        },
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        })
    }

    const onForget = (id) => {
        setLoading(true);
        updateCustomVocab(props.token, {
            id: id,
            result: false
        }, 
        (successResponse) => {
            let shouldGoNext = true;
            setLoading(false);
            setCustomVocab(customVocab.map(vocab => {
                
                if ((vocab.status === CUSTOM_VOCAB_STATE.PENDING || vocab.status === CUSTOM_VOCAB_STATE.READY) && vocab.id !== id) {
                    shouldGoNext = false;
                }
                if (vocab.id === id) {
                    props.setCustomVocabAnswer(vocab, false)
                    return {
                        ...vocab,
                        status: CUSTOM_VOCAB_STATE.FORGET
                    }
                }
                return {
                    ...vocab
                }
            }));
            setProgress(progress+1);
            if (shouldGoNext) {
                setTimeout(() => {
                    props.gotoRepeat();
                }, 1000)
            }
        },
        (errorResponse) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: errorResponse.statusCode,
                statusMessage: errorResponse.statusMessage
            });
        })
    }

    const gotoLanding = () => {
        let completed = 0;
        for (const v of customVocab) {
            if (v.status === CUSTOM_VOCAB_STATE.REMEMBER || v.status === CUSTOM_VOCAB_STATE.FORGET) {
                completed++;
            }
        }
        if (completed > 0) {
            const sortedWrongVocab = props.wrongVocab.sort(compare);
            const sortedCorrectVocab = props.correctVocab.sort(compare);
            props.history.push({
                pathname: REPEAT_RESULT,
                state: {
                    total: customVocab.length,
                    completed: completed,
                    wrong_vocab: sortedWrongVocab,
                    correct_vocab: sortedCorrectVocab
                }
            });
        } else {
            props.history.push(LANDING);
        }
    }

    useEffect(() => {
        if (isInitial === false) {
            setLoading(true);
            refreshVocab()
            setInitial(true);
        }
    }, []);

    return (
        <div>
            <Toolbar 
                displayClose="flex"
                percentage={{
                    learn: progress,
                    total: customVocab.length}}
                history={props.history}
                progressBarColor={"#005b8e"}
                handlerGoToLanding={gotoLanding}
                dialog={{
                    headerMessage:"ยอมแพ้แล้วหรอ ??",
                    bodyMessage:"ทบทวนต่ออีกนิดนะ",
                    cancelLabelButton:"ยอมแพ้",
                    submitLabelButton:"สู้ต่อ",
                }}/>
            <div style={{textAlign: "center", marginBottom: 36}}>
                คุณมีศัพท์จดเพิ่มที่ต้องทบทวน
            </div>
            <CardVocabGroup 
                title={""} 
                list={customVocab} 
                onTranslate={onTranslate}
                onRemember={onRemember} 
                onForget={onForget} />

            <ActionBottomButton onClick={() => props.gotoRepeat()} name={"ถัดไป"}/>
            <ConfirmDialog
                open={errorDialog.open}
                onClose={onCloseErrorDialog}
                message={errorDialog.statusMessage}
                positiveText={"ตกลง"}
                onPositive={onCloseErrorDialog}/>
            <DialogLoadingScreen dialogLoadingOpen={loading}/>
        </div>
    );
}

function mapStateToProps({user, repeatVocabReducer}) {
    return {
        token: user.token,
        wrongVocab: repeatVocabReducer.incorrectCustomVocabs,
        correctVocab: repeatVocabReducer.correctCustomVocabs
    }
}

function mapDispatchToProps(dispatch) {
    return {
        gotoRepeat: () => dispatch(gotoRepeat()),
        setCustomVocabAnswer: (vocab, remember) => dispatch(setCustomVocabAnswer(vocab, remember))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomVocabSection);