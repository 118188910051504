import React from "react";
import {connect} from "react-redux";
import Toolbar from "../../common/progressBarChallenge";
import HeadCount from "./headCount";
import SlideChoice from "./slideChoice";
import * as CON from "./controller/const-repeat";
import {
    setupSelectedVocab,
    closeRepeatDialog,
    clearVocabState,
    loading,
    requestUpdateRepeatVocab,
    fetchRepeatVocab,
    setRepeat,
} from "./controller/repeat-action";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";
import {LANDING, REPEAT_RESULT} from "../../../constants/routes";
import CustomVocabSection from "../../screen/vocab-repeat/custom/custom-vocab";
import { compare } from "./utils";

const container = {
    display: "block",
    margin : "auto",
    textAlign : "center",
};

class RepeatVocab extends React.Component {

    constructor(props) {
        super(props);

        let selectedVocab = []
        let resultVocab = []
        if(this.props.location.state !== undefined) {
            selectedVocab = this.props.location.state.selectedVocab
            resultVocab = this.props.location.state.resultVocab
        }

        if (selectedVocab !== undefined && selectedVocab.length !== 0) {
            this.props.setupSelectedVocab(
                selectedVocab,
                resultVocab
            )
        } else if (selectedVocab === undefined && selectedVocab.length === 0 && !this.props.location.state.home){
            this.props.history.push(LANDING);
        } else {
            this.props.setRepeat(true);
        }
        
        this.onCloseErrorDialog = this.onCloseErrorDialog.bind(this);
        this.handlerGoToLanding = this.handlerGoToLanding.bind(this);
    }

    componentWillReceiveProps(nextProps,nextState){
        let resultVocab = []
        if(this.props.location.state !== undefined) {
            resultVocab = this.props.location.state.resultVocab
        }

        if (nextProps.selectedVocab.length !== this.props.selectedVocab.length){
            nextProps.setupSelectedVocab(
                nextProps.selectedVocab,
                resultVocab
            )
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loadRepeatVocabSuccess !== this.props.loadRepeatVocabSuccess && this.props.loadRepeatVocabSuccess === true && 
                this.props.selectedVocab.length === 0 && (this.props.incorrectCustomVocabs.length + this.props.correctCustomVocabs.length === 0)) {
            this.props.clearVocabState();
            this.props.history.push(LANDING);
        } else if (this.props.isRepeat === false && prevProps.updateRepeatVocabSuccess !== this.props.updateRepeatVocabSuccess &&
                this.props.updateRepeatVocabSuccess === true) {
            this.props.clearVocabState();
            this.props.history.push(LANDING);
        } else if (this.props.firstTime === false && prevProps.firstTime === false) {
            if (prevProps.updateRepeatVocabSuccess !== this.props.updateRepeatVocabSuccess &&
                this.props.updateRepeatVocabSuccess === true) {
                
                let total = 0;
                let completed = 0;
                let wrongVocab = [];
                let correctVocab = [];

                total += this.props.incorrectCustomVocabs.length + this.props.correctCustomVocabs.length + this.props.selectedVocab.length;
                completed += this.props.incorrectCustomVocabs.length + this.props.correctCustomVocabs.length + this.props.correctVocabs.length;
                
                const correctCourseVocab = [];
                const incorrectCourseVocab = [];
                for (const v of this.props.correctVocabs) {
                    for (const u of this.props.requestUpdate) {
                        if (v.vocab_id === u.vocab_id) {
                            if (u.attemp > 1) {
                                incorrectCourseVocab.push({...v});
                            } else {
                                correctCourseVocab.push({...v});
                            }
                            break;
                        }
                    }
                }
                
                wrongVocab = [...this.props.incorrectCustomVocabs.sort(compare), ...incorrectCourseVocab.sort(compare)];
                correctVocab = [...this.props.correctCustomVocabs.sort(compare), ...correctCourseVocab.sort(compare)];
                this.props.clearVocabState();
                this.props.history.push({
                    pathname: REPEAT_RESULT,
                    state: {
                        total: total,
                        completed: completed,
                        wrong_vocab: wrongVocab,
                        correct_vocab: correctVocab
                    }
                });
            }
        } else if (this.props.firstTime === false && prevProps.firstTime === true) {
            this.props.onLoading();
            this.props.fetchRepeatVocab();
        }
        
    }

    onCloseErrorDialog() {
        window.location.reload();
        this.props.closeErrorDialog();
    }

    handlerGoToLanding() {
        this.props.onLoading();
        this.props.onUpdate();
    }

    render() {
        if (this.props.firstTime === true && this.props.isRepeat === true) {
            return  (
                <CustomVocabSection history={this.props.history}/>
            )
        }
        return (
            <div style={container}>
                <Toolbar 
                    displayClose="flex"
                    percentage={{
                        learn:this.props.correctVocabs.length,
                        total:this.props.selectedVocab.length}}
                    history={this.props.history}
                    progressBarColor={"#005b8e"}
                    handlerGoToLanding={this.handlerGoToLanding}
                    dialog={{
                        headerMessage:"ยอมแพ้แล้วหรอ ??",
                        bodyMessage:"ทบทวนต่ออีกนิดนะ",
                        cancelLabelButton:"ยอมแพ้",
                        submitLabelButton:"สู้ต่อ",
                    }}
                />
                <HeadCount count={{
                        learn:this.props.correctVocabs.length,
                        total:this.props.selectedVocab.length}}/>
                <SlideChoice 
                    history={this.props.history} 
                    selectedVocab={this.props.selectedVocab}
                />
                <DialogLoadingScreen dialogLoadingOpen={this.props.loading}/>
                <ConfirmDialog
                    open={this.props.dialogError.show}
                    message={this.props.dialogError.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onCloseErrorDialog}/>
            </div>
        );
    }

}


function mapDispatchToProps(dispatch) {
    return {
        setupSelectedVocab: (selectedVocab,vocabResult) => dispatch(setupSelectedVocab(selectedVocab,vocabResult)),
        closeErrorDialog: () => dispatch(closeRepeatDialog()),
        clearVocabState: () => dispatch(clearVocabState()),
        onLoading : () => dispatch(loading()),
        onUpdate: () => dispatch(requestUpdateRepeatVocab()),
        fetchRepeatVocab: () => dispatch(fetchRepeatVocab()),
        setRepeat: (repeat) => dispatch(setRepeat(repeat))
     };
}


function mapStateToProps({ui, firebase, user, session, repeatVocabReducer}) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        selectedVocab: repeatVocabReducer.selectedVocab,
        correctVocabs: repeatVocabReducer.correctVocabs,
        loading: repeatVocabReducer.loading,
        dialogError: repeatVocabReducer.dialogError,
        updateRepeatVocabSuccess: repeatVocabReducer.updateRepeatVocabSuccess,
        firstTime: repeatVocabReducer.firstTime,
        isRepeat: repeatVocabReducer.isRepeat,
        incorrectCustomVocabs: repeatVocabReducer.incorrectCustomVocabs,
        correctCustomVocabs: repeatVocabReducer.correctCustomVocabs,
        requestUpdate: repeatVocabReducer.requestUpdate,
        loadRepeatVocabSuccess: repeatVocabReducer.loadRepeatVocabSuccess
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepeatVocab);