export const SETUP_CHALLENGE_VOCAB = "setup_selected_vocab";
export const NEXT_VOCAB = "next_vocab";
export const IS_HIDDEN_NEXT_BUTTON = "is_hidden_next_button";
export const SUBMIT_ANSWER = "submit_answer";
export const UPDATE_CHALLENGE_VOCAB_SUCCESS = "update_challenge_vocab_success";
export const UPDATE_CHALLENGE_VOCAB_ERROR = "update_challenge_vocab_error";
export const LOADING_CHALLENGE_VOCAB = "loading_challenge_vocab";
export const CLOSE_CHALLENGE_DIALOG = "close_challenge_dialog";
export const CLEAR_VOCAB_STATE = "clear_vocab_state";


export const FETCH_VOCAB_CHALLENGE_SUCCESS = "fetch_vocab_challenge_success"
export const FETCH_VOCAB_CHALLENGE_FAIL = "fetch_vocab_challenge_fail"
