import React from "react";
import {connect} from "react-redux";
import filterImg from "../../../assets/images/filterButton@3x.png";
import FilterBookScreen from "./filterPage";
import {
    toggleDrawer,
} from "./controller/vbook-action";

const container = {
    display: "flex",
    alignItems: "center",
    height: "60px"
};

const itemButton = {
    display: "flex",
    justifyContent: "center",
    backgroundColor: `rgba(0, 0, 0, 0)`,
    border: "none",
    outline: "none",
    cursor: "pointer",
    color: "white"
};

const itemTitle = {
    flexGrow: 1,
    fontWeight: 500,
    fontFamily: 'Prompt, sans-serif',
    fontSize: "23px",
    margin: "auto",
    textAlign: "center",
    color : "white"
};

const itemIcon = {
    width : 22,
    height : 22
}


class ToolbarBook extends React.Component {

    constructor(props) {
        super(props);

        this.handleBack = this.handleBack.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    handleBack() {
        if (this.props.onBack !== undefined) {
            this.props.onBack();
        } else {
            this.props.history.goBack();
        }
    }

    toggleDrawer(){
        this.props.toggleDrawer();
    }

    render() {
        return (
            <div style={container}>
                <div>
                    <button style={itemButton} onClick={this.handleBack}>
                        <i className="material-icons-round md-48">arrow_back_ios</i>
                    </button>
                </div>
                <div style={itemTitle}>
                    {this.props.title}
                </div>
                <div>
                    <button style={itemButton} onClick={this.toggleDrawer}>
                        <img src={filterImg} style={itemIcon} />
                    </button>
                </div>
                <FilterBookScreen drawerOpen={this.props.drawerOpen} />
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        // fetchCourseList: (token) => dispatch(fetchCourseList(token)),
        toggleDrawer: () => dispatch(toggleDrawer()),
    };
}

function mapStateToProps({vocabBookReducer}) {
    return {
        drawerOpen : vocabBookReducer.drawerOpen,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarBook);