import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SubmitDialogButtons from './submitDialogButton';
import * as DIA from '../../../constants/dialogs';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fontStyle = {
    fontFamily: "Prompt, sans-serif",
    textSize : 16,
    textAlign:"center"
}

class DialogCloseScreen extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.dialogOpen}
                onClose={this.props.handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        borderRadius: 25,
                        color:"#4a4a4a",
                        backgroundColor: "#f9fafb",
                        width : 350,
                        padding : 40,
                        paddingLeft: 10,
                        paddingRight: 10,
                    },
                  }}
            >
                <DialogTitle id="alert-dialog-title" style={{textAlign:"center"}}>
                    <span style={fontStyle}>
                        {this.props.purchaseFlag===true ? DIA.SUCCESS_PURCHASE_DIALOGS : DIA.FAIL_PURCHASE_DIALOGS}
                    </span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={fontStyle}>
                        {this.props.dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{justifyContent:"center"}}>
                    <SubmitDialogButtons onClick={this.props.handleDialogClose} textButton={DIA.BUTTON_CLOSE}/>
                </DialogActions>
            </Dialog>
        );
    }
}

export default DialogCloseScreen;