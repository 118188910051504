import React, { useState } from "react";
import { LANDING } from "../../../../constants/routes";
import Toolbar from "../../../common/progressBarChallenge";
import DialogLoadingScreen from "../../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../../common/Dialogs/confirm-dialog";
import CardVocab from "./component/card-vocab";
import Spacer from "../../../common/space";
import { ActionBottomButton } from "../../mybook/component/button-bottom-action";
import { notifyIncorrectVocabs } from "../../../../client/api-client";
import { connect } from "react-redux";

function RepeatResultScreen(props) {

    const [ loading, setLoading ] = useState(false);
    const [ errorDialog, setErrorDialog ] = useState({
        open: false,
        statusCode: "",
        statusMessage: ""
    });

    if (props.location.state === undefined) {
        props.history.push(LANDING);
        return (<div/>)
    }

    const total = props.location.state.total;
    const completed = props.location.state.completed;
    const wrongVocab = props.location.state.wrong_vocab;
    const correctVocab = props.location.state.correct_vocab;

    if (total === undefined || completed === undefined || wrongVocab === undefined || correctVocab === undefined || total === 0) {
        props.history.push(LANDING);
        return (<div/>);
    }

    const sendNotify = () => {
        setLoading(true);
        const custom_vocabs = [];
        const course_vocabs = [];
        for (const v of wrongVocab) {
            if (v.voice === undefined) {
                custom_vocabs.push({
                    id: v.vocab_id,
                    vocab: v.vocab
                });
            } else {
                course_vocabs.push({
                    id: v.vocab_id,
                    vocab: v.vocab
                });
            }
        }
        if (custom_vocabs.length === 0 && course_vocabs.length === 0) {
            props.history.push(LANDING);
        } else {
            notifyIncorrectVocabs(props.token, custom_vocabs, course_vocabs,
            (successResponse) => {
                setLoading(false);
                props.history.push(LANDING);
            }, 
            (errorResponse) => {
                setLoading(false);
                setErrorDialog({
                    open: true,
                    statusCode: errorResponse.statusCode,
                    statusMessage: errorResponse.statusMessage
                });
            });
        }
    }

    const onCloseErrorDialog = () => {
        setLoading(false);
        setErrorDialog({
            open: false,
            statusCode: "",
            statusMessage: ""
        });
    }

    return (
        <div>
            <Toolbar 
                    displayClose="none"
                    percentage={{
                        learn:completed,
                        total:total}}
                    history={props.history}
                    progressBarColor={"#005b8e"}
                    dialog={{
                        headerMessage:"ยอมแพ้แล้วหรอ ??",
                        bodyMessage:"ทบทวนต่ออีกนิดนะ",
                        cancelLabelButton:"ยอมแพ้",
                        submitLabelButton:"สู้ต่อ",
                    }}
                />
            <div style={{textAlign: "center", marginBottom: 36}}>
                สรุปผลการทบทวน
            </div>
            <ActionBottomButton onClick={() => sendNotify()} name={"เสร็จสิ้น"}/>
            <CardVocab list={wrongVocab} incorrect={true}/>
            <Spacer spaceHeight={24}/>
            <CardVocab list={correctVocab} incorrect={false}/>
            <ConfirmDialog
                open={errorDialog.open}
                onClose={onCloseErrorDialog}
                message={errorDialog.statusMessage}
                positiveText={"ตกลง"}
                onPositive={onCloseErrorDialog}/>
            <DialogLoadingScreen dialogLoadingOpen={loading}/>
        </div>
    )

}

function mapDispatchToProps(dispatch) {
    return {
        
    };
}

function mapStateToProps({user}) {
    return {
        token: user.token
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RepeatResultScreen);