import React from "react";
import {connect} from "react-redux";
import SectionTitle from "../../../common/section-title";
import ItemCourse from "../items/item-course";

class ListCourse extends React.Component {

    render() {
        if (this.props.ownCourses.length !== 0) {
            return (
                <div>
                    <SectionTitle title="คอร์สแพ็กเก็จ"/>
                    {this.props.ownCourses.map((course, i) => {
                        return <ItemCourse key={i} course={course} history={this.props.history}/>;
                    })}
                </div>
            );
        } else {
            return <div/>;
        }
    }

}

function mapStateToProps({courseReducer}) {
    return {
        ownCourses: courseReducer.allCourse
    }
}

export default connect(mapStateToProps)(ListCourse);