import React from "react";
import Slideshow from './slide';
import FooterScreen from '../../common/footer';
import '../../../assets/fonts/Brusher.ttf';
import './fonts.css';
import GoogleButton from './gbutton';
import FacebookButton from './fbutton';
import { connect } from "react-redux";
import { invalidateToken } from "../../../state/action/users-action";
import { LANDING, REGISTER } from "../../../constants/routes";
import logo from '../../../assets/images/logo-2@2x.png';
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import { PENDING_LOGIN, TOKEN } from "../../../constants/storage";
import { clearState, getUser, loginFailed } from "./controller/login-controller";
import { loginLoading } from "./controller/login-reducer";

const fontsHead = {
	color: "#2d3142",
	fontFamily: "Prompt, sans-serif",
	fontWeight: 500,
	fontSize: 32,
	margin: 0,
	lineHeight: 1.19,
	marginTop: 10
};

const fontsAloha = {
	fontFamily: "Brusher",
};

class LoginScreen extends React.Component {

	componentDidMount() {
		if (this.props.token !== "" && this.props.token != null) {
			this.props.history.push(LANDING);
		} else {
			this.props.firebase.auth().getRedirectResult()
				.then((result) => {
					if (result.credential) {
						const token = result.credential.accessToken;
						window.sessionStorage.setItem(TOKEN, token);
						this.props.loading();
						this.props.firebase.auth().currentUser.getIdToken(false)
							.then((idToken) => {
								this.props.getUser(idToken);
							});
					} else {
						window.sessionStorage.setItem(PENDING_LOGIN, null);
						this.props.onLoginFailed();
					}
				})
				.catch((error) => {
					window.sessionStorage.setItem(PENDING_LOGIN, null);
					this.props.onLoginFailed();
				});
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		const registerUserDetail = nextProps.loginState.register_user_detail;
		if (registerUserDetail != null && registerUserDetail !== this.props.loginState.register_user_detail) {
			this.props.history.push(
				REGISTER,
				registerUserDetail
			);
		}

		const loggedInUserDetail = nextProps.loginState.logged_in_user_detail;
		if (loggedInUserDetail != null && loggedInUserDetail !== this.props.loginState.logged_in_user_detail) {
			this.props.onInvalidateToken(loggedInUserDetail);
		}

		const token = nextProps.token;
		if (token !== "" && token !== this.props.token) {
			this.props.history.push(LANDING);
		}
	}

	componentWillUnmount() {
		this.props.onClearState();
	}

	render() {
		return (
			<div style={{ textAlign: "center", marginTop: 80 }}>
				<div>
					<img src={logo} style={{width:44,height:36}} alt="logo" />
				</div>
				<div style={fontsHead}>
					<div >ยินดีต้อนรับสู่</div>
					<div style={fontsAloha}>Aloha</div>
				</div>
				<div style={{ marginBottom: 20 }} />
				<Slideshow />
				<div style={{ marginBottom: 50 }} />
				<FacebookButton />
				<div style={{ marginBottom: 15 }} />
				<GoogleButton />
				<FooterScreen />
				<DialogLoadingScreen dialogLoadingOpen={this.props.loginState.loading} />
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		firebase: state.firebase.app,
		db: state.firebase.db,
		session: state.session,
		ui: state.ui,
		token: state.user.token,
		loginState: state.loginReducer
	};
}

function mapDispatchToProps(dispatch) {
	return {
		loading: () => dispatch(loginLoading()),
		getUser: (token) => dispatch(getUser(token)),
		onInvalidateToken: (user) => dispatch(invalidateToken(user)),
		onLoginFailed: () => dispatch(loginFailed()),
		onClearState: () => dispatch(clearState())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);