import React from "react";
import {connect} from "react-redux";
import { fetchCourseList } from "../../../state/action/course-action";
import { selectedVocabSubCourse } from "../../../state/action/vocab-action";
import SubmitButtons from "./footerButton/submitButton";
import CancelButton from "./footerButton/cancelButton";

const containterFooter = {
    backgroundColor: "#ffffff",
    width: "100%",
    textAlign : "center",
    justifyContent : "center",
    boxSizing: "border-box",
    position: "fixed",
    bottom: 20,
    left: 0,
    right: 0,
    paddingTop : 8,
    paddingRight : 15,
    paddingLeft : 15,
}

class FooterNewVocab extends React.Component {

    render() {
        
        return (
            <div style={containterFooter}>
                <span style={{color : "#888992"}}>
                    เลือกแล้ว {this.props.countVocabSelected} คำจาก {this.props.newVocabLength < 6 ? this.props.newVocabLength-this.props.currentIndexVocabCard : 5} คำ
                </span>
                <div style={{margin : 20}}/>
                <SubmitButtons onClick={this.props.passSubmitOnClick}/>
                <div style={{margin : 10}}/>
                <CancelButton onClick={this.props.passCancelOnClick}/>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        fetchCourseList: (token) => dispatch(fetchCourseList(token)),
        selectedVocabSubCourse: (selectedVocabs, counts) => dispatch(selectedVocabSubCourse(selectedVocabs, counts)),
    };
}

function mapStateToProps({ui, firebase, user, session, courses}) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        sub_courses: courses.ownCourses.sub_courses
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterNewVocab);