import React from "react";
import {withRouter} from 'react-router-dom';

const iconStyle = {
    width: 30,
    height: 30
};

const detailStyle = {
    flexGrow: 1,
    display: "block",
    marginLeft: 12,
    marginRight: 12
};

const titleStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 400,
    fontSize: 15,
    color: "#202027"
};

const descriptionStyle = {
    fontFamily: "Prompt, sans-serif",
    fontWeight: 300,
    fontSize: 13,
    color: "#888992"
};

class ItemSubCourse extends React.Component {

    constructor(props) {
        super(props);
        this.gotoNewVocab = this.gotoNewVocab.bind(this);
    }

    gotoNewVocab() {
        const packID = {courseID : this.props.ownCourse.course_id, subCourseID : this.props.subCourse.id}
        if(this.props.subCourse.learn_vocab != this.props.subCourse.total_vocab){
            this.props.history.push(
                "/course/"+ this.props.ownCourse.course_id + "/" + this.props.subCourse.id + "/new"
            ,packID);
        }
    }

    render() {
        let moreIcon = null;
        if (this.props.subCourse.learn_vocab < this.props.subCourse.total_vocab) {
            moreIcon = <i className="material-icons-round md-48 blueForward">arrow_forward_ios</i>
        }
        return (
            <button className="default-button" onClick={this.gotoNewVocab}>
                <img style={iconStyle} src={this.props.subCourse.thumbnail} alt="icon"/>
                <div style={detailStyle}>
                    <div style={titleStyle}>{this.props.subCourse.title}</div>
                    <div style={descriptionStyle}>เรียนแล้ว {this.props.subCourse.learn_vocab} คำจาก {this.props.subCourse.total_vocab} คำ</div>
                </div>
                <div>
                    {moreIcon}
                </div>
            </button>
        );
    }

}

export default withRouter(ItemSubCourse);