import React from "react";
import { connect } from "react-redux";
import { loguot } from "./controller/logout-controller";
import { LANDING, LOG_IN } from "../../../constants/routes";

class LogoutScreen extends React.Component {

    componentDidMount() {
        this.props.logout();
    }

    componentWillReceiveProps(nextProps, nextState) {
        const nextLogoutSuccessState = nextProps.logoutSuccess;
        const currentLogoutSuccessState = this.props.logoutSuccess;
        if (nextLogoutSuccessState !== currentLogoutSuccessState && nextLogoutSuccessState === true) {
            this.props.history.push(LOG_IN)
        } else if (nextLogoutSuccessState === false) {
            this.props.push(LANDING);
        }
    }

    render() {
        return <div/>
    }

}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(loguot())
    }
}

function mapStateToProps({ logoutReducer }) {
    return {
        logoutSuccess: logoutReducer.logoutSuccess
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutScreen);