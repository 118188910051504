import React from "react";

const container = {
    display: "block",
    paddingTop: 4
};

const sectionTitleStyle = {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: "Prompt, sans-serif"
};

class SectionTitle extends React.Component {

    render() {
        return (
            <div style={container}>
                <p style={sectionTitleStyle}>{this.props.title}</p>
            </div>
        );
    }

}

export default SectionTitle;