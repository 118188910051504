import React from "react";
import Slider from "react-slick";
import ChallengeVocab from "./challengeVocab";
import NextButtons from "./nextButton";
import SummaryButtons from './summaryButton';
import { connect } from "react-redux";
import {
    nextVocab,
    requestUpdateVocabChallengeVocab,
    loading,
} from "./controller/challenge-action";
import * as ROUTES from "../../../constants/routes";
import ReactAudioPlayer from 'react-audio-player';

class SlideChoice extends React.Component {

    constructor(props) {
        super(props);

        this.onNextVocab = this.onNextVocab.bind(this);
    }

    onNextVocab() {
        this.slider.slickNext();
    }

    goToSummaryPage(shouldRepeat) {
        this.props.onLoading()
        this.props.onUpdate(shouldRepeat)
    }

    onAfterChange(current) {
        this.props.nextVocab();
    }

    render() {
        const settings = {
            infinite: true,
            lazyLoad: true,
            initialSlide: 0,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 500,
            arrows: false,
            adaptiveHeight: true,
            accessibility: false,
            swipe: false,
            // fade : true,
            beforeChange: function (currentSlide, nextSlide) {
                
            },
            afterChange: (current) => {
                this.onAfterChange(current);
            }
        };

        let vocabAudio;
        if (this.props.currentIndex === 0) {
            vocabAudio = <ReactAudioPlayer
                src={this.props.vocabFirst.voice}
                autoPlay
            />;
        } else {
            let audioSrc = this.props.vocabFirst.voice;
            if (this.props.currentIndex % 2 !== 0) {
                audioSrc = this.props.vocabSecond.voice;
            }
            vocabAudio = <ReactAudioPlayer
                src={audioSrc}
                autoPlay
            />;
        }

        let showSlider = <Slider ref={slider => (this.slider = slider)} {...settings}>
            <ChallengeVocab
                history={this.props.history}
                onNextVocab={this.onNextVocab}
                vocab={this.props.vocabFirst} />
            <ChallengeVocab
                history={this.props.history}
                onNextVocab={this.onNextVocab}
                vocab={this.props.vocabSecond} />
        </Slider>

        if (this.props.vocabSecond.choices.length === 0) {
            showSlider = <Slider ref={slider => (this.slider = slider)} {...settings}>
                <ChallengeVocab
                    history={this.props.history}
                    onNextVocab={this.onNextVocab}
                    vocab={this.props.vocabFirst} />
            </Slider>
        }

        return (
            <div>
                <div style={{ height: "800px", overflow: "scroll" }}>
                    {showSlider}
                </div>
                {vocabAudio}
                <div style={{
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    position: "fixed",
                    bottom: 50,
                    left: 0,
                    right: 0,
                    visibility: this.props.hiddenNextButton ? "hidden" : "visible",
                }}>
                    <NextButtons onClick={() => this.goToSummaryPage(true)} /><br/>
                    <SummaryButtons onClick={() => this.goToSummaryPage(false)} />
                </div>

            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        nextVocab: () => dispatch(nextVocab()),
        onLoading: () => dispatch(loading()),
        onUpdate: (shouldRepeat) => dispatch(requestUpdateVocabChallengeVocab(shouldRepeat)),
    };
}

function mapStateToProps({ ui, firebase, user, session, challengeVocabReducer }) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        vocabFirst: challengeVocabReducer.vocabFirst,
        vocabSecond: challengeVocabReducer.vocabSecond,
        hiddenNextButton: challengeVocabReducer.hiddenNextButton,
        updateChallengeVocabSuccess: challengeVocabReducer.updateChallengeVocabSuccess,
        currentIndex: challengeVocabReducer.vocabCurrentIndex
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideChoice);
