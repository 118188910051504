
import { combineReducers } from "redux";
import userReducer from "./users";
import { firebaseReducer } from "./firebase";
import uiReducer from "./ui";
import sessionReducer from "./session";
import {coursesReducer} from "./courses";
import newVocabReducer from "../../components/screen/vocab-new/controller/new-reducer";
import repeatVocabReducer from "../../components/screen/vocab-repeat/controller/repeat-reducer";
import challengeVocabReducer from "../../components/screen/vocab-challenge/controller/challenge-reducer";
import loginReducer from "../../components/screen/login/controller/login-reducer";
import registerReducer from "../../components/screen/register/controller/register-reducer";
import courseReducer from "../../components/screen/courses/controller/course-reducer";
import vocabBookReducer from "../../components/screen/vocab-book/controller/vbook-reducer";
import vocabCardReducer from '../../components/common/VocabCard/controller/vcard-reducer';
import homeReducer from "../../components/screen/home/controller/home-reducer";
import logoutReducer from "../../components/screen/logout/controller/logout-reducer";

export default combineReducers({
    user: userReducer,
    ui: uiReducer,
    session: sessionReducer,
    courses: coursesReducer,
    firebase: firebaseReducer,
    newVocabReducer: newVocabReducer,
    repeatVocabReducer: repeatVocabReducer,
    challengeVocabReducer: challengeVocabReducer,
    loginReducer: loginReducer,
    registerReducer: registerReducer,
    courseReducer: courseReducer,
    vocabBookReducer: vocabBookReducer,
    vocabCardReducer: vocabCardReducer,
    homeReducer: homeReducer,
    logoutReducer: logoutReducer
})