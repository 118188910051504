import React from "react";
import Toolbar from "../../../common/toolbar";
import {PURCHASE_PAGE} from "../../../../constants/topics";
import CoursesDetialPurchase from "./detail";

const container = {
    fontFamily: "Prompt, sans-serif",
    textSize : 14,
    height : "100%",
};

class CoursesPurchaseScreen extends React.Component {

    render() {
        return (
            <div style={container}>
                <Toolbar title={PURCHASE_PAGE} history={this.props.history}/>
                <CoursesDetialPurchase />
            </div>
        );
    }

}

export default CoursesPurchaseScreen;