import React from "react";
import {connect} from "react-redux";
import bookIcon from "../../../assets/images/bookIcon@3x.jpg";
import * as ROUTES from "../../../constants/routes";

const titleContainer = {
    flexGrow: 1,
    fontFamily: "Prompt, sans-serif",
    fontWeight: 500,
    color: "#000000",
    fontSize: 17,
    marginLeft: 8
};

const iconStyle = {
    width: "20px",
    height: "26px",
    marginLeft: 4
};

const counterContainer = {
    borderRadius: 23,
    backgroundColor: "#f6cd6e",
    fontFamily: "Prompt, sans-serif",
    fontWeight: 500,
    fontSize: 14,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 12,
    paddingRight: 12,
    marginRight: 4,
    marginLeft: 4,
    color: "#ffffff"
};

class Counter extends React.Component {
    render() {
        return (
            <div style={counterContainer}>
                {this.props.count}
            </div>
        );
    };
}

class SectionVocabs extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
    }

    gotoVocabBook() {
        this.props.history.push(ROUTES.VOCAB_BOOK);
    }

    render() {
        return (
            <button className="vocab-button" onClick={() => this.gotoVocabBook()}>
                <div>
                    <img style={iconStyle} src={bookIcon} alt="vocab"/>
                </div>
                <div style={titleContainer}>
                    สมุดคำศัพท์
                </div>
                <Counter count={this.props.totalVocab}/>
                <i className="material-icons-round md-48">arrow_forward_ios</i>
            </button>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
     };
}


function mapStateToProps({ homeReducer }) {
    return {
        totalVocab: homeReducer.total_vocab
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionVocabs);