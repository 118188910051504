import React from "react";
import {connect} from "react-redux";
import Toolbar from "../../common/progressBarChallenge";
import VocabCard from "../../common/VocabCard/vocabCard";
import FooterNewVocab from "./footerVocab.js";
import Slider from "react-slick";
import bgHeader from '../../../assets/images/bg-header-card3.png';
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";
import ReactAudioPlayer from 'react-audio-player';
import * as ROUTES from "../../../constants/routes";
import {
    resetState,
    fetchNewVocab,
    loadingPage,
    updateVocabResultAPI,
    dispatchVocabResult,
    dispatchSelectedVocab,
    dispatchCloseErrorDialog,
    nextVocab,
} from "./controller/new-action";
import {
    translateFlagN,
} from "../../common/VocabCard/controller/vcard-action";


const bgHeaderStyle = {
    transform: `translate(-16, 0)`,
    position:"fixed",
    zIndex:-1,
    width:"100%",
    height: 312,
    margin : 0,
    padding : 0,
    top : 0,
    left : 0,
};

class NewVocabScreen extends React.Component {

    constructor(props) {
        super(props);

        this.onClickErrorDialog = this.onClickErrorDialog.bind(this);
        this.onNextVocab = this.onNextVocab.bind(this);
        this.handlerGoToLanding = this.handlerGoToLanding.bind(this);
    }

    componentDidMount(){
        this.props.resetState()
        this.props.loadingPage();
        this.props.fetchNewVocab(this.props.match.params);
    }

    componentWillReceiveProps(nextProps, nextState){
        const oldResultVocabLength = this.props.resultVocab.length;
        const newResultVocabLength = nextProps.resultVocab.length;
        const totalVocabLength = nextProps.newVocab.length;
        if(oldResultVocabLength !== newResultVocabLength &&
            newResultVocabLength === totalVocabLength) { // select whole pass vocab
            this.props.loadingPage();
            nextProps.updateVocabResultAPI();
        }

        const selectVocabLength = nextProps.selectedVocab.length;
        const oldSelectedVocabLength = this.props.selectedVocab.length;

        if((oldResultVocabLength !== newResultVocabLength ||
            selectVocabLength !== oldSelectedVocabLength) &&
            (selectVocabLength === 5 ||
             (selectVocabLength + newResultVocabLength === totalVocabLength &&
                selectVocabLength > 0))){ //
                nextProps.history.push({
                pathname: ROUTES.REPEAT_VOCAB,
                state: {
                    selectedVocab : nextProps.selectedVocab,
                    resultVocab : nextProps.resultVocab
                }
            })
        }

        if (this.props.updateVocabResultSuccess !== nextProps.updateVocabResultSuccess &&
            nextProps.updateVocabResultSuccess === true) {
            nextProps.history.push(ROUTES.LANDING);
        }
    }

    handleOnClickNext = () => {
        this.props.dispatchSelectedVocab(this.props.newVocab[this.props.currentIndexVocabCard]);
        this.slider.slickNext()

    };

    handleOnClickPass = () => {
        this.props.dispatchVocabResult({
            vocab_id: this.props.newVocab[this.props.currentIndexVocabCard].vocab_id,
			attemp: 0,
			result: true
        });

        this.slider.slickNext();
    };

    handlerGoToLanding = () => {
        if(this.props.resultVocab.length !== 0){
            this.props.updateVocabResultAPI()
        } else {
            this.props.history.push(ROUTES.COURSES)
        }
    };

    onClickErrorDialog = () => {
        this.props.dispatchCloseErrorDialog();
        this.props.history.goBack();
    };

    onNextVocab() {
        setTimeout(() => {
            this.props.nextVocab();
        }, 350);

        let audio = new Audio(this.props.vocabFirstStack.voice)

        if (this.props.currentIndexVocabCard % 2 !== 0) {
            audio = new Audio(this.props.vocabSecondStack.voice)
        }
        audio.play()
        
        this.props.translateFlagN()
    }

    render() {

        let vocabAudio;
        if (this.props.currentIndexVocabCard === 0) {
            vocabAudio = <ReactAudioPlayer
                src={this.props.vocabFirstStack.voice}
                autoPlay
            />;
        }

        const settings = {
            infinite: true,
            speed: 500,
            adaptiveHeight: true,
            accessibility: false,
            swipe: false,
            arrows : false,
            beforeChange: (old, newIndex) => {
                this.onNextVocab();
            },
        };
        return  (
            <div style={{display: "block"}}>
                <div style={bgHeaderStyle}>
                    <img alt={""} src={bgHeader} style={{width:"inherit",height:"inherit"}}/>
                </div>
                <Toolbar 
                    displayClose="flex"
                    history={this.props.history}
                    handlerGoToLanding={this.handlerGoToLanding}
                    progressBarColor={"white"}
                    closeButtonColor={"white"}
                    percentage={{learn:this.props.learnedVocab,total:this.props.totalVocab}}
                    dialog={{
                        headerMessage:"ยอมแพ้แล้วหรอ ??",
                        bodyMessage:"เหลืออีกแค่นิดเดียวนะ",
                        cancelLabelButton:"ยอมแพ้",
                        submitLabelButton:"สู้ต่อ",
                    }}
                />
                <div style={{height:"700px", overflow: "scroll"}}>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        <VocabCard
                            isHidden="true"
                            data={this.props.vocabFirstStack}
                            style={{display:"inline-block"}}/>
                        <VocabCard
                            isHidden="true"
                            data={this.props.vocabSecondStack}
                            style={{display:"inline-block"}}/>
                    </Slider>
                </div>

                {vocabAudio}
                
                <FooterNewVocab 
                    currentIndexVocabCard={this.props.currentIndexVocabCard}
                    newVocabLength={this.props.newVocab.length}
                    countVocabSelected = {this.props.selectedVocab.length}
                    passSubmitOnClick={this.handleOnClickNext}
                    passCancelOnClick={this.handleOnClickPass}
                    history={this.props.history} />
                <DialogLoadingScreen dialogLoadingOpen={this.props.loadingDialog}/>

                <ConfirmDialog
                    open={this.props.errorDialog.show} 
                    message={this.props.errorDialog.message}
                    positiveText={"ตกลง"}
                    onPositive={this.onClickErrorDialog}
                />
            </div>
        )
        
    }

}
function mapDispatchToProps(dispatch) {
    return {
        loadingPage: () => dispatch(loadingPage()),
        fetchNewVocab: (course) => dispatch(fetchNewVocab(course)),
        dispatchVocabResult: (vocab) => dispatch(dispatchVocabResult(vocab)),
        dispatchSelectedVocab: (vocab) => dispatch(dispatchSelectedVocab(vocab)),
        updateVocabResultAPI: () => dispatch(updateVocabResultAPI()),
        dispatchCloseErrorDialog: () => dispatch(dispatchCloseErrorDialog()),
        nextVocab: () => dispatch(nextVocab()),
        resetState: () => dispatch(resetState()),
        translateFlagN: () => dispatch(translateFlagN()),
     };
}

function mapStateToProps({ui, firebase, user, session, newVocabReducer}) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        newVocab : newVocabReducer.newVocab,
        resultVocab : newVocabReducer.resultVocab,
        loadingDialog : newVocabReducer.loadingDialog,
        learnedVocab : newVocabReducer.learnedVocab,
        totalVocab : newVocabReducer.totalVocab,
        selectedVocab : newVocabReducer.selectedVocab,
        errorDialog : newVocabReducer.errorDialog,
        currentIndexVocabCard : newVocabReducer.currentIndexVocabCard,
        vocabFirstStack: newVocabReducer.vocabFirstStack,
        vocabSecondStack: newVocabReducer.vocabSecondStack,
        updateVocabResultSuccess: newVocabReducer.updateVocabResultAPI
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVocabScreen);