
import * as CON from "./const-vcard";

export function onCloseDialog() {
    return {
        type : CON.CLOSE_DIALOG,
    }
}


export function googleTextToSpeech(vocab) {
    return function(dispatch, getState) {

    }
}

export function translateFlag() {
    return {
        type : CON.TRANSLATE_FLAG,
    }
}

export function translateFlagN() {
    return {
        type : CON.TRANSLATE_FLAG_N,
    }
}