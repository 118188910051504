import React from "react";
import { Grid, IconButton, Box } from "@material-ui/core";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { helpIconStyle, AlohaToolTip } from "./style";
import { connect } from "react-redux";
import { homeToolTip } from "./controller/home-controller";
import Zoom from '@material-ui/core/Zoom';

class CounterDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.handleTooltip = this.handleTooltip.bind(this);
        this.handleToolTipClose = this.handleToolTipClose.bind(this);
        this.handleToolTipOpen = this.handleToolTipOpen.bind(this);
    }

    handleTooltip(toolTipNo) {
        this.props.homeToolTip(toolTipNo);
    }

    handleToolTipClose() {
        this.setState({
            open: false
        });
    }

    handleToolTipOpen() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        return (
            <Grid item xs={4} justify="center" direction="column" alignItems="center" container>
                <Grid
                    alignItems="center"
                    justify="center"
                    direction="row"
                    container>
                    <div className="counterDetail">{this.props.title}</div>
                    <Box ml={0.2} display="flex" justifyContent="flex-start">
                        <AlohaToolTip
                            title={this.props.tip}
                            placement="top-start"
                            open={this.state.open}
                            onClose={() => this.handleToolTipClose()}
                            // PopperProps={{
                            //     disablePortal: true,
                            // }}
                            disableFocusListener
                            disableHoverListener
                            TransitionComponent={Zoom}
                            arrow>
                            <IconButton aria-label="help" size="small" onClick={() => this.handleToolTipOpen()}>
                                <HelpOutlineOutlinedIcon style={helpIconStyle} />
                            </IconButton>
                        </AlohaToolTip>
                    </Box>
                </Grid>
                <div className="count">{this.props.total}</div>
            </Grid>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        homeToolTip: (toolTipNo) => dispatch(homeToolTip(toolTipNo))
    }
}

function mapStateToProps({ homeReducer }) {
    return {
        toggleToolTip: homeReducer.toggleToolTip

    }
}



export default connect(mapStateToProps, mapDispatchToProps)(CounterDetail);