import React from "react";
import { cardTitle, cardContainer } from "../styles";
import ItemCourseVocab from "./item-course-vocab";


export default function CourseCardGroup(props) {
    if (props.list.length === 0) {
        return <div/>
    }

    return (
        <div style={cardContainer}>
            <div style={cardTitle}>
                {props.title}
                {   props.list.map((vocab) => {
                    return <ItemCourseVocab key={vocab.id} vocab={vocab}/>
                })}
            </div>
        </div>
    );

}