import React from "react";
import { connect } from "react-redux";
import checkIconPng from "../../../assets/images/checkIcon@3x.png";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
    updateCheckedType,
} from "./controller/vbook-action";
import "./filterPage.css";

// const fontHeader = {
//     color : `#717479`,
//     fontSize : 18,
// }

const detailButtonStyle = {
    width: "100%",
    padding: 5,
    margin: 0,
    background: "none",
    border: "none",
    textAlign: "left",
    justifyContent: "left",
    textTransform: "none",
}

const fontVocab = {
    fontFamily: "Prompt, sans-serif",
    fontSize: 16,
    color: "#000000",
}

class CheckBoxBookScreen extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    onCloseDrawer() {
    }

    handleToggleCheckedType(index) {
        this.props.updateCheckedType(index, this.props.flagCheck);
    }

    render() {
        return (
            <div>
                {
                    this.props.typeGroup.map((data, index) => {
                        return <div style={{ width: "100%", display: "flex", paddingLeft: 10 }}>
                            <img src={checkIconPng} style={{
                                width: 14,
                                height: 13,
                                margin: "auto",
                                visibility: data.check ? "visible" : "hidden",

                            }} />
                            <Button
                                onClick={() => this.handleToggleCheckedType(index)}
                                style={detailButtonStyle}>
                                <div>
                                    <Typography variant="body2" style={fontVocab}>
                                        {data.type}
                                    </Typography>
                                </div>
                            </Button>
                        </div>
                    })
                }
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        // fetchCourseList: (token) => dispatch(fetchCourseList(token)),
        updateCheckedType: (index, flagCheck) => dispatch(updateCheckedType(index, flagCheck)),
    };
}

function mapStateToProps({ vocabBookReducer }) {
    return {
        // checkedType: vocabBookReducer.checkedType
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckBoxBookScreen);