export const ACTION_MENU_CLICK = "action_menu_click";

// action for auth
export const ACTION_LOGIN_WITH_GOOGLE = "action_login_with_google";
export const ACTION_LOGIN_WITH_FACEBOOK = "action_login_with_facebook";
export const ACTION_LOGOUT_SUCCESS = "action_logout_success";
export const ACTION_LOGOUT_FAILED = "action_logout_failed";
export const ACTION_LOGIN_SUCCESS = "action_login_success";
export const ACTION_GET_USER_FROM_DB = "action_get_user_from_db";
export const ACTION_UPDATE_USER_DB_SUCCESS = "action_update_user_db_success";
export const ACTION_UPDATE_USER_DB_FAILED = "action_update_user_db_failed";
export const ACTION_CLEAR_USER_STATE = "action_clear_user_state";

export const UPDATE_USER_STATE = "update_user_state";
export const VALIDATE_EMAIL_STATE = "validate_email_state";
export const GET_USER_COURSE = "get_user_course";
export const GET_ALL_COURSE = "get_all_course";
export const VALIDATE_FORMAT_EMAIL_STATE = "validate_format_email_state";
export const GET_COURSE_BY_ID = "get_course_by_id";
export const GET_COURSE_BY_ID_ERROR = "get_course_by_id_error";

export const FETCH_COURSE_LIST = "fetch_course_list";
export const FETCH_COURSE_DETAIL = "fetch_course_detail";

export const FETCH_VOCAB_SUBCOURSE = "fetch_vocab_subcourse";
export const SELECTED_VOCAB_SUBCOURSE = "selected_vocab_subcourse";
export const SELECTED_VOCABCARD = "selected_vocabcard";
export const STORE_VOCAB_RESULT = "store_vocab_result";
export const ANSWER_VOCAB = "answer_vocab";
export const RESET_STATE_VOCAB = "reset_state_vocab";
export const UPDATE_VOCAB_RESULT_DB_SUCCESS = "update_vocab_result_db_success";
export const UPDATE_VOCAB_RESULT_DB_FAILED = "update_vocab_result_db_failed";

