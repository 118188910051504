import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import VocabCard from "../VocabCard/vocabCard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogVocabCardScreen extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.dialogOpen}
                onClose={this.props.handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        width : "100%",
                        borderRadius: 20,
                    },
                  }}
            >
                <VocabCard data={this.props.data} onClose={this.props.handleDialogClose}/>
            </Dialog>
        );
    }
}

export default DialogVocabCardScreen;