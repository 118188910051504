export const containerSummary = {
    display: "block",
    borderRadius: `10px`,
    boxShadow: `0 2px 20px 0 rgba(0, 64, 128, 0.1)`,
    backgroundColor: "#ffffff",
    padding : "5%",
    marginTop : "6%",
};

export const container = {
    color : "#2d3142",
    height : "100%",
};

export const detail = {
    paddingLeft : "7%",
    paddingRight : "7%",
    height : "100%",
};

export const topics = {
    color : "#006b6d",
    marginTop : 25,
}

export const imgs1 = {
    height : 179,
    width : 170,
};

export const fileNameHidden = {
    width: 0,
    height: 0,
    opacity: 0,
    overflow : 'hidden',
    position : 'relative',
    float: "left",
    display : "none"
}

export const fileName = {
    overflow : 'hidden',
    position : 'relative',
    float: "left",
    display : "block"
}

export const deleteButtonHidden = {
    width: 0,
    height: 0,
    opacity: 0,
    overflow : 'hidden',
    position:"relative",
    float: "right",
    display : "none"
}

export const deleteButton = {
    overflow : 'hidden',
    position:"relative",
    float: "right",
    display : "block"
}

export const hidden = {
    height : 0,
    width : 0,
    padding : 0,
    margin : 0,
    display : "none"
};

export const common = {
    height : "100%",
    width : "100%",
    padding : 0,
    margin : 0,
    display : "block"
}

export const topicWaiting = {
    marginTop : 25,
    fontSize : 16,
}

export const contentWaiting = {
    fontSize : 14,
}

export const fonts = {
    fontFamily: "Prompt, sans-serif",
    textSize : 14,
}

export const uploadButton = {
    marginTop : 4,
    fontFamily: "Prompt, sans-serif",
    textSize : 14,
    color:"#888992"
}

export const ButtonBottomPosition = {
    backgroundColor : "white",
    position: "fixed",
    width: "100%",
    height : 50,
    margin : "auto",
    paddingTop : 10,
    paddingBottom : 10,
    bottom: 25,
    left: "50%",
    transform: `translate(-50%)`,
    textAlign : "center"
}
