import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import GoogleIcon from '../../../assets/images/google.svg';
import { connect } from 'react-redux';
import {loginWithGoogle} from "./controller/login-controller";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#c43030",
        borderRadius: 23,
        textTransform: 'none',
        width: 325,
        height: 40,
        paddingLeft: 70,
        paddingRight: 70, 
        fontSize:14,
        boxShadow: 'none',
        fontFamily: 'Prompt, sans-serif',
		fontWeight: "400",
        '&:hover': {
        backgroundColor: '#c43030',
        borderColor: '#0062cc',
        boxShadow: 'none',
        },
    },
}))(Button);

function GoogleButtons(props) {
    const classes = useStyles();
    const svgIcon = (
        <Icon>
            <img alt="edit" 
                src={GoogleIcon} 
                style={{width:14,height:14,marginBottom:"25%"}}
            />
        </Icon>
    );

    return (
        <div>
        <ColorButton
            variant="contained"
            size="large"
            className={classes.Button}
            startIcon={svgIcon}
            onClick={() => props.onLogin()}
        >
            เข้าสู่ระบบด้วย Google
        </ColorButton>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        firebase: state.firebase.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLogin: () => dispatch(loginWithGoogle())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleButtons);