import React from "react";
import ItemSubCourse from "./item-sub-course";

const container = {
    boxShadow: "0 2px 20px 0 rgba(0, 64, 128, 0.1)",
    display: "block",
    borderRadius: 20,
    paddingBottom: 16,
    marginBottom: 16
};

class ItemOwnCourse extends React.Component {

    constructor(props) {
        super(props);
        this.gotoCourseDetail = this.gotoCourseDetail.bind(this);
    };

    gotoCourseDetail() {
        this.props.history.push("/course/" + this.props.course.course_id);
    }

    render() {
        return (
            <div style={container}>
                <button className="header-button" onClick={this.gotoCourseDetail}>
                    {this.props.course.course_title}
                </button>
                { this.props.course.sub_courses.map((subCourses, i) => {
                    return <ItemSubCourse key={i} ownCourse={this.props.course} subCourse={subCourses}/>;
                })}
            </div>
        );
    }
}

export default ItemOwnCourse;