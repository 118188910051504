import React from "react";
import { Grid, Box, Collapse, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import { IconButton } from "material-ui";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MenuHidden from "./menu-hidden";
import { menuClick } from "../../../state/action/ui-action";
import * as ROUTES from "../../../constants/routes";
import Zoom from '@material-ui/core/Zoom';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { helpIconStyle, AlohaToolTip } from "./style";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import iconHelp from "../../../assets/images/help_outline_black.svg";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.gotoLogout = this.gotoLogout.bind(this);
        this.gotoQuestion = this.gotoQuestion.bind(this);
        this.gotoNotify = this.gotoNotify.bind(this);

        this.state = {
            open: false
        }

        this.handleToolTipClose = this.handleToolTipClose.bind(this);
        this.handleToolTipOpen = this.handleToolTipOpen.bind(this);
    }

    handleToolTipClose() {
        this.setState({
            open: false
        });
    }

    handleToolTipOpen() {
        this.setState({
            open: true
        });
    }

    gotoLogout() {
        this.props.history.push(ROUTES.LOGOUT);
    }

    gotoQuestion() {
        this.props.history.push(ROUTES.QUESTION)
    }

    gotoNotify() {
        this.props.history.push(ROUTES.NOTIFY);
    }

    render() {

        let colorVocab = "#e9446a";
        let colorRank = "#e9446a";
        let colorLogin = "#e9446a";
        let vocabCount = this.props.master_vocab;
        let rankCount = this.props.rank;
        let loginCount = this.props.levelInfo.login;

        if (this.props.levelInfo.vocab_pass === true) {
            colorVocab = "#006b6d";
            vocabCount = this.props.levelInfo.next_require_vocab;
        }
        if (this.props.levelInfo.rank_pass === true) {
            colorRank = "#006b6d";
            rankCount = this.props.levelInfo.next_require_rank;
        }
        if (this.props.levelInfo.login_pass === true) {
            colorLogin = "#006b6d";
            loginCount = this.props.levelInfo.next_require_login;
        }

        const content = <div style={{padding: 8, textAlign: "center"}}>
                            Level ของคุณคือ {this.props.levelInfo.level} <br/>
                            ทำภารกิจเพื่ออัพ Level <br/>
                            จำแม่น {vocabCount}/{this.props.levelInfo.next_require_vocab} คำ <br/>
                            อันดับ {rankCount}/{this.props.levelInfo.next_require_rank} <br/>
                            เช็คอิน {loginCount}/{this.props.levelInfo.next_require_login} วัน
                        </div>

        let menu = <MenuRoundedIcon/>;
        if (this.props.isMenuOpen) {
            menu = <CloseRoundedIcon/>
        }

        return (
            <Box width={1} display="flex" pt={4} style={{paddingBottom: 10}} >
                <div style={{width: "48px", height:"48px"}}/>
                <Box flexGrow={1}>
                    <Grid direction="column" container style={{whiteSpace: "nowrap"}}>
                        <div className="header">Hi, {this.props.name} !</div>
                        <Grid
                            alignItems="center"
                            justify="center"
                            direction="row"
                            container>
                                {/* <div className="regular" style={{fontSize: 11}}>
                                    ภารกิจ (จำแม่น <span style={{color: colorVocab}}>{vocabCount} </span> คำ | 
                                    อันดับ <span style={{color: colorRank}}>{rankCount}</span> | 
                                    เช็คอิน <span style={{color: colorLogin}}>{loginCount} </span> วัน)
                                </div> */}

                                <div className="regular" style={{fontSize: 11}}>
                                    Level {this.props.levelInfo.level} : เช็คอิน <span style={{color: colorLogin}}>{loginCount}/{this.props.levelInfo.next_require_login} </span> วัน จำแม่น <span style={{color: colorVocab}}>{vocabCount}/{this.props.levelInfo.next_require_vocab} </span> คำ <br/>
                                    และอันดับ <span style={{color: colorRank}}>{rankCount}/{this.props.levelInfo.next_require_rank}</span> พิชิตภารกิจนี้เพื่อไปต่อ
                                </div>
                                
                                {/* <ClickAwayListener onClickAway={() => this.handleToolTipClose()}>
                                    <div>
                                        <AlohaToolTip
                                            PopperProps={{
                                            disablePortal: true,
                                            }}
                                            onClose={() => this.handleToolTipClose()}
                                            open={this.state.open}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            TransitionComponent={Zoom}
                                            title={content}
                                            arrow>
                                            <div onClick={() => this.handleToolTipOpen()}><img src={iconHelp} style={{width: 12, height:12, marginLeft: 4}}/></div>
                                        </AlohaToolTip>
                                    </div>
                                </ClickAwayListener> */}
                            </Grid>
                        <Collapse in={this.props.isMenuOpen}>
                            <Box p={3}>
                                <Grid direction="column" container>
                                    <Divider/>
                                    <MenuHidden name="การแจ้งเตือน" onClick={this.gotoNotify}/>
                                    <Divider/>
                                    <MenuHidden name="คำถามที่พบบ่อย" onClick={this.gotoQuestion}/>
                                    <Divider/>
                                    <MenuHidden name="ออกจากระบบ" onClick={this.gotoLogout}/>
                                    <Divider/>
                                </Grid>
                            </Box>
                        </Collapse>
                    </Grid>
                </Box>
                <Box>
                    <IconButton style={{float: "right"}} onClick={() => this.props.menuClick()}>
                        {menu}
                    </IconButton>
                </Box>
            </Box>
        );
    }

}

function mapStateToProps(state) {
    return {
        isMenuOpen: state.ui.isMenuOpen,
        name: state.user.username,
        email: state.user.email,
        master_vocab: state.homeReducer.master_vocab,
        rank: state.homeReducer.challenge.user_rank,
        levelInfo: state.homeReducer.level_info,
        firebase: state.firebase.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        menuClick: () => dispatch(menuClick())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);