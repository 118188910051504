import React from "react";
import { bottomContainer, bottomContainerPadding } from "../styles";
import { Container } from "@material-ui/core";

export function ActionBottomButton(props) {
    return (
        <div style={bottomContainer}>
            <Container maxWidth="sm">
                <div style={bottomContainerPadding}>
                    <button onClick={props.onClick} className="bottom-button">
                        {props.name}
                    </button>
                </div>
            </Container>
        </div>
    );

}