export const COURSE_ACTION = {
    LOADING: "loading",
    GET_COURSE_LIST_SUCCESS: "get_course_list_success",
    GET_COURSE_FAILED_UNAUTHORIZED: "get_course_failed_unauthorized",
    CLOSE_DIALOG: "close_dialog",
    GET_COURSE_FAILED: "get_course_failed",
    GET_COURSE_DETAIL_SUCCESS: "get_course_detail_success",
    REGISTER_COURSE_FAILED: "register_course_failed",
    REGISTER_COURSE_SUCCESS: "register_course_success",
    REGISTER_COURSE_FINISHED: "register_course_finished"
};

const initialState = {
    loading: false,
    userCourse: [],
    allCourse: [],
    courseSuccessful: {
        course_id: "",
        course_title: "",
        summary: "",
        thumbnail: "",
        price: "",
        special_price: "",
        available_day: 180,
        user_count: 0,
        subcourse: [],
    },
    courseDetail: {
        status_code: "",
        status_message: "",
        course_id: "",
        course_title: "",
        course_description: "",
        cover: "",
        total_student: 0,
        total_vocab: 0,
        available_day: 0,
        price: "",
        special_price: "",
        status: "",
        expired_at: null,
        expiredAt: "",
    },
    shouldInvalidateToken: false,
    error: {
        show: false,
        message: ""
    },
    dialog: {
        show: false,
        message: ""
    }
};

export default function courseReducer(state = initialState, { type, payload }) {
    switch (type) {
        case COURSE_ACTION.GET_COURSE_LIST_SUCCESS: {
            return handleGetCourseListSuccess(state, payload)
        }
        case COURSE_ACTION.LOADING: {
            return handleLoading(state, true);
        }
        case COURSE_ACTION.GET_COURSE_FAILED_UNAUTHORIZED: {
            return handleUnauthorized(state);
        }
        case COURSE_ACTION.CLOSE_DIALOG: {
            return handleCloseDialog(state);
        }
        case COURSE_ACTION.GET_COURSE_FAILED: {
            return handleGetCourseListFailed(state, payload);
        }
        case COURSE_ACTION.GET_COURSE_DETAIL_SUCCESS: {
            return handleGetCourseDetailSuccess(state, payload);
        }
        case COURSE_ACTION.REGISTER_COURSE_SUCCESS: {
            return handleRegisterCourseSuccess(state);
        }
        case COURSE_ACTION.REGISTER_COURSE_FAILED: {
            return handleRegisterCourseFailed(state, payload);
        }
        case COURSE_ACTION.REGISTER_COURSE_FINISHED: {
            return handleRegisterCourseFinished(state);
        }
        default: {
            return {
                ...state
            };
        }
    }
}

function handleGetCourseListSuccess(state, payload) {
    return {
        ...state,
        loading: false,
        userCourse: payload.user_course,
        allCourse: payload.all_course,
        courseSuccessful: payload.courseSuccessful,
        courseDetail: {
            status_code: "",
            status_message: "",
            course_id: "",
            course_title: "",
            course_description: "",
            cover: "",
            total_student: 0,
            total_vocab: 0,
            available_day: 0,
            price: "",
            special_price: "",
            status: "",
            expired_at: null,
            expiredAt: "",
        },
    }
}

function handleGetCourseDetailSuccess(state, payload) {
    let expiredDateFormat = "";
    const course = payload.course;
    if (course.status === "active") {
        const expiredDate = new Date(course.expired_at._seconds * 1000);
        expiredDateFormat = formatTwoDigit(expiredDate.getDate()) + "/" + formatTwoDigit(expiredDate.getMonth() + 1) + "/" + (expiredDate.getFullYear() + 543 + "").substring(2);

    }
    return {
        ...state,
        loading: false,
        courseDetail: {
            ...payload.course,
            expiredAt: expiredDateFormat
        }
    }
}

function handleLoading(state, loading) {
    return {
        ...state,
        loading: loading
    }
}

function handleUnauthorized(state) {
    return {
        ...state,
        loading: false,
        shouldInvalidateToken: true,
        error: {
            show: true,
            message: "เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองใหม่อีกครั้ง"
        }
    }
}

function handleCloseDialog(state) {
    return {
        ...state,
        loading: false,
        shouldInvalidateToken: false,
        error: {
            show: false,
            message: ""
        }
    }
}

function handleGetCourseListFailed(state, payload) {
    return {
        ...state,
        loading: false,
        shouldInvalidateToken: false,
        error: {
            show: true,
            message: payload.message
        },
        
    }
}

function handleRegisterCourseSuccess(state) {
    return {
        ...state,
        loading: false,
        dialog: {
            show: true,
            message: "สมัครคอร์สสำเร็จ"
        }
    }
}

function handleRegisterCourseFailed(state, payload) {
    return {
        ...state,
        loading: false,
        error: {
            show: true,
            message: payload.message
        }
    }
}

function handleRegisterCourseFinished(state) {
    return {
        ...state,
        loading: false,
        error: {
            show: false,
            message: ""
        },
        dialog: {
            show: false,
            message: ""
        },
        courseSuccessful: {
            course_id: "",
            course_title: "",
            summary: "",
            thumbnail: "",
            price: "",
            special_price: "",
            available_day: 180,
            user_count: 0,
            subcourse: [],
        },
        courseDetail: {
            status_code: "",
            status_message: "",
            course_id: "",
            course_title: "",
            course_description: "",
            cover: "",
            total_student: 0,
            total_vocab: 0,
            available_day: 0,
            price: "",
            special_price: "",
            status: "",
            expired_at: null,
            expiredAt: "",
        }
    }
}

function formatTwoDigit(num) {
    return ("0" + num).slice(-2)
}