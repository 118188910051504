import {LOGIN_ACTION} from "./login-reducer";
import { env } from "../../../../constants/config";
import {PENDING_LOGIN} from "../../../../constants/storage";

export function loginWithGoogle() {
    return (dispatch, getState) => {
        window.sessionStorage.setItem(PENDING_LOGIN, "true");
        const { app } = getState().firebase;
        const provider = new app.auth.GoogleAuthProvider();
        app.auth().signInWithRedirect(provider);
        dispatch({
            type: LOGIN_ACTION.LOGIN_REQUEST
        });
    }
}

export function loginWithFacebook() {
    return (dispatch, getState) => {
        window.sessionStorage.setItem(PENDING_LOGIN, "true");
        const { app } = getState().firebase;
        const provider = new app.auth.FacebookAuthProvider();
        app.auth().signInWithRedirect(provider);
        dispatch({
            type: LOGIN_ACTION.LOGIN_REQUEST
        });
    }
}

export function loginFailed() {
    return {
        type: LOGIN_ACTION.LOGIN_FAILED
    }
}

export function getUser(token) {
    return (dispatch, getState) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": 'application/json; charset=UTF-8',
                "Authorization": 'Bearer ' + token,
                "Cache-Control": 'no-cache'
            }
        };
        fetch(env.host + "/apiAsia/user/getuser", requestOptions)
            .then((response) => response.json())
            .then((body) => {
                if (body.status_code === "00") {
                    dispatch({
                        type: LOGIN_ACTION.GET_USER_SUCCESS,
                        payload: {
                            username: body.username,
                            email: body.email
                        }
                    });
                } else if (body.status_code === "001") {
                    dispatch({
                        type: LOGIN_ACTION.GET_USER_UNREGISTERED,
                        payload: {
                            username: body.username,
                            email: body.email,
                            register_token: body.register_token
                        }
                    });
                } else {
                    dispatch({
                        type: LOGIN_ACTION.GET_USER_FAILED,
                        payload: {
                            message: body.status_message,
                            showing: true
                        }
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: LOGIN_ACTION.GET_USER_FAILED,
                    payload: {
                        message: error,
                        showing: true
                    }
                });
            })
    }
}

export function clearState() {
    return {
        type: LOGIN_ACTION.CLEAR_STATE
    }
}