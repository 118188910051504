export const SETUP_SELECTED_VOCAB = "setup_selected_vocab";
export const NEXT_VOCAB = "next_vocab";
export const IS_HIDDEN_NEXT_BUTTON = "is_hidden_next_button";
export const SUBMIT_ANSWER = "submit_answer";
export const UPDATE_REPEAT_VOCAB_SUCCESS = "update_repeat_vocab_success";
export const UPDATE_REPEAT_VOCAB_ERROR = "update_repeat_vocab_error";
export const LOADING_REPEAT_VOCAB = "loading_repeat_vocab";
export const CLOSE_REPEAT_DIALOG = "close_repeat_dialog";
export const CLEAR_VOCAB_STATE = "clear_vocab_state";
export const COUNT_LOOP_REPEAT = "count_loop_repeat";

export const FETCH_VOCAB_REPEAT_SUCCESS = "fetch_vocab_repeat_success";
export const FETCH_VOCAB_REPEAT_FAIL = "fetch_vocab_repeat_fail";

export const SET_AUDIO = "set_audio";
export const SET_FIRST_TIME = "set_first_time";
export const SET_REPEAT = "set_repeat";
export const SET_CUSTOM_VOCAB_ANSWER = "set_custom_vocab_answer";