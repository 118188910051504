import React from "react";
import ReactLoading from "react-loading";

const loadingStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-50px"
}

export class LoadingScreen extends React.Component {

    render() {
        return (
            <div style={loadingStyle}>
                <ReactLoading type={"spin"} color={"#e9446a"} height={100} width={100} />
            </div>
        );
    }

}