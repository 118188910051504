export const REGISTER_ACTION = {
    SETUP_REGISTER_DETAIL: "setup_register_detail",
    VALIDATE_EMAIL: "validate_email",
    VALIDATE_USERNAME: "validate_username",
    LOADING: "loading",
    REGISTER_SUCCESS: "register_success",
    REGISTER_FAILED_DUPLICATE_EMAIL: "register_failed_duplicate_email",
    REGISTER_FAILED: "register_failed",
    CLOSE_DIALOG: "close_dialog"
};

const initialState = {
    loading: false,
    email_invalid: false,
    username_invalid: false,
    error_email_message: "",
    error_username_message: "",
    register_detail: {
        username: "",
        email: ""
    },
    dialog_message: {
        show: false,
        message: ""
    },
    dialog_message_fail: {
        show: false,
        message: ""
    }
};

export default function registerReducer(state = initialState, { type, payload }) {
    switch (type) {
        case REGISTER_ACTION.SETUP_REGISTER_DETAIL: {
            return handleSetupRegisterDetail(state, payload.register_detail);
        }
        case REGISTER_ACTION.VALIDATE_EMAIL: {
            return handleValidateEmail(state, payload);
        }
        case REGISTER_ACTION.VALIDATE_USERNAME: {
            return handleValidateUsername(state, payload);
        }
        case REGISTER_ACTION.LOADING: {
            return handleLoading(state, true)
        }
        case REGISTER_ACTION.REGISTER_SUCCESS: {
            return handleRegisterSuccess(state);
        }
        case REGISTER_ACTION.REGISTER_FAILED_DUPLICATE_EMAIL: {
            return handleDuplicateEmail(state, payload.message);
        }
        case REGISTER_ACTION.REGISTER_FAILED: {
            return handleRegisterFailed(state, payload.message);
        }
        case REGISTER_ACTION.CLOSE_DIALOG: {
            return handleCloseDialog(state);
        }
        default: {
            return {
                ...state
            };
        }
    }
}

function handleSetupRegisterDetail(state, detail) {
    let emailInvalid = false;
    let errorMessage = "กรุณากรอกอีเมล์"
    if (detail.email === undefined || detail.email === "") {
        emailInvalid = true;
        errorMessage = "กรุณากรอกอีเมล์"
    }
    return {
        ...state,
        register_detail: {
            ...detail,
        },
        email_invalid: emailInvalid,
        error_email_message: errorMessage
    }
}

function handleValidateEmail(state, payload) {
    let errorMessage = "";
    if (!payload.email_valid) {
        errorMessage = "กรุณากรอกอีเมล์ให้ถูกต้อง";
    }
    if (payload.email === "") {
        errorMessage = "กรุณากรอกอีเมล์"
    }
    return {
        ...state,
        email_invalid: !payload.email_valid,
        error_email_message: errorMessage,
        register_detail: {
            ...state.register_detail,
            email: payload.email
        }
    }
}

function handleValidateUsername(state, payload) {
    let errorMessage = "";
    let invalid = false;
    if (payload.username === "") {
        errorMessage = "กรุณากรอกชื่อผู้ใช้งาน";
        invalid = true;
    }
    return {
        ...state,
        error_username_message: errorMessage,
        username_invalid: invalid,
        register_detail: {
            ...state.register_detail,
            username: payload.username
        }
    }
}

function handleLoading(state, loading) {
    return {
        ...state,
        loading: loading
    }
}

function handleRegisterSuccess(state) {
    return {
        ...state,
        loading: false,
        dialog_message: {
            show: true,
            message: "การสมัครสำเร็จ"
        }
    }
}

function handleDuplicateEmail(state, message) {
    return {
        ...state,
        loading: false,
        error_email_message: message,
        email_invalid: true
    }
}

function handleRegisterFailed(state, message) {
    return {
        ...state,
        loading: false,
        dialog_message: {
            show: false,
            message: ""
        },
        dialog_message_fail: {
            show: true,
            message: message
        }
    }
}

function handleCloseDialog(state) {
    return {
        ...state,
        dialog_message: {
            show: false,
            message: ""
        },
        dialog_message_fail: {
            show: false,
            message: ""
        }
    }
}