import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { headerText, mainContainer, menuContainer, menuDescriptionText, menuNotifyText, radioGroupContainer, radioTextContainer, buttonContainer } from "./style";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import iconSun from "../../../assets/images/sun@3x.png";
import iconMoon from "../../../assets/images/moon@3x.png";
import queryString from 'query-string';
import LaterButton from "./component/button-later";
import SubmitButton from "./component/button-submit";
import { LANDING } from "../../../constants/routes";
import { disableNotify, enableNotify, generateLineNotifyURL, getNotifyInfo } from "../../../client/api-client";
import DialogLoadingScreen from "../../common/Dialogs/dialogLoading";
import ConfirmDialog from "../../common/Dialogs/confirm-dialog";

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 38,
        height: 22,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
            backgroundColor: '#005b8e',
            opacity: 1,
            border: 'none',
        },
        },
        '&$focusVisible $thumb': {
        color: '#52d869',
        border: '2px solid #fff',
        },
    },
    thumb: {
        width: 20,
        height: 20,
    },
    track: {
        borderRadius: 22 / 2,
        backgroundColor: "#9b9b9b",
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    }))(({ classes, ...props }) => {
    return (
        <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
        }}
        {...props}
        />
    );
});

function NotifyScreen(props) {

    const [notify, setNotify] = useState(false);
    const [time, setTime] = useState("0");
    const [loading, setLoading] = useState(false);
    const [ isInitial, setInitial ] = useState(false);
    const [ errorDialog, setErrorDialog ] = useState({
        open: false,
        statusCode: "",
        statusMessage: ""
    });

    useEffect(() => {
        if (isInitial === false) {
            setLoading(true);
            setInitial(true);
            fetchNotifyInfo();
        }
    }, []);

    const fetchNotifyInfo = () => {
        getNotifyInfo(props.token, 
        (response) => {
            setLoading(false);
            setNotify(response.enable);
            setTime(response.time+"");
            const code = queryString.parse(props.location.search).code;
            if (code !== undefined) {
                setNotify(true);
            }
        },
        (error) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: error.statusCode,
                statusMessage: error.statusMessage
            });
        });
    }

    const labelMorning = (
        <div style={radioTextContainer}> <img src={iconSun} alt="sun" style={{width:16, height: 16, paddingRight: 8}}/> ตอนตื่น (06:30 - 07.30 น.)</div>
    );

    const labelNight = (
        <div style={radioTextContainer}> <img src={iconMoon} alt="moon" style={{width:16, height: 16, paddingRight: 8}}/> ก่อนนอน (20:30 - 21.30 น.)</div>
    );

    const handleEnableNotify = (event) => {
        if (event.target.checked === true) {
            onGenerateURL();
        } else {
            onDisableNotify();
        }
        
    }

    const gotoMain = () => {
        props.history.push(LANDING);
    }

    const onCloseErrorDialog = () => {
        setErrorDialog({
            open: false,
            statusCode: "",
            statusMessage: ""
        });
    }

    const onGenerateURL = () => {
        setLoading(true);
        generateLineNotifyURL(props.token, 
        (response) => {
            setLoading(false);
            const url = response.url;
            window.location = url;
        }, 
        (error) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: error.statusCode,
                statusMessage: error.statusMessage
            });
        });
    }

    const onDisableNotify = () => {
        setLoading(true);
        disableNotify(props.token, 
        (response) => {
            setLoading(false);
            setNotify(false);
        }, 
        (error) => {
            setLoading(false);
            setErrorDialog({
                open: true,
                statusCode: error.statusCode,
                statusMessage: error.statusMessage
            });
        });
    }

    const onSubmit = () => {
        setLoading(true);
        const code = queryString.parse(props.location.search).code;
        if (code !== undefined && notify === true) {
            enableNotify(props.token, code, time,
            (response) => {
                setLoading(false);
                gotoMain();
            }, 
            (error) => {
                setLoading(false);
                setErrorDialog({
                    open: true,
                    statusCode: error.statusCode,
                    statusMessage: error.statusMessage
                });
            });
        } else if (notify === true) {
            enableNotify(props.token, "", time,
                (response) => {
                    setLoading(false);
                    gotoMain();
                }, 
                (error) => {
                    setLoading(false);
                    setErrorDialog({
                        open: true,
                        statusCode: error.statusCode,
                        statusMessage: error.statusMessage
                    });
                });
        }
    }

    let radioTime = null;
    let button = (<LaterButton textButton={"ไว้ทีหลัง"} onClick={() => gotoMain()}/>);
    if (notify === true) {
        radioTime = (
            <RadioGroup name="time" value={time} onChange={(event) => setTime(event.target.value)}>
                <FormControlLabel value={"0"} control={<Radio />} label={labelMorning} />
                <FormControlLabel value={"1"} control={<Radio />} label={labelNight} />
            </RadioGroup>
        );
        button = (<SubmitButton textButton={"ยืนยัน"} onClick={() => onSubmit()}/>);
    } 

    return (
        <div style={mainContainer}>
            <div style={headerText}>
                การแจ้งเตือน <br/>
                (ทบทวนศัพท์)
            </div>
            <div style={menuContainer}>
                <div style={menuNotifyText}>การแจ้งเตือน (Line Notify)</div>
                <IOSSwitch checked={notify} onChange={handleEnableNotify} name="notify" />
            </div>
            <div style={menuDescriptionText}>
                เราจะแจ้งเตือนเมื่อถึงเวลาทบทวนเท่านั้น <br/>
                เพื่อให้การจำศัพท์มีประสิทธิภาพมากที่สุด
            </div>
            <div style={radioGroupContainer}>
                {radioTime}
            </div>
            <div style={buttonContainer}>
                {button}
            </div>
            <ConfirmDialog
                open={errorDialog.open}
                message={errorDialog.statusMessage}
                positiveText={"ตกลง"}
                onPositive={onCloseErrorDialog}/>
            <DialogLoadingScreen dialogLoadingOpen={loading}/>
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        
    };
}

function mapStateToProps({user}) {
    return {
        token: user.token
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyScreen);