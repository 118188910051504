import { mainContainer, headerText, subText } from "./styles";
import React from "react";

export default function EmptyVocabSection() {
    return (
        <div style={mainContainer}>
            <div style={headerText}>
                ไม่พบผลลัพธ์
            </div>
            <div style={subText}>
                ลองเลือกสถานะอื่น หรือตัวกรองอื่น
            </div>
        </div>
    )
}