import React from "react";
import ChoiceButtons from "./choiceButton";
import {connect} from "react-redux";
import DialogVocabCardScreen from "../../common/Dialogs/dialogVocabCard";
import {
    loading, requestUpdateRepeatVocab,
    submitAnswer,
} from "./controller/repeat-action";

const fontStyle = {
    color: "#202027",
    fontFamily: "Prompt, sans-serif",
    textAlign : "center",
    width: 311,
    height: 40,
    fontSize: 40,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    margin:"auto",
    paddingTop: "20%",
    paddingBottom: "5%",

};


class RepeatVocab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen : false,
        };
    }

    handleDialogClose = () => {
        this.setState(prevState => ({
            dialogOpen: !prevState.dialogOpen
        }));
    };

    handleOnClickChoice = (data) => {
        this.props.submitedAnswer(data);
        if (data.correct && !this.props.vocab.isAnswer) {
            setTimeout(() => {
                if (this.props.correctVocabs.length === (this.props.selectedVocabs.length)) {
                    this.props.onLoading();
                    this.props.onUpdate();
                } else {
                    this.props.onNextVocab();
                }
            }, 1000);
        }
    };

    render() {
        
        return (
            <div>
                <div style={fontStyle}>{this.props.vocab.vocab}</div>
                <div 
                    style={{
                        textDecoration:"underline",
                        color : "#2574ff",
                        paddingBottom: "10%",
                        visibility: this.props.vocab.isHiddenUI ? "hidden" : "visible",
                    }}
                    onClick={this.handleDialogClose}
                >ดูรายละเอียด</div>
                <div style={{textAlign:"center",justifyContent:"center"}}>
                    {this.props.vocab.choices.map((data,index) =>{
                        return <ChoiceButtons 
                                key={index}
                                stateButton={data.state}
                                onClick={() => this.handleOnClickChoice(data)}
                                textButton={data.meaning} />
                    })}
                </div>
                <div className="space" style={{marginTop:25}}/>

                <DialogVocabCardScreen 
                    dialogOpen={this.state.dialogOpen}
                    handleDialogClose={this.handleDialogClose}
                    data={this.props.vocab}/>
                
            </div>
        );
    }

}


function mapDispatchToProps(dispatch) {
    return {
        submitedAnswer : (selectedChoice) => dispatch(submitAnswer(selectedChoice)),
        onLoading : () => dispatch(loading()),
        onUpdate: () => dispatch(requestUpdateRepeatVocab())
    };
}


function mapStateToProps({ ui, firebase, user, session, repeatVocabReducer }) {
    return {
        uiState: ui,
        firebaseState: firebase,
        userState: user,
        session: session,
        correctVocabs: repeatVocabReducer.correctVocabs,
        selectedVocabs: repeatVocabReducer.selectedVocab,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepeatVocab);