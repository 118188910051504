import bubblesBackground from "../../../assets/images/bubbles.svg";
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from "@material-ui/core";

export const mainContainerStyle = {
    backgroundSize: `100% auto`,
    backgroundRepeat: `no-repeat`
};

export const boxShadowAlohaStyle = {
    boxShadow: `0 10px 20px 0 rgba(0, 64, 128, 0.15)`,
    borderRadius: `20px`
};

export const helpIconStyle = {
    width: `9px`,
    height: `9px`
};

export const AlohaToolTip = withStyles((theme) => ({
    arrow: {
        color: "#fe9870",
    },
    tooltip: {
        fontFamily: 'Prompt, sans-serif',
        fontWeight: "300",
        backgroundColor: '#fe9870',
        color: '#ffffff',
        maxWidth: 300,
        fontSize: "12px"
    }
}))(Tooltip);

export const circleCounter = {
    borderRadius: "23px",
    backgroundColor: "#e9446a",
    color: "#ffffff"
};

export const circleCounterVocab = {
    borderRadius: "23px",
    backgroundColor: "#f6cd6e",
    color: "#ffffff",
    fontFamily: "Prompt, sans-serif",
    fontWeight: "500",
    fontSize: "14px"
};

export const counterButtonRepeatEnableStyle = {
    fontFamily: 'Prompt, sans-serif',
    fontWeight: "400",
    backgroundColor: "#006b6d",
    height: "40px",
    borderRadius: "23px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    color: "#ffffff",
    paddingLeft: "12px",
    marginTop:"10px",
};

export const counterButtonRepeatDisableStyle = {
    fontFamily: 'Prompt, sans-serif',
    fontWeight: "400",
    backgroundColor: "#9b9b9b",
    height: "40px",
    borderRadius: "23px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    color: "#ffffff",
    paddingLeft: "12px",
    marginTop:"10px",

};

export const counterButtonNewStyle = {
    fontFamily: 'Prompt, sans-serif',
    fontWeight: "400",
    backgroundColor: "#005b8e",
    height: "40px",
    borderRadius: "23px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    color: "#ffffff",
    paddingLeft: "12px",
    marginTop:"10px",
};

export const challengeButtonStyle = {
    borderRadius: "24px",
    border: "solid 1px #e9446a",
    color: "#e9446a",
    backgroundColor: "white",
    fontFamily: 'Prompt, sans-serif',
    fontWeight: "400",
    fontSize: "14px",
    paddingLeft: "14px",
    paddingRight: "14px",
    paddingTop: "4px",
    paddingBottom: "4px"
};

export const divider = {
    width: "302px",
    height: "1px",
    border: "solid 1px rgba(0, 0, 0, 0.07)",
    backgroundColor: "#c7c7c7"
}