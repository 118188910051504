import "firebase/auth";
import { LOGOUT_ACTION } from "../controller/logout-reducer";

export function loguot() {
    return (dispatch, getState) => {
        const { app } = getState().firebase;
        app.auth().signOut()
        .then(() => {
            dispatch({
                type: LOGOUT_ACTION.LOGOUT_SUCCESS
            })
        })
        .catch((error) => {
            dispatch({
                type: LOGOUT_ACTION.LOGOUT_FAILED
            })
        })
    }
}