import React from "react";
import {connect} from "react-redux";
import Drawer from '@material-ui/core/Drawer';
import CheckBoxBookScreen from "./checkBoxList";
import SubmitButtons from "./submitButton";
import {
    fetchVocabBook,
    filterVocab,
    toggleDrawer,
    loading,
} from "./controller/vbook-action";
import "./filterPage.css";

const fontHeader = {
    color : `#717479`,
    fontSize : 18,
}

const container = {
    display: "inherit",
    alignItems: "center",
}

const contrainerButton = {
    width: "100%",
    textAlign : "center",
    justifyContent : "center",
    boxSizing: "border-box",
    position: "fixed",
    bottom: 20,
    left: 0,
    right: 0,
}
class FilterBookScreen extends React.Component {

    constructor(props) {
        super(props);

        this.onCloseDrawer = this.onCloseDrawer.bind(this);
        this.onSubmitFilter = this.onSubmitFilter.bind(this);
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    onCloseDrawer(){
        this.props.toggleDrawer();
    }

    onSubmitFilter(){
        this.props.filterVocab();
        this.props.toggleDrawer();
    }

    render() {
        return (
            <div style={container}>
                <Drawer 
                    anchor={"bottom"} 
                    open={this.props.drawerOpen} 
                    onClose={this.onCloseDrawer}>
                    <div className="scrollTypeGroup" style={{height:"93%"}}>
                        <div style={fontHeader}>ประเภทคำศัพท์</div>
                        <CheckBoxBookScreen typeGroup={this.props.checkedType} flagCheck="type"/>
                        <div style={fontHeader}>หมวด</div>
                        <CheckBoxBookScreen typeGroup={this.props.checkedGroup} flagCheck="group"/>
                    </div>
                    <div style={contrainerButton}>
                        <SubmitButtons onClick={() => this.onSubmitFilter()} />
                    </div>
                    
                </Drawer>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        filterVocab: () => dispatch(filterVocab()),
        toggleDrawer: () => dispatch(toggleDrawer()),
        fetchVocabBook: () => dispatch(fetchVocabBook()),
        onLoading: () => dispatch(loading()),
    };
}

function mapStateToProps({vocabBookReducer}) {
    return {
        checkedType : vocabBookReducer.checkedType,
        checkedGroup : vocabBookReducer.checkedGroup,
        loading : vocabBookReducer.loading,
        vocabBook : vocabBookReducer.vocabBook,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBookScreen);