const dev = {
    apiKey: "AIzaSyCXuWu6pf0WThkjKs00q4H6w0Tg9bSTnuo",
    authDomain: "aloha-846d0.firebaseapp.com",
    databaseURL: "https://aloha-846d0.firebaseio.com",
    projectId: "aloha-846d0",
    storageBucket: "aloha-846d0.appspot.com",
    messagingSenderId: "432808706967",
    appId: "1:432808706967:web:906ad36a992b42ca8b7ad2",
    measurementId: "G-DJGZZ1LC1G"
}

const prod = {
    apiKey: "AIzaSyBFSyL8JBjT727kUIu-YcrA3DdpE2qYftk",
    authDomain: "www.alohavocabulary.com",
    databaseURL: "https://aloha-vocabulary-8888.firebaseio.com",
    projectId: "aloha-vocabulary-8888",
    storageBucket: "aloha-vocabulary-8888.appspot.com",
    messagingSenderId: "692374557755",
    appId: "1:692374557755:web:f39ab5efad18080c41b81e",
    measurementId: "G-NQ6YSDM4H0"
}

const config = (process.env.REACT_APP_STAGE.trim() === 'prod-local' || process.env.REACT_APP_STAGE.trim() === 'prod-server')
    ? prod
    : dev;

const envDevLocal = {
    host: "http://localhost:5001/aloha-846d0/asia-southeast2"
}

const envDevServer = {
    host: "https://asia-southeast2-aloha-846d0.cloudfunctions.net"
}

const envProdLocal = {
    host: "http://localhost:5001/aloha-vocabulary-8888/asia-southeast2"
}

const envProdServer = {
    host: "https://asia-southeast2-aloha-vocabulary-8888.cloudfunctions.net"
}

function getEnv() {
    const condition = process.env.REACT_APP_STAGE.trim();
    if ("dev-local" === condition) {
        return envDevLocal;
    } else if ("dev-server" === condition) {
        return envDevServer;
    } else if ("prod-local" === condition) {
        return envProdLocal;
    } else if ("prod-server" === condition) {
        return envProdServer;
    } else {
        return envDevLocal;
    }
    
}

export const env = getEnv();
    

export default config;