import React from "react";
import {connect} from "react-redux";
import ItemOwnCourse from "../items/item-own-course";
import SectionTitle from "../../../common/section-title";

class ListOwnCourse extends React.Component {

    render() {
        if (this.props.ownCourses.length !== 0) {
            return (
                <div>
                    <SectionTitle title="เลือกหมวดที่ต้องการเรียนรู้"/>
                    {this.props.ownCourses.map((course,i) => {
                        return <ItemOwnCourse key={i} course={course} history={this.props.history}/>;
                    })}
                </div>
            );
        } else {
            return <div/>;
        }
    }

}

function mapStateToProps({courseReducer}) {
    return {
        ownCourses: courseReducer.userCourse
    }
}

export default connect(mapStateToProps)(ListOwnCourse);