import React from "react";
import filterImg from "../../assets/images/ic_filter@3x.png";

const container = {
    display: "flex",
    alignItems: "center",
    height: "60px"
};

const itemButton = {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    border: "none",
    outline: "none",
    cursor: "pointer"
};

const itemTitle = {
    flexGrow: 1,
    fontWeight: 500,
    fontFamily: 'Prompt, sans-serif',
    fontSize: "23px",
    textAlign: "center"
};

const itemIcon = {
    width : 22,
    height : 22
}

class FilterToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this);
    }

    handleBack() {
        if (this.props.onBack !== undefined) {
            this.props.onBack();
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        return (
            <div style={container}>
                <div>
                    <button style={itemButton} onClick={this.handleBack}>
                        <i className="material-icons-round md-48">arrow_back_ios</i>
                    </button>
                </div>
                <div style={itemTitle}>
                    {this.props.title}
                </div>
                <div>
                    <button style={itemButton} onClick={this.props.onFilter}>
                        <img src={filterImg} style={itemIcon} />
                    </button>
                </div>
            </div>
        );
    }

}

export default FilterToolbar;