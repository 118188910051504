import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';

class DialogLoadingScreen extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.dialogLoadingOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                  }}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" >
                        <CircularProgress color="secondary" />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

export default DialogLoadingScreen;