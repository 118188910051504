import React from 'react';
import Image from 'react-image-webp';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import content from '../../../assets/images/content.svg';
import content2 from '../../../assets/images/content_2.svg';
import content3 from '../../../assets/images/content_3.svg';


const proprietes = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const fonts = {
    color : '#005b8e',
    marginBottom : 20,
    fontFamily: "Prompt, sans-serif",
    fontWeight: "300"
};

const imgs = {
    margin : "auto"
};

const Slideshow = () => {
    return (
        <div 
        className="containerSlide" 
        style={{
            width:343,
            height:275,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "5%"}}>
            <Slider {...proprietes}>
                <div>
                    <div style={fonts}>
                        สมุดคำศัพท์ ที่เป็นมากกว่า สมุดคำศัพท์ <br/>
                        แพลตฟอร์มที่ช่วยให้การจำศัพท์ภาษาอังกฤษ <br/>
                        มีประสิทธิภาพกว่าการท่องแบบเดิมๆ<br/>
                    </div>
                    <Image alt="content1" style={imgs}
                             src={content}
                             webp={content}/>
                </div>
                <div>
                    <div style={fonts}>
                        เรียนรู้คำศัพท์มากกว่า 4000 คำ<br/>
                        ผ่านทาง ภาพ เสียง พร้อมประโยคตัวอย่าง<br/>
                    </div>
                    <Image alt="content2" style={imgs}
                             src={content2}
                             webp={content2}/>
                </div>
                <div>
                    <div style={fonts}>
                        ผ่านกระบวนการ Spaced Repetition<br/>
                        ที่จะให้น้องๆสามารถจำคำศัพท์ได้ในระยะยาว<br/>
                    </div>
                    <Image alt="content3" style={imgs}
                             src={content3}
                             webp={content3}/>
                </div>
            </Slider>
        </div>
    )
}

export default Slideshow;