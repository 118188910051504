import React from "react";

const fontStyle = {
    color: "#888992",
    fontFamily: "Prompt, sans-serif",
    textAlign : "center",
};

class HeadCount extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={fontStyle}>
                {this.props.count.learn}/{this.props.count.total}
            </div>
        );
    }

}


export default HeadCount;