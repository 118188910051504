import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import SubmitDialogButtons from "./submitDialogButton";
import CancelDialogButtons from "./cancelDialogButton";
import Slide from '@material-ui/core/Slide';
import Spacer from "../space";

const fontStyle = {
    fontFamily: "Prompt, sans-serif",
    textSize : 16,
    textAlign:"center"
}

const upTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class ConfirmDialog extends React.Component {

    constructor(props) {
        super(props);
        this.onPositiveClick = this.onPositiveClick.bind(this);
        this.onNegativeClick = this.onNegativeClick.bind(this);
    }

    onPositiveClick() {
        this.props.onPositive();
    }

    onNegativeClick() {
        this.props.onNegative();
    }

    render() {
        return (
            <Dialog
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={upTransition}
                open={this.props.open}
                onClose={this.props.onClose}
                PaperProps={{
                    style: {
                        width: "300px",
                        borderRadius: 25,
                        padding : 16,
                    },
                }}
                >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={fontStyle}>
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{justifyContent:"center"}}>
                        { this.props.negativeText !== undefined ?
                            <CancelDialogButtons
                                textButton={this.props.negativeText}
                                onClick={this.onNegativeClick}/> : undefined
                        }
                        {
                            this.props.positiveText !== undefined && this.props.negativeText !== undefined ?
                                <Spacer spaceWidth={16} spaceHeight={0}/> : undefined
                        }
                        { this.props.positiveText !== undefined ?
                            <SubmitDialogButtons
                                textButton={this.props.positiveText}
                                onClick={this.onPositiveClick}/> : undefined
                        }
                </DialogActions>
            </Dialog>
        );
    }

}

export default ConfirmDialog;