export const LANDING = '/';
export const LOG_IN = '/login';
export const REGISTER = '/register';
export const TERM = '/term';
export const PRIVACY = '/privacy';
export const ADMIN = '/admin';
export const VOCAB_BOOK = '/book';
export const COURSES = "/courses";
export const COURSE_DETAIL = "/course/:course_id";
export const COURSES_PURCHASE = "/course/:course_id/purchase";
export const COURSES_SUCCESS_PURCHASE = "/course/:course_id/successful";
export const NEW_VOCAB = "/course/:course_id/:subCourse_id/new";
export const REPEAT_VOCAB = "/repeat";
export const LOGOUT = "/logout";
export const CHALLENGE = "/challenge";
export const CHALLENGESUM = "/challengeSummary";
export const QUESTION = "/question";
export const NOTIFY = "/notify";
export const REPEAT_RESULT = "/repeat/summary";