import React from "react";
import {connect} from "react-redux";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import soundButtonImg from "../../../assets/images/sound-icon@3x.png";
import DialogVocabCardScreen from "../../common/Dialogs/dialogVocabCard";

const fontVocab = {
    fontFamily: "Prompt, sans-serif",
    fontSize : 15,
    color : "#000000",
}

const fontVocabMeaning = {
    fontFamily: "Prompt, sans-serif",
    fontSize : 11,
    color : "#717479",
}

const soundBtStyle = {
    width: 44,
    height: 30,
    overflow: "visible",
    float: "right",
    padding: 0,
    margin: 0,
    background: "none",
    border : "none"
}

const detailButtonStyle = {
    width: "100%",
    padding: 0,
    margin: 0,
    background: "none",
    border : "none",
    textAlign : "left",
    justifyContent: "left",
    textTransform: "none",
}

class ItemVocabBook extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen : false,
        };

        this.handleSoundOnClick = this.handleSoundOnClick.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
    }

    handleDialogClose() {
        this.setState(prevState => ({
            dialogOpen: !prevState.dialogOpen
        }));
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    handleSoundOnClick(voice) {
        // const audioEl = document.getElementsByClassName("audio-element")[0]
        // audioEl.play()
        let audio = new Audio(voice)
        audio.play()
    }

    render() {
        return (
            <div style={{width:"100%",marginTop:10,display:"flex"}}>
                <Button 
                    onClick={this.handleDialogClose}
                    style={detailButtonStyle}>
                    <div>
                        <Typography variant="body2" style={fontVocab}>
                            {this.props.vocab.vocab}
                        </Typography>
                        <Typography variant="body2" style={fontVocabMeaning}>
                            {this.props.vocab.meaning}
                        </Typography>
                    </div>
                </Button>
                <Button
                    onClick={() => this.handleSoundOnClick(this.props.vocab.voice)}
                    style={soundBtStyle}>
                    <img alt="soundButton" 
                        style={{
                            width: 24,
                            height: 20,
                        }}
                        src={soundButtonImg}/>
                </Button>
                <DialogVocabCardScreen 
                    dialogOpen={this.state.dialogOpen}
                    handleDialogClose={this.handleDialogClose}
                    data={this.props.vocab}/>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return {
        // fetchCourseList: (token) => dispatch(fetchCourseList(token)),
    };
}

function mapStateToProps({}) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemVocabBook);