import {
    ACTION_GET_USER_FROM_DB,
    ACTION_UPDATE_USER_DB_SUCCESS, 
    ACTION_UPDATE_USER_DB_FAILED, 
    ACTION_LOGOUT_SUCCESS,
} from "../../constants/action-type";
import { LOGOUT_ACTION } from "../../components/screen/logout/controller/logout-reducer";

export const USER_ACTION = {
    UPDATE_TOKEN: "update_token",
    REFRESH_TOKEN: "refresh_token",
    USER_STATE_NOT_FOUND: "user_state_not_found"
};

const initialState = { // add more state as you wish!
    username: "",
    email: "",
    registerStatus: null,
    token: ""
};

export default function userReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ACTION_GET_USER_FROM_DB: {
            if (payload.registerStatus === "registered") {
                return {
                    ...state,
                    username: payload.username,
                    email: payload.email,
                    registerStatus: payload.registerStatus
                }
            }
            return state
        }
        case ACTION_UPDATE_USER_DB_SUCCESS: {
            return {
                ...state,
                registerStatus: "registered",
                username: payload.username,
                email: payload.email,
            }
        }
        case ACTION_UPDATE_USER_DB_FAILED: {
            return {
                ...state,
                registerStatus: "none",
                username: payload.username,
                email: payload.email,
            }
        }
        case ACTION_LOGOUT_SUCCESS: {
            return {
                ...state,
                registerStatus: null,
                username: "",
                email: "",
                token: ""
            }
        }
        case USER_ACTION.UPDATE_TOKEN: {
            return {
                ...state,
                token: payload.token,
                username: payload.username,
                email: payload.email
            }
        }
        case USER_ACTION.REFRESH_TOKEN: {
            return {
                ...state,
                token: payload.token
            }
        }
        case USER_ACTION.USER_STATE_NOT_FOUND: {
            return {
                ...state,
                token: ""
            }
        }
        case LOGOUT_ACTION.LOGOUT_SUCCESS: {
            return {
                ...state,
                token: ""
            }
        }
        default: {
            return state;
        }
    }
}