import {ACTION_GET_USER_FROM_DB, ACTION_MENU_CLICK} from "../../constants/action-type";

const uiState = {
    isMenuOpen: false,
    isLoading: false
};

export default function uiReducer(state = uiState, { type, payload }) {
    switch (type) {
        case ACTION_MENU_CLICK: {
            return {
                ...state,
                isMenuOpen: !state.isMenuOpen
            }
        }
        case ACTION_GET_USER_FROM_DB: {
            return {
                ...state,
                isLoading: false
            }
        }
        default: {
            return state;
        }
    }
}